import { create } from 'zustand';

type createPDFexportStore = {
	pdfExportInfoModalState: boolean;
	setPDFexportInfoModalState: (pdfExportInfoModalState: boolean) => void;
	selectedDatagridValues: any[];
	setSelectedDatagridValues: (selectedDatagridValues: any[]) => void;
	PDFworkerKill: any;
	setPDFworkerKill: (PDFworkerKill: any) => void;
	pdfSnackState: boolean;
	setPdfSnackState: (pdfSnackState: boolean) => void;

	pdfExportDone: boolean;
	setPdfExportDone: (pdfExportDone: boolean) => void;
};

export const usePDFexport = create<createPDFexportStore>((set, get) => ({
	pdfExportInfoModalState: false,
	setPDFexportInfoModalState: (pdfExportInfoModalState: boolean) => {
		set({
			pdfExportInfoModalState: pdfExportInfoModalState,
		});
	},

	selectedDatagridValues: [],
	setSelectedDatagridValues: (selectedDatagridValues: any) => {
		set({
			selectedDatagridValues: selectedDatagridValues,
		});
	},

	PDFworkerKill: false,
	setPDFworkerKill: (PDFworkerKill: boolean) => {
		set({
			PDFworkerKill: PDFworkerKill,
		});
	},

	pdfSnackState: false,
	setPdfSnackState: (pdfSnackState: boolean) => {
		set({
			pdfSnackState: pdfSnackState,
		});
	},

	pdfExportDone: false,
	setPdfExportDone: (pdfExportDone: boolean) => {
		set({
			pdfExportDone: pdfExportDone,
		});
	},
}));

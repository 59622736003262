import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { decodeJWT } from "../../../globalStores/jwt";
import { motion, AnimatePresence } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { rot18 } from "../../../scripts/rot";

interface Props {
	applicantData: {
		birthday: Date | null | string;
		email: string;
		phone_number: string;
		forename: string;
		surname: string;
		uuid: string;
		created_on: Date | null | string;
		created_from: string;
		id: number;
		transmission_code: string;
	};
	toggle: boolean;
	closeWindow: () => void;
}

export default function QRcodeGenerator(props: Props) {
	let qrApplicant = {
		forename: props.applicantData.forename,
		surname: props.applicantData.surname,
		uuid: props.applicantData.uuid,
		id: props.applicantData.id,
		transmissionCode: props.applicantData.transmission_code,
		env: RELEASE_NAME,
		// location: window.location.hostname,
		location: decodeJWT().location,
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={props.toggle}
			onClose={props.closeWindow}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					backdropFilter: "blur(8px)",
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}>
			<motion.div
				initial={{
					opacity: 0,
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					translateX: "-50%",
					translateY: "-50%",
					minWidth: 350,
					// minHeight: 600,
					// height: 564,
					backgroundColor: "#FFFFFF",
					boxShadow:
						"0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
					borderRadius: "20px",
				}}
				// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}

				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				// whileHover={{ width: 620 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className="noselect">
				{/* //! close */}
				<Grid container sx={{ pr: "7px", pt: "7px" }}>
					<Grid item xs={10}></Grid>
					<Grid item xs={2} sx={{ textAlign: "right" }}>
						<IconButton aria-label="delete" size="large" sx={{ ml: 1 }} onClick={props.closeWindow}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems="center"
					sx={{
						padding: "32px",
						paddingTop: 0,
					}}>
					<Grid container direction="column" alignItems="center" justifyContent="center">
						<Grid item xs={12}>
							<QRCodeCanvas
								value={rot18(JSON.stringify(qrApplicant))}
								size={250}
								bgColor={"#ffffff"}
								fgColor={"#000000"}
								// ('L' 'M' 'Q' 'H')
								level={"L"}
								includeMargin={false}
								// imageSettings={{
								// 	src: "https://static.zpao.com/favicon.png",
								// 	// x: null,
								// 	// y: null,
								// 	height: 24,
								// 	width: 24,
								// 	excavate: true,
								// }}
								style={{
									textAlign: "center",
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ pt: 3 }}>
							<Typography
								sx={{
									fontFamily: "Open Sans",
									fontSize: 20,
									// fontWeight: "bold",
									color: "primary.dark",
								}}>
								<FormattedMessage id="applicant" />
								{": "}
								{props.applicantData.forename
									? props.applicantData.forename + " " + props.applicantData.surname
									: "Applicant"}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</motion.div>
		</Modal>
	);
}

import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { useDraggable } from "@dnd-kit/core";

export const DragItem = (props: any) => {
	const [hoveredOver, setHoveredOver] = React.useState<string | null>(null);

	const {
		attributes,
		active,
		isDragging,
		over,
		listeners,
		setNodeRef,
		transform,
	} = useDraggable({
		// id: "draggable",
		id: props.id,
		// attributes: {
		// 	role: "button",
		// },
		// disabled: props.disabled,
		// data: props.data,
		data: {
			hoveredOver: hoveredOver,
		},
	});
	const style = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
		transition: isDragging ? "none" : "transform 0.14s ease-out",
		// boxShadow: isDragging ? "0px 0px 0px 1px #aeaeae" : "none",
		// scale: isDragging ? 1.1 : 1,
		cursor: isDragging ? "grabbing" : "grab",
		// transition: "transform 0.14s ease-out",
		zIndex: isDragging ? "99" : "0",
		outline: "none",
		minWidth: "152px",
		maxWidth: "182px",
	};

	// React.useEffect(() => {
	// 	console.log(active);
	// }, [active]);

	// React.useEffect(() => {
	// 	console.log(isDragging);
	// 	console.log(over);
	// 	if (isDragging && over) {
	// 		// setDroppedIn(over);
	// 	}
	// }, [isDragging]);
	React.useEffect(() => {
		if (isDragging && over) {
			setHoveredOver(over?.id ? over.id : null);
		}
	}, [over]);

	return (
		<Grid
			item
			// xs={2.4}
			xs
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}
			display={"flex"}
		>
			<Paper
				elevation={isDragging ? 14 : 2}
				sx={{
					borderRadius: "5px",
					backgroundColor: "#f9f9f9",
					p: "5px",
					pt: "8px",
					pb: "8px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					// whiteSpace: "nowrap",
					// overflow: "hidden",
					fontFamily: "Rubik",
					fontSize: 14,
					flex: "1",
					textAlign: "center",
				}}
			>
				{props.children}
			</Paper>
		</Grid>
	);
};

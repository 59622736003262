import useSWR from "swr";
import axios from "axios";

interface Results {
	applicants: any;
}

export default function getApplicants() {
	// let getSessionToken = () => {
	// 	let localToken = localStorage.getItem("userToken");
	// 	if (localToken) return localToken;
	// 	else return "noToken";
	// };

	let localToken = localStorage.getItem("userToken");

	let axConfig = {
		headers: {
			"Content-Type": "application/json",
			// token: getSessionToken(),
			token: localToken ? localToken : "noToken",
		},
		// Authorization: localToken,
		// data:{
		// 	token: localToken ? localToken : "noToken",
		// }
		token: localToken,
	};

	// const fetcher = (url: string, localToken: object) =>
	// 	axios
	// 		.post<Results>(url, { token: localToken }, axConfig)
	// 		.then((res) => res.data);
	const fetcher = (url: string) => axios.get<Results>(url, axConfig).then((res) => res.data);

	const { data, mutate, error } = useSWR(import.meta.env.VITE_apiURL + "/applicant/applicants", fetcher, {
		refreshInterval: 10000, //? every 10 seconds
	});

	const loading = !data && !error;
	// const loggedOut = error && error.status === 403;
	// const tokenNotValide = error && error.status === 401;

	return {
		loading,
		data,
		mutate,
		error,
	};
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import getSNresults from '../../api/getSNresults';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress';
// const emptyIMG =
// 	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	minHeight: 420,
	bgcolor: '#FFFFFF',
	pl: 3,
	pr: 3,
	pt: 1,
	pb: 1,
	boxShadow:
		'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
	borderRadius: '20px',
};

interface Props {
	applicantData: any;
	snCheckState: boolean;
	closeSNcheck: () => void;
	opacityParent: (opacity: number) => void;
	mutateApplicantResults: () => void;
}

export default function snCheck(props: Props) {
	const intl = useIntl();

	const [currentCheck, setCurrentCheck] = React.useState(0);

	const [checkSN, setCheckSN] = React.useState([]);
	const [checkIMG, setCheckIMG] = React.useState<any>([]);
	const [checkedIMG, setCheckedIMG] = React.useState<any>([0, 0, 0, 0, 0]);
	const [enableSubmit, setEnableSubmit] = React.useState<any>(false);

	const [hrResults, setHRresults] = React.useState<any>([0, 0, 0, 0, 0]);
	const { isLoading, data, mutate, isValidating, error } = getSNresults(props.applicantData);
	const [mounted, setMounted] = React.useState(false);
	const handleClose = () => {
		props.closeSNcheck();
		props.opacityParent(1);
		props.mutateApplicantResults();
	};

	React.useEffect(() => {
		// console.log("currentCheck: ", currentCheck);
		let checkedImages = checkedIMG.reduce((a: number, b: number) => a + b, 0);
		if (checkedImages === checkIMG.length) {
			setEnableSubmit(true);
		}
	}, [currentCheck]);

	// React.useEffect(() => {
	// 	// console.log(hrResults);
	// 	console.log(isLoading);
	// }, [isLoading]);

	React.useEffect(() => {
		if (!mounted) setMounted(true);
		if (!mounted) return;
		console.log('mutate');
		mutate();
	}, [mounted]);

	React.useEffect(() => {
		if (!mounted) return;
		if (isValidating) {
			console.log('isValidating');
			setCurrentCheck(0);
			setCheckSN([]);
			setCheckIMG([]);
			setHRresults([0, 0, 0, 0, 0]);
			setCheckedIMG([0, 0, 0, 0, 0]);
			setEnableSubmit(false);
			// setOCRresults(null);
			// setHRresults(null);
		} else if (error) {
			console.log('no SN data');
			// setCurrentCheck(0);
			// setCheckSN([]);
			// setCheckIMG([]);
			// setOCRresults(null);
			// setHRresults(null);
		} else if (data) {
			try {
				// console.log("SN data", data);
				// console.log(data.solutions);
				// console.log(data.ocrResults);
				// console.log(data.manualResults);
				//* set solution strings
				setCheckSN(JSON.parse(data.solutions));
				//* set manual and ocr results
				// setOCRresults(JSON.parse(data.ocrResults));
				// setHRresults(JSON.parse(data.manualResults));

				//! clear again to prevent double data
				// setCheckIMG([]);

				data.testInputs.forEach((testInput: any) => {
					// console.log(testInput);
					setCheckIMG((checkIMG: any) => [...checkIMG, testInput]);
				});
			} catch (error) {
				console.log('error in snCheck');
				// console.log(error);
			}
		}
	}, [data, isValidating, mounted]);

	const handleSave = () => {
		//* submit data
		if (enableSubmit) {
			// console.log("submit data");
			let localToken = localStorage.getItem('userToken');
			fetch(import.meta.env.VITE_apiURL + '/applicant/test-data/?sn=setData', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					token: localToken ? localToken : 'noToken',
				},
				body: JSON.stringify({
					applicantdata: props.applicantData,
					newResults: { scoring: hrResults },
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					setTimeout(() => {
						handleClose();
					}, 500);
				});
		} else {
			// console.log("Please check all images");
		}
	};
	const handleYES = () => {
		if (!isLoading) {
			setCurrentCheck(currentCheck + 1);
			setHRresults((hrResults: any) => {
				let tempHRresults = hrResults;
				tempHRresults[currentCheck] = 1;
				return tempHRresults;
			});
			setCheckedIMG((checkedIMG: any) => {
				let tempCheckedIMG = checkedIMG;
				tempCheckedIMG[currentCheck] = 1;
				return tempCheckedIMG;
			});
		}
	};

	const handleNO = () => {
		if (!isLoading) {
			setCurrentCheck(currentCheck + 1);
			setHRresults((hrResults: any) => {
				let tempHRresults = hrResults;
				tempHRresults[currentCheck] = 0;
				return tempHRresults;
			});
			setCheckedIMG((checkedIMG: any) => {
				let tempCheckedIMG = checkedIMG;
				tempCheckedIMG[currentCheck] = 1;
				return tempCheckedIMG;
			});
		}
	};

	const variants = {
		enter: { opacity: 0 },
		center: {
			opacity: 1,
			transition: { duration: 0.3, type: 'tween' },
		},
		exit: {
			opacity: 0,
			transition: { duration: 0.3, type: 'tween' },
		},
	};

	return (
		<Modal
			open={props.snCheckState}
			onClose={handleClose}
			// onClose={props.closeSNcheck}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
			}}
		>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				// whileHover={{ width: 620 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className='noselect'
			>
				<Box sx={style} className='noselect'>
					<Grid container direction='row' justifyContent='flex-end'>
						<Grid item>
							<IconButton
								aria-label='close'
								size='large'
								sx={{
									color: 'primary.main',
								}}
								onClick={handleClose}
							>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						</Grid>
					</Grid>

					<Grid container direction='row' justifyContent='center'>
						<Grid xs={12} item textAlign={'center'}>
							<Typography
								className={window.location.hostname.includes('app.demo') ? 'demo' : ''}
								sx={{
									fontFamily: 'Rubik',
									display: 'inline-block',
									fontSize: '20px',
									fontWeight: 'bold',
									textTransform: 'uppercase',
								}}
							>
								<FormattedMessage id='sn' />
							</Typography>
							<Typography
								sx={{
									pl: '20px',
									display: 'inline-block',
									fontFamily: 'Rubik',
									fontSize: '20px',
									fontWeight: 'bold',
									textTransform: 'uppercase',
								}}
							>
								{currentCheck + 1 < checkIMG.length ? currentCheck + 1 : checkIMG.length}
								{'/' + checkIMG.length}
							</Typography>
						</Grid>
						<Grid container direction='row' justifyContent='center'>
							<Grid container item xs={1} justifyContent='center' alignItems='center'>
								<AnimatePresence>
									{currentCheck > 0 && (
										<motion.div
											style={{ padding: 8, cursor: 'pointer' }}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.3 }}
											onClick={() => {
												if (currentCheck >= 1) {
													setCurrentCheck(currentCheck - 1);
												}
											}}
										>
											<ArrowBackIosNewIcon />
										</motion.div>
									)}
								</AnimatePresence>
							</Grid>
							<Grid item xs={10} mt={2}>
								<Box
									sx={{
										borderRadius: '20px',
										background:
											'radial-gradient(closest-side at 50% 50%, #26486D 0%, #0C2748 100%) 0% 0% no-repeat padding-box',
										height: 200,
										// width: 520,
									}}
								>
									<Grid
										container
										direction='column'
										// justifyContent="space-evenly"
										justifyContent='center'
										alignItems='center'
										height={'100%'}
									>
										<AnimatePresence initial={false} mode='wait'>
											{/* {currentCheck < checkIMG.length && !isLoading ? ( */}
											{currentCheck < checkIMG.length && !isLoading ? (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													exit={{ opacity: 0 }}
													transition={{ duration: 0.3 }}
													key={'OCRDATA'}
												>
													<Grid item textAlign={'center'} sx={{ height: '48px', mb: 2 }}>
														{!isLoading && (
															<AnimatePresence mode='wait'>
																<motion.div
																	key={currentCheck}
																	variants={variants}
																	initial='enter'
																	animate='center'
																	exit='exit'
																>
																	<Typography
																		sx={{
																			fontFamily: 'Rubik',
																			fontSize: '2rem',
																			fontWeight: 'bold',
																			textTransform: 'uppercase',
																			letterSpacing: '0.2rem',
																			color: 'white',
																		}}
																	>
																		{checkSN[currentCheck]}
																	</Typography>
																</motion.div>
															</AnimatePresence>
														)}
													</Grid>
													<Grid item alignItems={'center'}>
														<Box
															style={{
																backgroundColor: 'white',
																width: '380px',
																height: '80px',
																borderRadius: '12px',
																overflow: 'hidden',
															}}
														>
															{!isLoading && (
																<AnimatePresence
																	mode='wait'
																	// onExitComplete={() => {
																	// 	//* close after last check
																	// 	if (currentCheck === checkIMG.length) {
																	// 		setTimeout(() => {
																	// 			handleClose();
																	// 		}, 700);
																	// 	}
																	// }}
																>
																	<motion.img
																		key={currentCheck}
																		variants={variants}
																		initial='enter'
																		animate='center'
																		exit='exit'
																		style={{
																			height: '100%',
																			width: '100%',
																		}}
																		src={checkIMG[currentCheck]}
																	></motion.img>
																</AnimatePresence>
															)}
														</Box>
													</Grid>
												</motion.div>
											) : currentCheck >= checkIMG.length && !isLoading ? (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													exit={{ opacity: 0 }}
													transition={{ duration: 0.3 }}
													key={'DONE'}
												>
													<Grid item textAlign={'center'}>
														<Typography
															sx={{
																fontFamily: 'Rubik',
																fontSize: '2rem',
																fontWeight: 'bold',
																textTransform: 'uppercase',
																letterSpacing: '0.2rem',
																color: 'white',
															}}
														>
															<FormattedMessage id='done' />
														</Typography>
													</Grid>
												</motion.div>
											) : (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													exit={{ opacity: 0 }}
													transition={{ duration: 0.3 }}
													key={'DONE'}
												>
													<Grid item textAlign={'center'}>
														<CircularProgress
															size={55}
															sx={{
																color: 'white',
															}}
														/>
													</Grid>
												</motion.div>
											)}
										</AnimatePresence>
									</Grid>
								</Box>
							</Grid>
							<Grid container item xs={1} justifyContent='center' alignItems='center'>
								<AnimatePresence mode='wait'>
									{currentCheck < checkIMG.length && (
										<motion.div
											style={{ padding: 8, cursor: 'pointer' }}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.3 }}
											onClick={() => {
												if (currentCheck <= checkIMG.length) {
													setCurrentCheck(currentCheck + 1);
												}
											}}
										>
											<ArrowForwardIos />
										</motion.div>
									)}
								</AnimatePresence>
							</Grid>
						</Grid>
						<Grid xs={12} item mt={2} textAlign={'center'}>
							<AnimatePresence mode='wait' initial={false}>
								{currentCheck < checkIMG.length || isLoading ? (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.3 }}
									>
										<Typography
											sx={{
												fontFamily: 'Open Sans',
												fontSize: '14px',
											}}
										>
											<FormattedMessage id='snInfoText' />
										</Typography>
									</motion.div>
								) : (
									<Box sx={{ height: '21px' }} />
								)}
							</AnimatePresence>
						</Grid>
					</Grid>
					<AnimatePresence mode='wait'>
						{currentCheck < checkIMG.length && !isLoading ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.3 }}
								key={'YES&NO'}
							>
								<Grid container direction='row' justifyContent='center' mt={4}>
									<Grid xs={3} item textAlign={'center'}>
										<Button
											variant='contained'
											onClick={() => {
												if (checkIMG.length > currentCheck) {
													handleYES();
												}
											}}
											// component={LinkBehavior}
											//if hrResults[currentCheck]
											color='secondary'
											style={{
												fontSize: 12,
												width: 125,
												height: 30,
												fontFamily: 'Rubik',
												border:
													checkedIMG[currentCheck] === 1 && hrResults[currentCheck] === 1 ? '3px solid #FF7D45' : 0,
											}}
										>
											<FormattedMessage id='yes' />
										</Button>
									</Grid>
									<Grid xs={1} item></Grid>
									<Grid xs={3} item textAlign={'center'}>
										<Button
											variant='contained'
											onClick={() => {
												if (checkIMG.length > currentCheck) {
													handleNO();
												}
											}}
											style={{
												fontSize: 12,
												width: 125,
												height: 30,
												backgroundColor: '#707070',
												fontFamily: 'Rubik',
												border:
													checkedIMG[currentCheck] === 1 && hrResults[currentCheck] === 0 ? '3px solid #FF7D45' : 0,
											}}
										>
											<FormattedMessage id='no' />
										</Button>
									</Grid>
								</Grid>
							</motion.div>
						) : (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.3 }}
								key={'SUBMIT'}
							>
								<Grid container direction='row' justifyContent='center' mt={4}>
									<Grid xs={3} item textAlign={'center'}>
										<Button
											variant='contained'
											onClick={() => {
												handleSave();
											}}
											disabled={!enableSubmit}
											color='secondary'
											style={{
												fontSize: 12,
												minWidth: 125,
												minHeight: 30,
												fontFamily: 'Rubik',
											}}
										>
											<FormattedMessage id='saveRating' />
										</Button>
									</Grid>
								</Grid>
							</motion.div>
						)}
					</AnimatePresence>
				</Box>
			</motion.div>
		</Modal>
	);
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import { FormattedMessage } from 'react-intl';
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.parcel2";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = "./other/pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('/pdf.worker.min.mjs', import.meta.url).toString();

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// translateX: "-50%",
	// translateY: "-50%",
	minWidth: 400,
	minHeight: 400,
	// bgcolor: "background.paper",
	bgcolor: '#FFFFFF',

	// border: "2px solid #000",
	// boxShadow: 24,
	pl: 3,
	pr: 3,
	pt: 1,
	pb: 1,
	boxShadow:
		'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
	borderRadius: '20px',
};

interface Props {
	manualState: boolean;
	closeManual: () => void;
}

export default function appManual(props: Props) {
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [manual, setManual] = React.useState('/MD_Application-description for recruiters-EN.pdf');

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	React.useEffect(() => {
		const location = window.location.hostname.substring(0, 2);
		switch (location) {
			case 'bg':
				setManual('/MD_Приложение-описание за наематели.pdf');
				break;

			case 'mx':
				setManual('/MD_Application-description para reclutadores.pdf');
				break;

			case 'cz':
				setManual('/MD_Popis aplikace pro náboráře.pdf');
				break;

			default:
				setManual('/MD_Application-description for recruiters.pdf');
				break;
		}
	}, []);

	return (
		<Modal
			open={props.manualState}
			// onClose={handleClose}
			onClose={props.closeManual}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 850,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}
		>
			<Box sx={style} className='noselect'>
				<Grid container direction='row' justifyContent='flex-end'>
					<Grid item>
						<IconButton
							aria-label='close'
							size='large'
							sx={{
								color: 'primary.main',
							}}
							onClick={props.closeManual}
						>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					</Grid>
				</Grid>
				<Document file={manual} onLoadSuccess={onDocumentLoadSuccess} loading={'Loading PDF, please wait...'}>
					<Page
						// pageNumber={pageNumber}
						pageNumber={pageNumber}
						// pageIndex={1}
						// height={1100}
						height={window.innerHeight - 240}
						renderMode='canvas'
						loading={'Please wait!'}
					/>
				</Document>
				{/* <p>
						Page {pageNumber} of {numPages}
					</p> */}
				<Grid
					container
					direction='row'
					justifyContent='center'
					// alignItems="center"
					columnSpacing={8}
				>
					{/* <Grid item xs={5} /> */}
					<Grid item>
						<IconButton
							disabled={pageNumber <= 1}
							aria-label='left'
							size='large'
							sx={{
								color: 'primary.main',
							}}
							onClick={() => {
								if (pageNumber > 1) {
									setPageNumber(pageNumber - 1);
								}
							}}
						>
							<ArrowBackIosNewIcon fontSize='inherit' />
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton
							//@ts-ignore
							disabled={pageNumber >= numPages}
							aria-label='right'
							size='large'
							sx={{
								color: 'primary.main',
							}}
							onClick={() => {
								//@ts-ignore
								if (pageNumber < numPages) {
									setPageNumber(pageNumber + 1);
								}
							}}
						>
							<ArrowForwardIosNewIcon fontSize='inherit' />
						</IconButton>
					</Grid>

					<Grid
						item
						sx={{
							position: 'absolute',
							right: '25px',
						}}
					>
						<a href={manual} download>
							<Tooltip title={<FormattedMessage id='download' />} placement='top'>
								<IconButton
									aria-label='right'
									size='large'
									sx={{
										color: 'primary.main',
									}}
									// onClick={() => {
									// 	//@ts-ignore
									// 	window.open(
									// 		"/220217-MD_Application-description for recruiters.pdf",
									// 		"_blank"
									// 	);
									// }}
									// }}
								>
									<DownloadIcon fontSize='inherit' />
								</IconButton>
							</Tooltip>
						</a>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}

import React from 'react';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

export default function PreReleaseOverlay() {
	// import.meta.env.MODE === "dedevelopment" ?
	const [overlay, setOverlay] = React.useState(false);
	const [overlayText, setOverlayText] = React.useState('STAGING');

	React.useEffect(() => {
		const host = window.location.hostname;
		if (host.includes('pre-release') || host.includes('localhost')) {
			setOverlay(true);
		}
		if (host.includes('app.demo')) {
			console.log('host - demo: ' + host.includes('app.demo'));
			setOverlayText('DEMO');
			setOverlay(false);
		}
	}, []);

	return overlay ? (
		<Backdrop
			// sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			sx={{
				// backgroundColor: "rgba(97, 97, 97, 0.1)",
				backgroundColor: 'transparent',
				zIndex: 999,
				pointerEvents: 'none',
			}}
			open={true}
		>
			<Typography
				sx={{
					fontFamily: 'Open Sans',
					opacity: 0.15,
					fontSize: 140,
					transform: 'rotate(-25deg)',
				}}
			>
				{overlayText}
			</Typography>
		</Backdrop>
	) : null;
}

import jwt_decode from "jwt-decode";

interface DecodedJWT {
	iat: number | boolean;
	exp: number | boolean;
	forename: string | boolean;
	surname: string | boolean;
	username: string | boolean;
	permission: number | boolean | null;
	location: string | boolean;
}

export const decodeJWT = () => {
	let decodedJWT: DecodedJWT = {
		iat: 0,
		exp: 0,
		forename: "",
		surname: "",
		username: "",
		permission: null,
		location: "",
	};
	try {
		let userJWT = JSON.stringify(localStorage.getItem("userToken") || "{}");
		decodedJWT = jwt_decode(userJWT);
		// console.log(decodedJWT);
	} catch (error) {
		console.log("no jwt");
	}

	return decodedJWT;
};

export const LOCALES: any = {
	//   Bulgarian, Czech, Ukraine, Spanish, Englisch, Romanian, Vietnamese
	// ENGLISH: "en-US",
	// BULGARIAN: "bg-BG",
	// SPANISH: "es-ES",
	// CZECH: "cs-CZ",

	// UKRAINIAN: "uk-UA",
	// ROMANIAN: "ro-RO",
	// VIETNAMESE: "vi-VN",
	ENGLISH: {
		NAME: "English",
		CODE: "en-GB",
	},
	BULGARIAN: {
		NAME: "Български",
		CODE: "bg-BG",
	},
	SPANISH: {
		NAME: "Español",
		CODE: "es-ES",
	},
	CZECH: {
		NAME: "Čeština",
		CODE: "cs-CZ",
	},
};

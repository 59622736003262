import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

export default function getSNresults(applicantData: any) {
	let localToken = localStorage.getItem('userToken');
	const IDandUUID = { id: applicantData.id, uuid: applicantData.uuid };

	const fetcher = (url: string) =>
		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				token: localToken ? localToken : 'noToken',
				applicantdata: JSON.stringify(IDandUUID),
			},
		}).then(async (res) => {
			// if (res.error) {
			// 	throw new Error(res.error);
			// }
			if (!res.ok) {
				const error = new Error('An error occurred in hetSNresults!');
				throw error;
			}
			return res.json();
		});

	const { data, mutate, isValidating, error, isLoading } = useSWR(
		import.meta.env.VITE_apiURL + '/applicant/test-data/?sn=getData',
		fetcher,
		{
			// refreshInterval: 20000, //? every 20 seconds
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			// errorRetryInterval: 3000,
			// errorRetryCount: 2,
			errorRetryCount: 0,
		},
	);
	// const loading = !data && !error;
	// const loggedOut = error && error.status === 403;
	// const tokenNotValide = error && error.status === 401;

	return {
		isLoading,
		data,
		mutate,
		isValidating,
		error,
	};
}

//* request latest applicant test data
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export default function getApplicantTestData(applicantData: any) {
	let localToken = localStorage.getItem("userToken");
	const IDandUUID = { id: applicantData.id, uuid: applicantData.uuid };

	const fetcher = (url: string) =>
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				token: localToken ? localToken : "noToken",
				applicantdata: JSON.stringify(IDandUUID),
			},
		}).then(async (res) => {
			if (!res.ok) {
				const error = new Error("An error occurred in getApplicantTestData!");
				throw error;
			}
			return res.json();
		});

	const { data, mutate, isValidating, error } = useSWRImmutable(
		import.meta.env.VITE_apiURL + "/applicant/test-data",
		fetcher,
		{
			// revalidateIfStale: false,
			// revalidateOnFocus: false,
			// revalidateOnReconnect: false,
			// errorRetryInterval: 5000,
			errorRetryCount: 0,
		}
	);
	const loading = !data && !error;
	// const loggedOut = error && error.status === 403;
	// const tokenNotValide = error && error.status === 401;

	return {
		isValidating,
		loading,
		data,
		mutate,
		error,
	};
}

import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useApplicantCreateToggle } from '../../globalStores/applicantToggleStore';
import CustomChart from './customChartHome';
import getAverageResults from '../../api/getAverageResults';

import { useChartData } from '../../globalStores/chartData';
import { FormattedMessage } from 'react-intl';

// import chartTest from "../../data/chartTest.json";

//* assets
import AddNewPNG from '../../assets/small-add-new3.png';
import Rocket from '../../assets/rocket-small.png';

import { styled } from '@mui/material/styles';

const CardBox = styled(Box)`
	border-radius: 20px;
	background-color: #ffffff;
	// z-index: 5;
	box-shadow: 0px 2px 6px #4d4f5c29;
	overflow: hidden;
	// width: 100%;
`;

const topBoxHeight = 170;

interface Props {
	// headline: string| boolean;
	// text: string;
	forename: string | boolean;
	openAddNewApplicant: () => void;
	openTestResults: () => void;
}
//* other way of linking, maybe needed later
// const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
// 	(props, ref) => <RouterLink ref={ref} to="/pvt" {...props} role={undefined} />
// );

export default function Card(props: Props) {
	// const navigate = useNavigate();
	const { openApplicantCreate } = useApplicantCreateToggle();
	const { setAverageChartData } = useChartData();
	const { isValidatingAVG, loadingAVG, dataAVG, mutateAVG, errorAVG } = getAverageResults();

	React.useEffect(() => {
		mutateAVG();
		return () => {
			mutateAVG(null, false);
		};
	}, []);
	React.useEffect(() => {
		if (dataAVG) {
			// console.log(dataAVG);
			const averages = dataAVG.averages;
			// //! order of this array is important
			setAverageChartData([
				averages.avg_first_pv_test,
				averages.avg_left_ehc__test,
				averages.avg_right_ehc_test,
				averages.avg_sa_test,
				averages.avg_cv_test,
				averages.avg_logic_test,
				averages.avg_m1_test,
				averages.avg_m2_test,
				// averages.avg_ul_test,
				averages.avg_second_pv_test,
				// averages.avg_v_test,
			]);
		}
	}, [isValidatingAVG]);

	// console.log(getAverageResult(signal));
	return (
		<>
			{/* <Box
				sx={{
					width: 300,
					height: 300,
					bgcolor: "header.main",
					"&:hover": {
						backgroundColor: "primary.main",
						opacity: [0.9, 0.8, 0.7],
					},
				}}
			/> */}
			<Box
				sx={{
					minWidth: 950,
					// minWidth: 1165,
					maxWidth: 1920,
					// maxHeight: 200,
					flexGrow: 1,
				}}
			>
				<Typography
					sx={{
						textAlign: 'left',
						fontFamily: 'Rubik',
						textTransform: 'capitalize',
						fontSize: 20,
						color: 'primary.main',
						mb: 3,
					}}
				>
					{<FormattedMessage id='hello' />} {props.forename ? props.forename : 'User'}!
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<CardBox sx={{ height: topBoxHeight }}>
							<Grid container alignItems='center' justifyContent='center'>
								<Grid item xs={7}>
									<Stack spacing={2} sx={{ pl: 4 }}>
										<Typography
											sx={{
												textAlign: 'left',
												fontFamily: 'Rubik',
												fontSize: 20,
												fontWeight: 'bold',
												color: 'primary.dark',
												// mb: 5,
												textTransform: 'uppercase',
											}}
										>
											<FormattedMessage id='addnewApplicant' />
										</Typography>
										<Button
											variant='contained'
											onClick={() => {
												// history.push("/applicantStart");
												props.openAddNewApplicant();
												openApplicantCreate();
											}}
											// component={LinkBehavior}
											color='secondary'
											style={{
												fontSize: 12,
												width: 125,
												height: 30,

												// marginTop: 50,
												// padding: 15,
												// paddingLeft: 50,
												// paddingRight: 50,
												fontFamily: 'Rubik',
											}}
										>
											<FormattedMessage id='start' />
										</Button>
									</Stack>
								</Grid>
								<Grid item xs={5} sx={{ textAlign: 'right' }}>
									<img src={AddNewPNG} alt='addNew' style={{ height: topBoxHeight }} />
								</Grid>
							</Grid>
						</CardBox>
					</Grid>
					<Grid item xs={6}>
						<CardBox sx={{ height: topBoxHeight }}>
							<Grid container alignItems='center' justifyContent='center'>
								<Grid item xs={7}>
									<Stack spacing={2} sx={{ pl: 4 }}>
										<Typography
											sx={{
												textAlign: 'left',
												fontFamily: 'Rubik',
												fontSize: 20,
												fontWeight: 'bold',
												color: 'primary.dark',
												// mb: 5,
												textTransform: 'uppercase',
											}}
										>
											<FormattedMessage id='overview' />
										</Typography>
										<Button
											variant='contained'
											onClick={() => {
												// history.push("/applicantStart");
												props.openTestResults();
											}}
											// component={LinkBehavior}
											color='secondary'
											style={{
												fontSize: 12,
												width: 125,
												height: 30,

												// marginTop: 50,
												// padding: 15,
												// paddingLeft: 50,
												// paddingRight: 50,
												fontFamily: 'Rubik',
											}}
										>
											<FormattedMessage id='showMe' />
										</Button>
									</Stack>
								</Grid>
								<Grid item xs={5} sx={{ textAlign: 'right' }}>
									<img src={Rocket} alt='rocketPNG' style={{ height: topBoxHeight }} />
								</Grid>
							</Grid>
						</CardBox>
					</Grid>
					<Grid item xs={12}>
						<CardBox sx={{ minHeight: 425, maxHeight: 584, height: '55vh', pb: 1 }}>
							<Grid container justifyContent='center' alignItems='center' pt={1}>
								<Grid item xs={12}>
									<Typography
										sx={{
											fontFamily: 'Rubik',
											fontSize: 20,
											fontWeight: 'bold',
											pt: 1,
											color: 'primary.dark',
										}}
									>
										<FormattedMessage id='locationAverage' />
									</Typography>
								</Grid>
							</Grid>
							<CustomChart />
						</CardBox>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

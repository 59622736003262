import React, { SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import moment from 'moment-timezone';
import {
	DataGridPro,
	GridToolbar,
	GridColDef,
	GridValueGetterParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	GridValueFormatterParams,
	GridActionsCellItem,
	GridSelectionModel,
	// useGridSlotComponentProps,
	useGridApiContext,
	GridOverlay,
	getGridNumericOperators,
	getGridBooleanOperators,
	getGridDateOperators,
	getGridStringOperators,
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import SelectedDeleteUserCard from './selectedDeleteUserCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage, useIntl } from 'react-intl';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

import Slide, { SlideProps } from '@mui/material/Slide';
type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionDown(props: TransitionProps) {
	return <Slide {...props} direction='up' />;
}

import { create } from 'zustand';
import getUsers from '../../api/getUsers';

//* assets
import AddNewPNG from '../assets/add-new2.png';
import OverviewPNG from '../assets/overview.png';

import CreatUser from './creatUser';
import { styled } from '@mui/material/styles';

// * Stores
type createUserToggleStore = {
	stateUserCreate: boolean;
	openUserCreate: () => void;
	closeUserCreate: () => void;
};
const useUserCreateToggle = create<createUserToggleStore>((set) => ({
	stateUserCreate: false,
	openUserCreate: () => set({ stateUserCreate: true }),
	closeUserCreate: () => set({ stateUserCreate: false }),
}));

type selectedDeleteUserToggleStore = {
	selectedDeleteUser: object;
	setSelectedDeleteUser: (data: object) => any;
	stateSelectedDeleteUserCard: boolean;
	openSelectedDeleteUserCard: () => void;
	closeSelectedDeleteUserCard: () => void;
};
const useSelectedDeleteUser = create<selectedDeleteUserToggleStore>((set) => ({
	selectedDeleteUser: {},
	setSelectedDeleteUser: (data) => set({ selectedDeleteUser: data }),
	// set((state) => ({
	// 	selectedApplicant: { ...state.selectedApplicant, data },
	// })),
	stateSelectedDeleteUserCard: false,
	openSelectedDeleteUserCard: () => set({ stateSelectedDeleteUserCard: true }),
	closeSelectedDeleteUserCard: () => set({ stateSelectedDeleteUserCard: false }),
}));

const CardBox = styled(Box)`
	border-radius: 20px;
	background-color: #ffffff;
	// z-index: 5;
	box-shadow: 0px 2px 6px #4d4f5c29;
	overflow: hidden;
	// width: 100%;
`;

// const topBoxHeight = 170;

const cellStyle = makeStyles({
	root: {
		'& .newStyle': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600',
			// fontFamily: "Montserrat",
		},
	},
});
const gridStyle = makeStyles({
	root: {
		'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
		'& .MuiDataGrid-iconSeparator': {
			display: 'none',
		},
		'&.MuiDataGrid-root .MuiDataGrid-selectedRowCount': {
			visibility: 'hidden',
		},
		'&.MuiDataGrid-root': {
			border: 'none',
		},
		'&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
			fontFamily: 'Montserrat',
			fontWeight: 600,
		},
		'&.MuiDataGrid-root .MuiDataGrid-dataContainer': {
			fontFamily: 'Open Sans',
			// fontWeight: 600,
		},
		'&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
			backgroundColor: '#E6EAEE',
		},
		'&.MuiPaginationItem-root': {
			borderRadius: 4,
		},
		'&.MuiDataGrid-footerContainer': {
			height: '400px',
		},
		//? hide density and export function
		// "& .css-14imlab-MuiButtonBase-root-MuiButton-root:nth-child(n+3)": {
		// 	visibility: "hidden",
		// },
	},
});
function CustomToolbar() {
	return (
		<GridToolbarContainer>
			{/* <GridToolbarColumnsButton sx={{ mb: 2 }} /> */}
			<GridToolbarFilterButton sx={{ mb: 2, mr: 1, p: 0.7, fontSize: 14 }} />
			{/* <GridToolbarDensitySelector />
		<GridToolbarExport /> */}
		</GridToolbarContainer>
	);
}

function CustomPagination() {
	// const { state, apiRef } = useGridSlotComponentProps();
	const apiRef = useGridApiContext();

	return (
		<Pagination
			color='primary'
			variant='outlined'
			shape='rounded'
			defaultPage={1}
			// page={state.pagination.page + 1}
			// count={state.pagination.pageCount}
			page={apiRef.current.state.pagination.page + 1}
			count={apiRef.current.state.pagination.pageCount}
			// @ts-expect-error
			renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
			//! value - 1 need to be investigated, not standard
			onChange={(event, value) => apiRef.current.setPage(value - 1)}
		/>
	);
}

function CustomLoadingOverlay() {
	return (
		<GridOverlay>
			<div style={{ position: 'absolute', top: 0, width: '100%' }}>
				<LinearProgress />
			</div>
		</GridOverlay>
	);
}

export default function Card() {
	const intl = useIntl();
	const { stateUserCreate, openUserCreate, closeUserCreate } = useUserCreateToggle();

	const {
		selectedDeleteUser,
		setSelectedDeleteUser,
		stateSelectedDeleteUserCard,
		openSelectedDeleteUserCard,
		closeSelectedDeleteUserCard,
	} = useSelectedDeleteUser();

	const { data, mutate } = getUsers();
	const [userRows, setUserRows] = React.useState([]);
	// const [selectionModel, setSelectionModel] =
	// 	React.useState<GridSelectionModel>([]);
	const [loading, setLoading] = React.useState(true);
	const [timeZone, setTimeZone] = React.useState<string>('Europe/Berlin');

	//* snack
	const [snackInfo, setSnackInfo] = React.useState<any>({
		open: false,
		message: '',
	});

	React.useEffect(() => {
		if (window.location.hostname === 'localhost') {
			setTimeZone('Europe/Berlin');
		} else if (window.location.hostname.startsWith('bg')) {
			setTimeZone('Europe/Sofia');
		} else if (window.location.hostname.startsWith('mx')) {
			setTimeZone('America/Mexico_City');
		} else if (window.location.hostname.startsWith('cz')) {
			setTimeZone('Europe/Prague');
		}
	}, []);

	//* DataGrid
	// const columns: GridColDef[] = [
	const columns = [
		{
			field: 'id',
			// headerName: "ID",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='idGrid' />
				</Typography>
			),
			width: 70,
			filterable: false,
		},
		{
			field: 'username',
			// headerName: "Username",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='usernameGrid' />
				</Typography>
			),
			width: 200,
			// cellClassName: "cellStyle",
			// editable: true,
		},
		{
			field: 'forename',
			// headerName: "First name",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='firstnameGrid' />
				</Typography>
			),
			width: 150,
			// editable: true,
			filterable: false,
		},
		{
			field: 'surname',
			// headerName: "Last name",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='lastnameGrid' />
				</Typography>
			),
			width: 150,
			// editable: true,
			filterable: false,
		},
		{
			field: 'permission',
			// headerName: "Role",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='roleGrid' />
				</Typography>
			),
			width: 140,
			filterable: false,
			valueFormatter: (params: GridValueFormatterParams) => {
				let convertRole = (role: any) => {
					// if (role == "1") return "Administrator";
					// if (role == "2") return "Human Resources";
					// if (role == "3") return "Testing Staff";
					if (role == '1') return intl.formatMessage({ id: 'selectUserAdmin' });
					if (role == '2') return intl.formatMessage({ id: 'selectUserHR' });
					if (role == '3') return intl.formatMessage({ id: 'selectUserTestingStaff' });
					return '';
				};
				return convertRole(params.value);
			},
		},
		{
			field: 'created_on',
			// headerName: "Created on",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='createdOnGrid' />
				</Typography>
			),
			// sortable: false,
			// filterable: false,
			type: 'date',
			width: 145,
			valueFormatter: (params: GridValueFormatterParams) => {
				// let convertedCreatedOn = format(
				// 	new Date(params.value as Date),
				// 	"dd.MM.yyyy"
				// );
				// //   (params.value as number) * 100,
				// // ).toLocaleString();
				// // return `${valueFormatted} %`;
				// return convertedCreatedOn;
				let convertedCreatedOn = moment(params.value as Date).tz(timeZone);
				return convertedCreatedOn.format('DD.MM.YYYY');
			},
		},
		{
			field: 'last_login',
			// headerName: "Last login",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='lastLoginGrid' />
				</Typography>
			),
			type: 'dateTime',
			width: 220,
			valueFormatter: (params: GridValueFormatterParams) => {
				if (params.value == null) return;
				// let convertedDateLogin = format(
				// 	new Date(params.value as Date),
				// 	"dd.MM.yyyy HH:mm"
				// );
				// //   (params.value as number) * 100,
				// // ).toLocaleString();
				// // return `${valueFormatted} %`;
				// return convertedDateLogin;
				let convertedDateLogin = moment(params.value as Date).tz(timeZone);
				return convertedDateLogin.format('DD.MM.YYYY HH:mm');
			},
		},
		{
			field: 'actions',
			// headerName: "Delete",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='delete' />
				</Typography>
			),
			type: 'actions',
			width: 110,
			getActions: (params: any) => [
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label='Delete'
					onClick={() => {
						// console.log(params.row.username);
						// deleteUser(params.row.username);
						openSelectedDeleteUserCard();
						setSelectedDeleteUser(params.row);
					}}
				/>,
				//   <GridActionsCellItem
				//     icon={<SecurityIcon />}
				//     label="Toggle Admin"
				//     // onClick={toggleAdmin(params.id)}
				//     showInMenu
				//   />,
				//   <GridActionsCellItem
				//     icon={<FileCopyIcon />}
				//     label="Duplicate User"
				//     // onClick={duplicateUser(params.id)}
				//     showInMenu
				//   />,
			],
		},
		// {
		// 	field: "fullName",
		// 	headerName: "Full name",
		// 	description: "This column has a value getter and is not sortable.",
		// 	sortable: false,
		// 	width: 160,
		// 	valueGetter: (params: GridValueGetterParams) =>
		// 		`${params.getValue(params.id, "firstName") || ""} ${
		// 			params.getValue(params.id, "lastName") || ""
		// 		}`,
		// },

		//?  GridValueFormatterParams
		// valueFormatter: (params: GridValueFormatterParams) => {
		// 	const valueFormatted = Number(
		// 	  (params.value as number) * 100,
		// 	).toLocaleString();
		// 	return `${valueFormatted} %`;
	];

	const columnsFilter = React.useMemo(
		() =>
			columns.map((col, i) => {
				// console.log(col);
				// if (col.field !== "valid") {
				// 	return col;
				// }

				// return {
				// 	...col,
				// 	// filterOperators: getGridNumericOperators().filter((operator) => {
				// 	// 	// console.log(operator);
				// 	// 	return (
				// 	// 		operator.value === "isEmpty" || operator.value === "isNotEmpty"
				// 	// 		// operator.label === "None"
				// 	// 	);
				// 	// }),
				// 	headerName: intl.formatMessage({
				// 		id: "statusGrid",
				// 	}),
				// 	filterOperators: getGridNumericOperators()
				// 		.filter(
				// 			(operator) =>
				// 				operator.value === "isEmpty" || operator.value === "isNotEmpty"
				// 		)
				// 		.map((operator) => ({
				// 			...operator,
				// 			label: operator.value === "isEmpty" ? "None" : "Finished",
				// 		})),
				// };
				if (col.field == 'username') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'username',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				if (col.field == 'created_on') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'createdOnGrid',
						}),
						filterOperators: getGridDateOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'after' ||
								operator.value === 'onOrAfter' ||
								operator.value === 'before' ||
								operator.value === 'onOrBefore',
						),
					};
				}
				if (col.field == 'last_login') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'lastLoginGrid',
						}),
						filterOperators: getGridDateOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'after' ||
								operator.value === 'onOrAfter' ||
								operator.value === 'before' ||
								operator.value === 'onOrBefore',
						),
					};
				} else {
					return col;
				}
			}),
		[columns],
	);

	React.useEffect(() => {
		if (!data) {
			// console.log("no data");
		} else {
			// let tempData: SetStateAction<never[]> = [];
			// let tempData = [] as any;
			// data.users.forEach((element: any, index: number) => {
			// 	//? modify date
			// 	// let tempCreated_on = new Date(element.created_on);
			// 	let convertedDateCreated = format(
			// 		new Date(element.created_on),
			// 		"dd/MM/yyyy"
			// 	);
			// 	let convertedDateLogin = null;
			// 	if (element.last_login !== null) {
			// 		convertedDateLogin = format(
			// 			new Date(element.last_login),
			// 			"dd/MM/yyyy HH:mm"
			// 		);
			// 	}
			// 	// prettier-ignore
			// 	// let convertedDate = `${tempCreated_on.getDate()}.${tempCreated_on.getMonth() + 1}.${tempCreated_on.getFullYear()}`;
			// 	//? modify role
			// 	let convertRole = (role: string) => {
			// 		if (role == "1") return "Administrator";
			// 		if (role == "2") return "Human Resources";
			// 		if (role == "3") return "Testing Staff";
			// 	};
			// 	tempData[index] = {
			// 		...data.users[index],
			// 		created_on: convertedDateCreated,
			// 		last_login: convertedDateLogin,
			// 		permission: convertRole(element.permission),
			// 	};
			// });
			// setUserRows(tempData);
			// console.log(data);
			setLoading(false);
			setUserRows(data.users);
		}
	}, [data]);

	return (
		<>
			{/* <Box
				sx={{
					width: 300,
					height: 300,
					bgcolor: "header.main",
					"&:hover": {
						backgroundColor: "primary.main",
						opacity: [0.9, 0.8, 0.7],
					},
				}}
			/> */}
			<Box
				sx={{
					minWidth: 950,
					maxWidth: 1920,
					flexGrow: 1,
					height: '100%',
				}}
			>
				{/* //? top empty name box */}
				{/* <Box sx={{ height: 30, mb: 3 }} /> */}
				<CreatUser
					toggle={stateUserCreate}
					snackSuccess={(message) =>
						setSnackInfo({
							open: true,
							message: message,
						})
					}
					closeWindow={() => {
						closeUserCreate();
					}}
				/>
				<SelectedDeleteUserCard
					toggle={stateSelectedDeleteUserCard}
					snackSuccess={(message) => {
						setSnackInfo({ open: true, message: message });
					}}
					closeWindow={() => {
						closeSelectedDeleteUserCard();
					}}
					// @ts-expect-error
					userData={selectedDeleteUser}
				/>
				<CardBox sx={{ height: '100%' }}>
					<Grid container spacing={2} sx={{ p: 3, height: '100%' }}>
						<Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
							<Button
								variant='contained'
								onClick={() => {
									openUserCreate();
								}}
								// component={LinkBehavior}
								color='secondary'
								style={{
									fontSize: 12,
									width: 180,
									minHeight: 30,
									// marginTop: 50,
									// padding: 15,
									// paddingLeft: 50,
									// paddingRight: 50,
									fontFamily: 'Rubik',
								}}
							>
								<FormattedMessage id='addNewUser' />
							</Button>
						</Grid>
						<Grid item style={{ height: '94%', width: '100%' }}>
							{/* <div style={{ display: "flex", height: "100%" }}> */}
							{/* <Grid item xs={12} sx={{ flexGrow: 1 }}> */}
							<Grid item xs={12} sx={{ display: 'flex', height: '100%' }}>
								<DataGridPro
									rows={userRows}
									// columns={columns}
									columns={columnsFilter}
									pageSize={100}
									pagination={true}
									// rowsPerPageOptions={[10]}
									disableColumnMenu
									loading={loading}
									//? cant have mui translation and react-intl (custom)
									//? https://github.com/mui/mui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
									localeText={{
										//* toolbar
										toolbarExport: intl.formatMessage({
											id: 'export',
										}),
										// toolbarExportLabel: intl.formatMessage({
										// 	id: "export",
										// }),
										toolbarExportCSV: intl.formatMessage({
											id: 'exportCSV',
										}),
										toolbarFilters: intl.formatMessage({
											id: 'filterFilters',
										}),

										//* filters
										filterOperatorContains: intl.formatMessage({
											id: 'filterContains',
										}),
										filterOperatorEquals: intl.formatMessage({
											id: 'filterEquals',
										}),
										filterOperatorStartsWith: intl.formatMessage({
											id: 'filterStartsWith',
										}),
										filterOperatorAfter: intl.formatMessage({
											id: 'filterAfter',
										}),
										filterOperatorOnOrAfter: intl.formatMessage({
											id: 'filterOnOrAfter',
										}),
										filterOperatorBefore: intl.formatMessage({
											id: 'filterBefore',
										}),
										filterOperatorOnOrBefore: intl.formatMessage({
											id: 'filterOnOrBefore',
										}),
										filterPanelAddFilter: intl.formatMessage({
											id: 'filterAdd',
										}),
										filterPanelDeleteIconLabel: intl.formatMessage({
											id: 'deleteButton',
										}),
										filterPanelOperators: intl.formatMessage({
											id: 'filterOperators',
										}),
										filterPanelOperatorAnd: intl.formatMessage({
											id: 'filterAnd',
										}),
										filterPanelOperatorOr: intl.formatMessage({
											id: 'filterOr',
										}),
										filterPanelColumns: intl.formatMessage({
											id: 'filterColumns',
										}),
										filterPanelInputLabel: intl.formatMessage({
											id: 'filterValue',
										}),

										//* column
										columnHeaderSortIconLabel: intl.formatMessage({
											id: 'columnSortLabel',
										}),
									}}
									// autoHeight={true}
									// checkboxSelection
									// disableSelectionOnClick
									// onSelectionModelChange={(id) => {
									// 	const selectedRowData = userRows.filter(
									// 		(row: any) => row.id == id
									// 	);
									// 	setSelectionModel(selectedRowData);
									// 	// console.log(selectionModel);
									// 	// console.log(selectedRowData[0]);
									// }}
									// selectionModel={selectionModel}
									components={{
										Pagination: CustomPagination,
										Toolbar: CustomToolbar,
										LoadingOverlay: CustomLoadingOverlay,
									}}
									className={gridStyle().root}
								/>
							</Grid>
							{/* </div> */}
						</Grid>
					</Grid>
				</CardBox>
			</Box>
			{/* //* snacks */}
			<Snackbar
				open={snackInfo.open}
				TransitionComponent={TransitionDown}
				autoHideDuration={4000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				onClose={() => {
					setSnackInfo({ ...snackInfo, open: false });
				}}
			>
				<Alert severity='success' sx={{ backgroundColor: 'secondary.main' }}>
					{snackInfo.message}
				</Alert>
			</Snackbar>
		</>
	);
}

// import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";

export default function submitCSVdata(csvData: any) {
	let localToken = localStorage.getItem("userToken");
	const fetcher = (url: string) =>
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				token: localToken ? localToken : "noToken",
			},
			body: csvData,
		}).then(async (res) => {
			// if (!res.ok) {
			// 	console.log("An error occurred in csvData!");
			// 	const error = res;
			// 	throw error;
			// }
			// return res.json();
			return res;
		});

	return fetcher(import.meta.env.VITE_apiURL + "/upload/csv");

	// const { data, error, mutate } = useSWRImmutable(
	// 	import.meta.env.VITE_apiURL + "/upload/csv",
	// 	fetcher,
	// 	{
	// 		revalidateIfStale: false,
	// 		revalidateOnFocus: false,
	// 		revalidateOnReconnect: false,
	// 		// errorRetryInterval: 5000,
	// 		// onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
	// 		// 	if (error.status === 404) return;
	// 		// 	if (error.status === 500) return;
	// 		// 	if (retryCount >= 3) return;
	// 		// 	// console.log({ retryCount });
	// 		// 	setTimeout(() => revalidate({ retryCount }), 1500);
	// 		// },
	// 		//* start POST if csvData is set
	// 		isPaused: () => {
	// 			if (!csvData) {
	// 				// console.log("csvData is null");
	// 				return true;
	// 			}
	// 			return false;
	// 		},
	// 	}
	// );
	// const submitting = !data && !error;
	// const response = data;
	// const errorSubmitting = error;
	// //prettier-ignore
	// return { submitting, response, errorSubmitting,  mutate };
}

import React, { FunctionComponent } from 'react';
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	ComposedChart,
	Area,
	Tooltip,
	Legend,
	Label,
	Text,
	ReferenceLine,
	LabelList,
} from 'recharts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import { useChartData } from '../../globalStores/chartData';
import { useChartData } from '../../globalStores/chartData';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

export default function applicantCards(props: any) {
	const { chartData } = useChartData();
	const intl = useIntl();

	const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
		const { x, y, payload } = props;
		// <text
		// 	style={{ fontFamily: "Rubik", fontSize: 15 }}
		// 	x={0}
		// 	y={0}
		// 	dy={16}
		// 	textAnchor="middle"
		// 	// fill="#666"
		// 	// transform="rotate(-35)"
		// >
		// 	{/* {payload.value} */}
		// 	{payload.value.split("*").map((part: any, i: any) => (
		// 		<tspan textAnchor="middle" x="0" dy="20" key={i}>
		// 			{/* <tspan textAnchor="middle" x="0" key={i} dy={(i = 0 ? "0" : "20")}> */}
		// 			{part}
		// 		</tspan>
		// 	))}
		// </text>
		return (
			<g transform={`translate(${x},${y})`}>
				{/* //? rect behinde text as background */}
				{/* <rect x="-20" y="0" width="150px" height="55" fill="#FFFFFF" /> */}
				<foreignObject x='-55' y='10' width='110' height='80'>
					<Typography
						// className="demo-graph safari-hide"
						className={window.location.hostname.includes('app.demo') ? 'demo-graph safari-hide' : ''}
						sx={{
							fontFamily: 'Rubik',
							fontSize: 14,
							textAlign: 'center',
							verticalAlign: 'middle',
						}}
					>
						{<FormattedMessage id={payload.value} />}
					</Typography>
				</foreignObject>
			</g>
		);
	};

	const CustomTooltip = ({ active, payload, label }: any) => {
		// console.log(payload);
		if (active && payload && payload.length) {
			let undefValue = '-';
			if (payload[1] === undefined) undefValue = '-';
			else undefValue = payload[1].value;
			return (
				<Box
					sx={{
						pl: 2,
						pr: 2,
						pt: 0.2,
						pb: 0.2,
						borderRadius: '5px',
						boxShadow: '0px 2px 6px #A4AFB7',
						background: '#FFFFFF ',
						color: '#0090D7',
						fontFamily: 'Rubik',
					}}
				>
					{/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
					{/* <p className="label">{`Score: ${payload[1].value}`}</p> */}
					<p className='label'>{`Ø-${intl.formatMessage({
						id: 'score',
					})}: ${payload[0].value}`}</p>
					<p className='label'>{`${intl.formatMessage({
						id: 'applicantScore',
					})}: ${undefValue}`}</p>
					{/* <p className="intro">{getIntroOfPage(label)}</p> */}
					{/* <p className="desc">Anything you want can be displayed here.</p> */}
				</Box>
			);
		}
		return null;
	};

	const renderCustomizedLabel = ({ x, y, index, value }: any) => {
		// console.log(x, y, index, value);
		if (index == 0) {
			return (
				<text x={x - 30} y={y + 5} fill='#3F8ED1' fontWeight='500' fontFamily='Rubik' fontSize={25}>
					Ø
				</text>
			);
		} else return <></>;
	};

	const dataDotsRef = React.useRef({});
	const handleNewDot = (currentActiveDot: any) => {
		// const value = testingRef.current;
		dataDotsRef.current = currentActiveDot;
	};
	const CustomCursor = ({ payload, points }: any) => {
		const dotValue: any = dataDotsRef.current;
		// console.log(dotValue.payload.testScore);
		return dotValue.payload.testScore !== null && dotValue.payload.testScore !== '-' ? (
			<line
				// x1={points[0].x}
				// y1={points[0].y + 300}
				x1={dotValue.cx}
				y1={dotValue.cy}
				x2={points[1].x}
				y2={points[1].y}
				stroke='#0090D7'
				strokeWidth={3}
			/>
		) : (
			<></>
		);
	};

	return props.gotTestData ? (
		// <ResponsiveContainer minHeight={500} maxHeight={700}>
		<ComposedChart
			width={props.responsiveWidth}
			height={props.responsiveHeight}
			data={chartData}
			margin={{
				top: 50,
				right: 50,
				left: 20,
				bottom: 40,
			}}
		>
			<defs>
				<linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1.1'>
					<stop offset='0%' stopColor='#3F8ED1' stopOpacity={0} />
					{/* <stop offset="0%" stopColor="#3F8ED1" stopOpacity={0.9} /> */}
					{/* <stop offset="90%" stopColor="#3F8ED1" stopOpacity={0} /> */}
				</linearGradient>
			</defs>
			<CartesianGrid
				opacity={0.6}
				vertical={false}
				strokeDasharray='6 8'
				stroke='#59587C'
				horizontalPoints={[50, 150, 250, 350, 450]}
			/>
			<XAxis
				dataKey='name'
				axisLine={false}
				tickLine={false}
				interval={0}
				// tick={{ fontFamily: "Rubik" }}
				tick={<CustomizedAxisTick />}
				// tick={<Text width={30} />}
				// tickFormatter={(val) => val.replace("*", 123)}
				// dy={20}
				padding={{ left: 10, right: 10 }}
			/>
			<YAxis
				// type="number" dataKey="x"
				axisLine={false}
				tickLine={false}
				// tickCount={1}
				width={40}
				// interval={99}
				// tick={{ fontFamily: "Rubik" }}
				tick={false}
				dx={-10}
			>
				{/* <Label
					value="Ø"
					// offset={0}
					stroke="#3F8ED1"
					strokeWidth=".6px"
					position="insideLeft"
					fontFamily="Rubik"
					fontSize={20}
				/> */}
			</YAxis>
			{/* <ReferenceDot x="Basic Math" y={1000} /> */}
			<Tooltip
				content={<CustomTooltip />}
				// itemStyle={{ fontFamily: "Rubik", color: "#43425D" }}
				labelStyle={{
					fontFamily: 'Rubik',
					paddingBottom: 10,
					color: '#43425D',
				}}
				// cursor={{ stroke: "#0090D7", strokeWidth: 1 }}
				cursor={props.gotTestData ? <CustomCursor /> : <></>}
				animationDuration={0}
				// animationEasing="ease-out"
				// position={{ x: 40, y: 100 }}
				// position={{ x: barGraphData.x, y: barGraphData.y - 40 }}
			/>
			{chartData[0].testScore === '-' ? (
				console.log('no test data')
			) : (
				<Area
					type='monotone'
					dataKey='average'
					stroke='#3F8ED1'
					strokeWidth='2px'
					fill='url(#colorUv)'
					activeDot={false}
					animationBegin={600}
					label={renderCustomizedLabel}
				/>
			)}
			{/* <Area
				type="monotone"
				dataKey="average"
				stroke="#3F8ED1"
				strokeWidth="2px"
				fill="url(#colorUv)"
				activeDot={false}
				animationBegin={600}
				label={renderCustomizedLabel}
			/> */}
			<Line
				type='monotone'
				dataKey='testScore'
				stroke='transparent'
				dot={{ stroke: '#43425D', strokeWidth: 10, r: 5 }}
				animationDuration={100}
				// activeDot={
				// 	{ stroke: "#43425D", strokeWidth: 14, r: 7 }
				// }
				//@ts-ignore
				activeDot={(props) => {
					handleNewDot(props);
					// console.log(props);
				}}
			/>
		</ComposedChart>
	) : (
		// </ResponsiveContainer>
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			sx={{
				width: props.responsiveWidth - 100,
				height: props.responsiveHeight,
			}}
		>
			<Grid item xs={1}>
				<CircularProgress size={200} sx={{ opacity: 0.4 }} />
			</Grid>
		</Grid>
	);
}

const rot5 = (input: string) => {
	return input
		.split("")
		.map((i) => i.charCodeAt(0))
		.map((d) => (d >= 48 && d <= 57 ? (d + 5 > 57 ? d - 5 : d + 5) : d))
		.map((d) => String.fromCharCode(d))
		.reduce((a, c) => a + c, "");
};

const rot13 = (input: string) => {
	return input
		.split("")
		.map((i) => i.charCodeAt(0))
		.map((d) => (d >= 65 && d <= 90 ? (d + 13 > 90 ? d - 13 : d + 13) : d))
		.map((d) => (d >= 97 && d <= 122 ? (d + 13 > 122 ? d - 13 : d + 13) : d))
		.map((d) => String.fromCharCode(d))
		.reduce((a, c) => a + c, "");
};

const rot18 = (input: string) => {
	// console.log(input);
	return rot5(rot13(input));
};

export { rot5, rot13, rot18 };

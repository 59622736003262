import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { create } from 'zustand';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { format, sub } from 'date-fns';
import { enGB, bg, es, cs } from 'date-fns/locale';
import { motion, AnimatePresence } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguageSwitch } from '../../globalStores/languageSwitch';

import { IMaskInput } from 'react-imask';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import getApplicants from '../../api/getApplicants';

// * Stores
type createUserStore = {
	applicantData: {
		birthday: Date | null;
		email: string;
		phonenumber: string;
		forename: string;
		surname: string;
	};
	addBirthday: (data: Date | null) => any;
	addEmail: (data: string) => any;
	addPhonenumber: (data: string) => any;
	addForename: (data: string) => any;
	addSurname: (data: string) => any;
	clearApplicantData: () => any;
};
const useUserCreate = create<createUserStore>((set) => ({
	applicantData: {
		birthday: null,
		email: '',
		phonenumber: '',
		forename: '',
		surname: '',
	},
	addBirthday: (data: Date | null) =>
		set((state) => ({
			applicantData: { ...state.applicantData, birthday: data },
		})),
	addEmail: (data: string) =>
		set((state) => ({
			applicantData: { ...state.applicantData, email: data },
		})),
	addPhonenumber: (data: string) =>
		set((state) => ({
			applicantData: { ...state.applicantData, phonenumber: data },
		})),
	addForename: (data: string) =>
		set((state) => ({
			applicantData: { ...state.applicantData, forename: data },
		})),
	addSurname: (data: string) =>
		set((state) => ({
			applicantData: { ...state.applicantData, surname: data },
		})),
	clearApplicantData: () =>
		set((state) => ({
			applicantData: {
				...state.applicantData,
				birthday: null,
				email: '',
				phonenumber: '',
				forename: '',
				surname: '',
			},
		})),
}));

interface ErrorInterface {
	message: string;
	allFields: boolean;
	birthday: boolean;
	email: boolean;
	phonenumber: boolean;
	forename: boolean;
	surname: boolean;
}

interface Props {
	toggle: boolean;
	closeWindow: () => void;
	snackSuccess: (message: string) => void;
}

interface PhonenumberMaskProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}
// var phoneMask = "+00 00000000000000";
// const location = window.location.hostname.substring(0, 2);
// switch (location) {
// 	case "bg":
// 		phoneMask = "+000 00000000000000";
// 		break;

// 	case "mx":
// 		phoneMask = "+00 00000000000000";
// 		break;

// 	case "cz":
// 		phoneMask = "+000 00000000000000";
// 		break;

// 	default:
// 		phoneMask = "+00 00000000000000";
// 		break;
// }

const PhonenumberMask = React.forwardRef<HTMLElement, PhonenumberMaskProps>(function PhonenumberMask(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			// mask={phoneMask}
			mask='+0000000000000000'
			// definitions={{
			// 	"#": /[1-9]/,
			// }}
			//@ts-ignore
			inputRef={ref}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export default function createApplicant(props: Props) {
	const intl = useIntl();

	const { applicantData, addBirthday, addEmail, addPhonenumber, addForename, addSurname, clearApplicantData } =
		useUserCreate();
	const { mutate } = getApplicants();
	// const [errorMessage, setErrorMessage] = React.useState<ErrorInterface>({
	// 	message: "",
	// 	type: false,
	// });
	//* error state
	const [errorState, setErrorState] = React.useState<ErrorInterface>({
		message: '',
		allFields: false,
		birthday: false,
		email: false,
		phonenumber: false,
		forename: false,
		surname: false,
	});

	const [phonenumberValue, setPhonenumberValue] = React.useState({
		phoneMask: '',
	});
	const handlePhonenumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPhonenumberValue({
			...phonenumberValue,
			[event.target.name]: event.target.value,
		}),
			addPhonenumber(event.target.value);
	};

	// const [dateValue, setDateValue] = React.useState<Date | null>(null);
	const [dateValue, setDateValue] = React.useState<Date | null>(
		// sub(new Date(), { years: 18 })
		// sub(new Date(946767600000), { years: 18 })
		//*start with 01.01.2000 in ms (UTC+2h)
		// new Date(946681200000)
		null,
	);
	const { LANGUAGE } = useLanguageSwitch();
	const [datePickerLocal, setDatePickerLocal] = React.useState(enGB);
	React.useEffect(() => {
		switch (LANGUAGE.CODE) {
			case 'en-GB':
				setDatePickerLocal(enGB);
				break;
			case 'bg-BG':
				setDatePickerLocal(bg);
				break;
			case 'es-ES':
				setDatePickerLocal(es);
				break;
			case 'cs-CZ':
				setDatePickerLocal(cs);
				break;

			default:
				setDatePickerLocal(enGB);
				break;
		}
	}, [LANGUAGE]);

	let closeAndReset = () => {
		props.closeWindow();
		clearApplicantData();
		// setDateValue(new Date(946681200000));
		setDateValue(null);
		// setErrorMessage({ message: "", type: false });
		setErrorState({
			message: '',
			allFields: false,
			birthday: false,
			email: false,
			phonenumber: false,
			forename: false,
			surname: false,
		});
		setPhonenumberValue({ phoneMask: '' });
	};

	//* request add new applicant
	const addnewApplicant = async () => {
		const localToken = localStorage.getItem('userToken');
		const request = await fetch(import.meta.env.VITE_apiURL + '/applicant/register', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token: localToken ? localToken : 'noToken',
			},
			body: JSON.stringify(applicantData),
		});
		return request;
	};

	const handleSubmit = async () => {
		//* check mail
		if (applicantData.email.length > 0) {
			if (
				!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					applicantData.email,
				)
			) {
				setErrorState({
					...errorState,
					// message: 'E-Mail: Only letters and @ . - _ are allowed',
					message: intl.formatMessage({
						id: 'createApplicatnErrorEMail',
					}) as string,
					email: true,
				});
				return;
			} else {
				setErrorState({
					...errorState,
					message: '',
					email: false,
				});
			}
		}

		if (
			errorState.birthday ||
			errorState.email ||
			errorState.phonenumber ||
			errorState.forename ||
			errorState.surname
		) {
			return;
		}

		//* api response object
		const request = await addnewApplicant();
		//* api response message
		const response: string = await request.json();
		// console.log(request);
		// console.log(response);

		//* ok
		if (request.status == 200) {
			// props.closeWindow();
			// clearApplicantData();
			closeAndReset();
			//TODO handle mutate data, await new applicant after create
			// setTimeout(() => {
			mutate();
			// }, 1000);

			props.snackSuccess(
				intl.formatMessage({
					id: 'createApplicantSuccess',
				}) as string,
			);
		}
		//* error all fields are required
		if (request.status == 400) {
			// setErrorMessage({ message: response, type: true });
			setErrorState({
				...errorState,
				// message: "All fields are required",
				message: intl.formatMessage({
					id: 'createUserErrorAllFields',
				}) as string,
				allFields: true,
				// birthday:
				// 	applicantData.birthday == null || errorState.birthday ? true : false,
				// email: applicantData.email == "" || errorState.email ? true : false,
				// phonenumber:
				// 	applicantData.phonenumber == "" || errorState.phonenumber
				// 		? true
				// 		: false,
				forename: applicantData.forename == '' || errorState.forename ? true : false,
				surname: applicantData.surname == '' || errorState.surname ? true : false,
			});
		}
		//* error user already exists
		if (request.status == 409) {
			// setErrorMessage({ message: response, type: true });
			setErrorState({
				...errorState,
				// message: "User already exists",
				message: intl.formatMessage({
					id: 'createApplicatnErrorExists',
				}) as string,
				allFields: true,
				// birthday:
				// 	applicantData.birthday == null || errorState.birthday ? true : false,
				// email: applicantData.email == "" || errorState.email ? true : false,
				// phonenumber:
				// 	applicantData.phonenumber == "" || errorState.phonenumber
				// 		? true
				// 		: false,
				forename: applicantData.forename == '' || errorState.forename ? true : false,
				surname: applicantData.surname == '' || errorState.surname ? true : false,
			});
		}

		//* error custom
		// if (request.status == 406) {
		// 	setErrorState({
		// 		...errorState,
		// 		message: response,
		// 		allFields: true,
		// 		birthday:
		// 			applicantData.birthday == null || errorState.birthday ? true : false,

		// 		forename:
		// 			applicantData.forename == "" || errorState.forename ? true : false,
		// 		surname:
		// 			applicantData.surname == "" || errorState.surname ? true : false,
		// 	});
		// }
	};

	return (
		<div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={props.toggle}
				onClose={closeAndReset}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 800,
					sx: {
						backgroundColor: 'rgba(0, 0, 0, 0.1)',
						backdropFilter: 'blur(8px)',
					},
					// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
				}}
			>
				<motion.div
					initial={{
						opacity: 0,
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						// transform: "translate(-50%, -50%)",
						translateX: '-50%',
						translateY: '-50%',
						width: 500,
						// minHeight: 600,
						backgroundColor: '#FFFFFF',
						boxShadow:
							'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
						borderRadius: '20px',
						// padding: 32,
					}}
					// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.4 }}
					// exit={{ opacity: 0, transition: { duration: 0.4 } }}
					className='noselect'
				>
					{/* //! close */}
					<Grid container sx={{ pr: '7px', pt: '7px' }}>
						<Grid item xs={10}></Grid>
						<Grid item xs={2} sx={{ textAlign: 'right' }}>
							<IconButton aria-label='delete' size='large' sx={{ ml: 1 }} onClick={closeAndReset}>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						</Grid>
					</Grid>

					<Grid
						container
						alignItems='center'
						sx={{
							padding: '32px',
							paddingTop: 0,
						}}
					>
						<Grid item xs={12}>
							<Typography
								sx={{
									textAlign: 'left',
									fontFamily: 'Rubik',
									fontSize: 26,
									fontWeight: 'bold',
									color: 'primary.dark',
									textTransform: 'uppercase',
								}}
							>
								<FormattedMessage id='addApplicant' />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography
								sx={{
									textAlign: 'left',
									fontFamily: 'Rubik',
									fontSize: 14,
									minHeight: '21px',
									color: 'error.main',
								}}
							>
								{errorState.message}
							</Typography>
						</Grid>
						{/* <Grid item xs={1}>
							<IconButton
								aria-label="delete"
								size="large"
								sx={{ p: 1, ml: 2 }}
								onClick={closeAndReset}
								// onClick={() => {
								// 	props.closeWindow();
								// 	clearUserData();
								// 	setUserRole("");
								// }}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						</Grid> */}
						<Grid container alignItems='center' spacing={3} sx={{ pt: 4.5 }}>
							<Grid item xs={6} style={{ paddingTop: '0' }}>
								<TextField
									error={errorState.forename ? errorState.forename : false}
									required
									fullWidth
									id='firstname'
									// label="Firstname"
									label={<FormattedMessage id='addUserFirstname' />}
									type='text'
									variant='outlined'
									inputProps={{ maxLength: 18 }}
									onChange={(e) => {
										addForename(e.target.value);
										// if (e.target.value.match(/[^a-zA-Z0-9]/g)) {
										if (/[€@§#+^?!"§%&{}.()\[\]=\\/\d]/gi.test(e.target.value) || /[\s]{2,}/gi.test(e.target.value)) {
											setErrorState({
												...errorState,
												// message: "Firstname: Only letters are allowed",
												message: intl.formatMessage({
													id: 'createUserErrorFirstname',
												}) as string,
												forename: true,
											});
										} else {
											setErrorState({
												...errorState,
												message: '',
												forename: false,
											});
										}
									}}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingTop: '0' }}>
								<TextField
									error={errorState.surname ? errorState.surname : false}
									required
									fullWidth
									id='lastname'
									// label="Lastname"
									label={<FormattedMessage id='addUserLastname' />}
									type='text'
									variant='outlined'
									inputProps={{ maxLength: 18 }}
									onChange={(e) => {
										// setErrorMessage({ message: "", type: false });
										addSurname(e.target.value);
										// if (/[^a-zA-Z]/gi.test(e.target.value)) {
										if (/[€@§#+^?!"§%&{}.()\[\]=\\/\d]/gi.test(e.target.value) || /[\s]{2,}/gi.test(e.target.value)) {
											setErrorState({
												...errorState,
												// message: "Lastname: Only letters are allowed",
												message: intl.formatMessage({
													id: 'createUserErrorLastname',
												}) as string,
												surname: true,
											});
										} else {
											setErrorState({
												...errorState,
												message: '',
												surname: false,
											});
										}
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									error={errorState.email ? errorState.email : false}
									fullWidth
									id='email'
									// label="E-Mail"
									label={<FormattedMessage id='eMail' />}
									type='text'
									variant='outlined'
									onChange={(e) => {
										addEmail(e.target.value);

										setErrorState({
											...errorState,
											message: '',
											email: false,
										});
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								{/* <TextField
									fullWidth
									id="phoneNumber"
									label="Phone Number"
									// type="number"
									variant="outlined"
									onChange={(e) => addPhonenumber(e.target.value)}
								/> */}

								<FormControl>
									<InputLabel htmlFor='component-outlined'>
										<FormattedMessage id='phoneNumber' />
									</InputLabel>
									<OutlinedInput
										value={phonenumberValue.phoneMask}
										onChange={handlePhonenumberChange}
										name='phoneMask'
										id='phoneNumber'
										// label="Phone Number"
										label={<FormattedMessage id='phoneNumber' />}
										inputComponent={PhonenumberMask as any}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={datePickerLocal}>
									<DatePicker
										sx={{
											width: '100%',
										}}
										inputFormat='dd/MM/yyyy'
										// label="Birthday *"
										label={
											intl.formatMessage({
												id: 'birthdayApplicantGrid',
											}) as string
										}
										orientation='landscape'
										//* only allow applicants older than 18
										maxDate={sub(new Date(), { years: 14 })}
										// mask={"__.__.____"}
										// disableMaskedInput={true}
										// inputFormat="dd/mm/yyyy"
										// openTo={format(new Date(dateRange), "dd/MM/yyyy")}
										openTo={'year'}
										views={['year', 'month', 'day']}
										// views={["year", "day"]}
										value={dateValue}
										onChange={(newDateValue) => {
											setDateValue(newDateValue);
											addBirthday(newDateValue);
										}}
										onError={(errorMessage) => {
											// console.log(errorMessage);
											if (errorMessage !== null) {
												setErrorState({
													...errorState,
													// message: "Birthdate is set wrong",
													message: intl.formatMessage({
														id: 'createApplicantErrorBirthday',
													}) as string,
													birthday: true,
												});
											} else {
												setErrorState({
													...errorState,
													message: '',
													birthday: false,
												});
											}
										}}
										renderInput={(params) => {
											return (
												<TextField
													{...params}
													sx={{ width: '100%' }}
													error={errorState.birthday ? errorState.birthday : false}
												/>
											);
										}}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: '10px' }}>
								<Typography
									sx={{
										fontSize: '.7rem',
										color: errorState.allFields === false ? '#919191' : 'error.main',
									}}
								>
									<FormattedMessage id='addUserAllFields' />
								</Typography>
							</Grid>
							<Grid
								container
								spacing={0}
								direction='column'
								alignItems='center'
								justifyContent='center'
								sx={{ pt: 4, paddingLeft: '24px' }}
							>
								<Grid item xs={1}>
									<Button
										// type="submit"
										variant='contained'
										// onClick={props.closeWindow}
										onClick={() => {
											// props.closeWindow();
											// closeAndReset();
											// addnewApplicant();
											handleSubmit();
										}}
										color='secondary'
										style={{
											// color: "#fff",
											padding: 10,
											paddingLeft: 40,
											paddingRight: 40,
											fontFamily: 'Rubik',
										}}
									>
										<FormattedMessage id='addUserButton' />
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</motion.div>
			</Modal>
		</div>
	);
}

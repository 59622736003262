import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { create } from 'zustand';
import TextField from '@mui/material/TextField';
import { motion, AnimatePresence } from 'framer-motion';
import getUsers from '../../api/getUsers';
import { FormattedMessage } from 'react-intl';

// * Stores
type toDeleteStore = {
	userData: {
		permission: string;
		username: string;
	};
	addPermission: (data: string) => any;
	addUsername: (data: string) => any;
	clearUserData: () => any;
};
const useToDelete = create<toDeleteStore>((set) => ({
	userData: {
		permission: '',
		username: '',
	},
	addPermission: (data: string) => set((state) => ({ userData: { ...state.userData, permission: data } })),
	addUsername: (data: string) => set((state) => ({ userData: { ...state.userData, username: data } })),
	clearUserData: () =>
		set((state) => ({
			userData: {
				...state.userData,
				permission: '',
				username: '',
			},
		})),
}));

interface ErrorInterface {
	message: string | JSX.Element;
	type: boolean;
}

interface Props {
	userData: {
		username: string;
		forename: string;
		surname: string;
		uuid: string;
	};
	toggle: boolean;
	closeWindow: () => void;
	snackSuccess: (message: string | JSX.Element) => void;
}

export default function selectedDeletedUserCard(props: Props) {
	const { userData, addPermission, addUsername, clearUserData } = useToDelete();
	const { mutate } = getUsers();
	const [errorMessage, setErrorMessage] = React.useState<ErrorInterface>({
		message: '',
		type: false,
	});

	const closeAndReset = () => {
		props.closeWindow();
		clearUserData();
		setErrorMessage({ message: '', type: false });
	};

	//* request delete user
	const deleteUser = async () => {
		const localToken = localStorage.getItem('userToken');
		const request = await fetch(import.meta.env.VITE_apiURL + '/user/delete', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token: localToken ? localToken : 'noToken',
			},
			body: JSON.stringify({ username: userData.username }),
		});
		// .then((data) => data.json());
		return request;
	};

	const handleSubmit = async () => {
		if (userData.username == props.userData.username) {
			//* api response object
			const request = await deleteUser();
			// //* api response message
			const response: string = await request.json();
			// console.log(request);
			// console.log(response);

			//* ok
			if (request.status == 200) {
				props.closeWindow();
				clearUserData();
				mutate();
				props.snackSuccess(<FormattedMessage id='deleteUserSuccess' />);
			}
			//* error on permission or dont allow self deleting
			if (request.status == 403) {
				setErrorMessage({
					message: <FormattedMessage id='deleteErrorNotAllowed' />,
					type: true,
				});
			}
		} else {
			setErrorMessage({
				message: <FormattedMessage id='deleteErrorWrongUsername' />,
				type: true,
			});
		}
	};

	return (
		<Modal
			open={props.toggle}
			onClose={closeAndReset}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}
		>
			<motion.div
				initial={{
					opacity: 0,
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					translateX: '-50%',
					translateY: '-50%',
					width: 500,
					// minHeight: 600,
					// height: 564,
					backgroundColor: '#FFFFFF',
					boxShadow:
						'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
					borderRadius: '20px',
				}}
				// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}

				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				// whileHover={{ width: 620 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className='noselect'
			>
				{/* //! close */}
				<Grid container sx={{ pr: '7px', pt: '7px' }}>
					<Grid item xs={10}></Grid>
					<Grid item xs={2} sx={{ textAlign: 'right' }}>
						<IconButton aria-label='delete' size='large' sx={{ ml: 1 }} onClick={closeAndReset}>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems='center'
					sx={{
						padding: '32px',
						paddingTop: 0,
					}}
				>
					<Grid item xs={12}>
						<Typography
							sx={{
								textAlign: 'left',
								fontFamily: 'Rubik',
								fontSize: 26,
								fontWeight: 'bold',
								color: 'primary.dark',
								textTransform: 'uppercase',
							}}
						>
							<FormattedMessage id='deleteUser' />
						</Typography>
						<Typography
							sx={{
								textAlign: 'left',
								fontFamily: 'Rubik',
								fontSize: 26,
								fontWeight: 'bold',
								fontStyle: 'italic',
								color: 'primary.dark',
								// textTransform: "uppercase",
							}}
						>
							{props.userData.username ? props.userData.username : 'Error: no username'}
						</Typography>
					</Grid>
					<Grid container alignItems='center' spacing={3} sx={{ mt: 1 }}>
						<Grid item xs={12}>
							<Typography
								sx={{
									textAlign: 'left',
									fontFamily: 'Rubik',
									fontSize: 16,
									color: 'primary.dark',
								}}
							>
								<FormattedMessage id='deleteUserInfo' />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								error={errorMessage.type}
								helperText={errorMessage.message}
								required
								sx={{ minHeight: '78.91px' }}
								fullWidth
								id='text'
								label={<FormattedMessage id='deleteUserNameInfo' />}
								type='text'
								variant='outlined'
								onClick={(e) => {
									e.stopPropagation();
								}}
								onChange={(e) => {
									setErrorMessage({ message: '', type: false });
									addUsername(e.target.value);
								}}
							/>
						</Grid>
						<Grid
							container
							spacing={0}
							direction='column'
							alignItems='center'
							justifyContent='center'
							sx={{ pt: 4, paddingLeft: '24px' }}
						>
							<Grid item xs={1}>
								<Button
									// type="submit"
									variant='contained'
									// onClick={props.closeWindow}
									onClick={() => {
										// props.closeWindow();
										// closeAndReset();
										// deleteUser();
										handleSubmit();
									}}
									style={{
										// color: "#fff",
										padding: 10,
										paddingLeft: 40,
										paddingRight: 40,
										fontFamily: 'Rubik',
									}}
									sx={{
										backgroundColor: 'error.main',
										'&:hover': {
											backgroundColor: 'error.main',
										},
									}}
								>
									<FormattedMessage id='deleteButton' />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</motion.div>
		</Modal>
	);
}

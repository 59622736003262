import { create } from 'zustand';
import { LOCALES } from '../i18n/locales';

type createLangauges = {
	LANGUAGE: any;
	setLanguage: (language: any) => void;
};

export const useLanguageSwitch = create<createLangauges>((set) => ({
	LANGUAGE: LOCALES.ENGLISH,

	setLanguage: (language: any) => {
		set({
			LANGUAGE: language,
		});
	},
}));

import { LOCALES } from './locales';

export const messages: any = {
	[LOCALES.ENGLISH.CODE]: {
		//? login
		headline: 'Welcome! Please login to your account.',
		username: 'Username',
		password: 'Password',
		signIn: 'Sign In',
		legal: 'Legal',
		DPD: 'Data Protection Declaration',
		status: 'System status',
		reportAproblem: 'Report a problem',
		locationInfo: 'Location',
		loginErrorInvalid: 'Invalid username or password.',
		loginErrorAllFields: 'All fields are required.',
		badConnection: 'Server is not accessible',
		requestLimiter: 'Too many requests, please try again later',

		//? dashboard sidebar
		home: 'Home',
		applicants: 'Applicants',
		users: 'Users',
		manual: 'Manual',

		//? dashboard toolbar
		languages: 'Languages',
		logout: 'Logout',

		//? home cards
		hello: 'Hello',
		addnewApplicant: 'Add new applicant',
		start: "Let's start",
		overview: 'Applicant Overview',
		showMe: 'Show me',
		locationAverage: 'Location Average',

		//? chart labels
		pvI: 'Psychological Vigilance I',
		lehc: 'Left Eye Hand Coordination',
		rehc: 'Right Eye Hand Coordination',
		sa: 'Sustained Attention',
		cv: 'Color Vision',
		bmI: 'Logic',
		bmII: 'Basic Math I',
		bmIII: 'Basic Math II',
		ul: 'Language',
		pvII: 'Psychological Vigilance II',
		sn: 'Handwrite Serial Number',

		//? grid
		addNewUser: 'Add new user',
		idGrid: 'ID',
		hmidGrid: 'HM-ID',
		usernameGrid: 'Username',
		firstnameGrid: 'First name',
		lastnameGrid: 'Last name',
		roleGrid: 'Role',
		createdOnGrid: 'Created on',
		testDate: 'Test date',
		lastLoginGrid: 'Last login',
		delete: 'Delete',
		addNewApplicant: 'Add new applicant',
		birthdayApplicantGrid: 'Birthday',
		phoneNumberGrid: 'Phone number',
		emailGrid: 'E-Mail',
		createdByGrid: 'Created by',
		statusGrid: 'Status',
		qrCodeGrid: 'QR-Code',
		openGrid: 'Open',
		filterNone: 'None',
		filterFinished: 'Finished',
		export: 'Export',
		exportCSV: 'Applicant Data',
		exportCSVFull: 'Full Database Entry',
		filterContains: 'contains',
		filterEquals: 'equals',
		filterStartsWith: 'starts with',
		filterAfter: 'is after',
		filterOnOrAfter: 'is on or after',
		filterBefore: 'is before',
		filterOnOrBefore: 'is on or before',
		filterAdd: 'Add filter',
		filterOperators: 'Operators',
		filterAnd: 'And',
		filterOr: 'Or',
		filterColumns: 'Columns',
		filterValue: 'Value',
		filterValuePlaceholder: 'Filter value',
		filterIsEmpty: 'is empty',
		filterIsNotEmpty: 'is not empty',
		columnSortLabel: 'Sort',
		filterFilters: 'Filters',
		extendedView: 'Extended view',
		exportQRCodes: 'QR-Code tickets',
		exportInfoHead: 'Selected {length, number} applicants!',
		exportInfoBody: 'Are you sure you want to export this many tickets?',
		exportInfoBodyError:
			'You can not export more than 250 applicants at once. Use "filters" or "select" to narrow your export.',
		QRfileName: 'Applicant QR-Code Ticket',
		selectedRows: 'Selected rows',
		importCSV: 'Import CSV',

		//? user delete
		deleteUserSuccess: 'User successfully deleted!',
		deleteUser: 'Delete user:',
		deleteUserInfo: 'To delete the selected user please type the username in the below',
		deleteUserNameInfo: 'Username',
		deleteButton: 'Delete',
		deleteErrorNotAllowed: 'You are not allowed to delete this user.',
		deleteErrorWrongUsername: 'Wrong username.',

		//? user create
		createUserSuccess: 'User successfully created!',
		addUser: 'Add a new user',
		addUserFirstname: 'First name',
		addUserLastname: 'Last name',
		addUserUsername: 'Username',
		addUserPassword: 'Password',
		addUserRole: 'User Role',
		addUserAllFields: 'All fields with * are required',
		addUserButton: 'Add',
		selectUserAdmin: 'Administrator',
		selectUserHR: 'Human Resources',
		selectUserTestingStaff: 'Testing Staff',
		createUserError4CharUsername: 'Username must be at least 4 characters long',
		createUserError4CharPassword: 'Password must be at least 4 characters long',
		createUserErrorAllFields: 'All fields are required',
		createUserErrorUsernameExists: 'Username already exists',
		createUserErrorFirstname: 'First Name: Only letters are allowed',
		createUserErrorLastname: 'Last Name: Only letters are allowed',
		createUserErrorUsername: 'Username: Only letters, numbers and underscores "_" are allowed',
		createUserErrorPassword: 'Password: Only letters, numbers and  + * # _ - $ !  are allowed',

		//? applicant create
		addApplicant: 'Add a new applicant',
		createApplicatnErrorExists: 'Applicant already exists',
		createApplicantErrorBirthday: 'Birthday: Invalid date',
		createApplicantSuccess: 'Applicant successfully created!',
		createApplicatnErrorEMail: 'Invalid E-Mail address',

		//? qrCard
		applicant: 'Applicant',

		//? selected applicant
		eMail: 'E-Mail',
		phoneNumber: 'Phone number',
		score: 'Score',
		test: 'Test',
		totalScore: 'Total Score',
		applicantScore: 'Applicant Score',
		selectedLanguage: 'Selected language',

		//? manual
		download: 'Download',
		report: 'Report',

		//? other
		yes: 'Yes',
		no: 'No',
		snInfoText: 'Please confirm the handwriting of the applicant with YES or NO.',
		check: 'Check',
		done: 'Done',
		saveRating: 'Save rating',
		cancel: 'Cancel',
		cancelExport: 'Cancel export',
		ok: 'OK',
		pleaseWait: 'Please wait...',
		units: 'units',
		passed: 'Passed',
		failed: 'Failed',
		is: 'is',
		testCompletedOn: 'Test completed on',
		reset: 'Reset',
		submit: 'Submit',
		addApplicants: 'Add {count, number} applicants',
		info: 'Info',

		//? snack
		exportProgress: 'Ticket export in progress',
		exportSuccess: 'Ticket export successful',

		//? CSV import
		importCSVSuccess: 'CSV import successful',
		importCSVNotAll: ' out of {maxNumber, number} records not created',
		importCSVError: 'CSV import failed',
		importCSVinPreogress: 'CSV import in progress',
		infoAtLeast2: 'Please upload a CSV file with at least 2 columns and values separated by commas.',
		infoTooMany: 'Too many rows in CSV file. Please limit to 200 rows.',
		infoOnlyCSV: 'Only one CSV file is allowed',
		dnd: "Drag 'n' drop a file here, or click to select one",
		csvImport: 'CSV import',
		csvImportInfoHeader: 'Drag the labels in the coressponding fields',
		applicantCount: 'Applicant count',
	},
	//!-------------------------------------------------------------------------------------------------------------------------------
	[LOCALES.BULGARIAN.CODE]: {
		//? login
		headline: 'Добре дошли! Моля влезте в си акаунт.',
		username: 'Потребителско име',
		password: 'Парола',
		signIn: 'Вход',
		legal: 'Правни',
		DPD: 'Декларация за защита на данните',
		status: 'Състояние на системата',
		reportAproblem: 'Съобщете за проблем',
		locationInfo: 'Местоположение',
		loginErrorInvalid: 'Невалидно потребителско име или парола.',
		loginErrorAllFields: 'Всички полета са задължителни.',
		badConnection: 'Сървърът не е достъпен',
		requestLimiter: 'Твърде много заявки, моля, опитайте отново по-късно',

		//? dashboard sidebar
		home: 'Начало',
		applicants: 'Заявители',
		users: 'Потребители',
		manual: 'Ръководство',

		//? dashboard toolbar
		languages: 'Езици',
		logout: 'Изход',

		//? home cards
		hello: 'Здравейте',
		addnewApplicant: 'Добави нов заявител',
		start: 'Начало',
		overview: 'Преглед на заявителите',
		showMe: 'Покажи ми',
		locationAverage: 'Средно местоположение',

		//? chart labels
		pvI: 'Психологична винагистрация I',
		lehc: 'Лева координация на ръцето',
		rehc: 'Дясна координация на ръцето',
		sa: 'Постоянна внимание',
		cv: 'Цветова видимост',
		bmI: 'Логика',
		bmII: 'Базова математика I',
		bmIII: 'Базова математика II',
		ul: 'Език',
		pvII: 'Психологична винагистрация II',
		sn: 'Сериен номер на ръка',

		//? grid
		addNewUser: 'Добави нов потребител',
		idGrid: 'ID',
		hmidGrid: 'HM-ID',
		usernameGrid: 'Потребителско име',
		firstnameGrid: 'Име',
		lastnameGrid: 'Фамилия',
		roleGrid: 'Роля',
		createdOnGrid: 'Създаден на',
		testDate: 'Дата на теста',
		lastLoginGrid: 'Последно влизане',
		delete: 'Изтрий',
		addNewApplicant: 'Добави нов кандидат',
		birthdayApplicantGrid: 'Дата на раждане',
		phoneNumberGrid: 'Телефонен номер',
		emailGrid: 'Имейл',
		createdByGrid: 'Създаден от',
		statusGrid: 'Статус',
		qrCodeGrid: 'QR-код',
		openGrid: 'Отвори',
		filterNone: 'Нито един',
		filterFinished: 'Завършени',
		export: 'Експорт',
		exportCSV: 'Данни за заявителя',
		exportCSVFull: 'Пълна база данни',
		filterContains: 'съдържа',
		filterEquals: 'pавно',
		filterStartsWith: 'започва с',
		filterAfter: 'е след',
		filterOnOrAfter: 'е на или след',
		filterBefore: 'е преди',
		filterOnOrBefore: 'е на или преди',
		filterAdd: 'Добавете филтър',
		filterOperators: 'Оператори',
		filterAnd: 'И',
		filterOr: 'Или',
		filterColumns: 'колони',
		filterValue: 'Стойност',
		filterValuePlaceholder: 'Стойност на филтъра',
		filterIsEmpty: 'празно е',
		filterIsNotEmpty: 'не е празен',
		columnSortLabel: 'Вид',
		filterFilters: 'Филтри',
		extendedView: 'Разширен изглед',
		exportQRCodes: 'QR-код билети',
		exportInfoHead: 'Избрани {length, number} кандидати!',
		exportInfoBody: 'Наистина ли искате да експортирате толкова много билети?',
		exportInfoBodyError:
			'Не можете да експортирате повече от 250 кандидати наведнъж. Използвайте „филтри“ или „изберете“, за да стесните експортирането.',
		QRfileName: 'билет за QR-код на заявителя',
		selectedRows: 'Избрани редове',
		importCSV: 'Импортиране на CSV',

		//? user delete
		deleteUserSuccess: 'Потребителят е изтрит успешно!',
		deleteUser: 'Изтриване на потребител:',
		deleteUserInfo: 'За да изтриете избрания потребител, моля, въведете потребителското име по-долу',
		deleteUserNameInfo: 'Потребителско име',
		deleteButton: 'Изтрий',
		deleteErrorNotAllowed: 'Нямате права да изтриете този потребител.',
		deleteErrorWrongUsername: 'Грешно потребителско име.',

		//? user create
		createUserSuccess: 'Потребителят е създаден успешно!',
		addUser: 'Добавете нов потребител',
		addUserFirstname: 'Първо име',
		addUserLastname: 'Фамилия',
		addUserUsername: 'Потребителско име',
		addUserPassword: 'Парола',
		addUserRole: 'Роля',
		addUserAllFields: 'Всички полета със * са задължителни',
		addUserButton: 'Добави',
		selectUserAdmin: 'Администратор',
		selectUserHR: 'Човешки ресурси',
		selectUserTestingStaff: 'Тестов служител',
		createUserError4CharUsername: 'Потребителското име трябва да е с минимум 4 символа',
		createUserError4CharPassword: 'Паролата трябва да е с минимум 4 символа',
		createUserErrorAllFields: 'Всички полета са задължителни',
		createUserErrorUsernameExists: 'Потребителското име вече съществува',
		createUserErrorFirstname: 'Първо име: Разрешени са само букви',
		createUserErrorLastname: 'Фамилия: Разрешени са само букви',
		createUserErrorUsername: 'Потребителско име: Разрешени са само букви, цифри и долни черти "_"',
		createUserErrorPassword: 'Парола: Само букви, цифри и + * # _ - $ ! са разрешени',

		//? applicant create
		addApplicant: 'Добавете нов кандидат',
		createApplicatnErrorExists: 'Кандидатът вече съществува',
		createApplicantErrorBirthday: 'Рожден ден: Невалидна дата',
		createApplicantSuccess: 'Кандидатът е създаден успешно!',
		createApplicatnErrorEMail: 'Невалиден имейл адрес',

		//? qrCard
		applicant: 'Заявител',

		//? selected applicant
		eMail: 'Електронна поща',
		phoneNumber: 'Телефонен номер',
		score: 'Резултат',
		test: 'Тест',
		totalScore: 'Общ резултат',
		applicantScore: 'Резултат от кандидата',
		selectedLanguage: 'Избран език',

		//? manual
		download: 'Изтегли',
		report: 'Отчет',

		//? other
		yes: 'да',
		no: 'Не',
		snInfoText: 'Моля, потвърдете почерка на кандидата с ДА или НЕ.',
		check: 'Проверете',
		done: 'Свършен',
		saveRating: 'Запазване на оценка',
		cancel: 'Отмяна',
		cancelExport: 'Анулиране на експортирането',
		ok: 'ОК',
		pleaseWait: 'Моля Изчакай...',
		units: 'единици',
		passed: 'Преминал',
		failed: 'Неуспешно',
		is: 'е',
		testCompletedOn: 'Тестът приключи на',
		reset: 'Нулиране',
		submit: 'Изпращане',
		addApplicants: 'Добавете {count, number} кандидати',
		info: 'Инфо',

		//? snack
		exportProgress: 'Експортирането на билети е в ход',
		exportSuccess: 'Експортирането на билет е успешно',

		//? CSV import
		importCSVSuccess: 'Импортирането на CSV е успешно',
		importCSVNotAll: ' от {maxNumber, number} записи не са създадени',
		importCSVError: 'Импортирането на CSV не бе успешно',
		importCSVinPreogress: 'Импортирането на CSV в ход',
		infoAtLeast2: 'Моля, качете CSV файл с поне 2 колони и стойности, разделени със запетаи.',
		infoTooMany: 'Твърде много редове в CSV файл. Моля, ограничете до 200 реда.',
		infoOnlyCSV: 'Разрешен е само един CSV файл',
		dnd: 'Плъзнете и пуснете файл тук или щракнете, за да изберете един',
		csvImport: 'CSV импортиране',
		csvImportInfoHeader: 'Плъзнете етикетите в съответните полета',
		applicantCount: 'Брой на кандидатите',
	},
	//!-------------------------------------------------------------------------------------------------------------------------------
	[LOCALES.SPANISH.CODE]: {
		//? login
		headline: '¡Bienvenido! Por favor inicie sesión en su cuenta.',
		username: 'Nombre de usuario',
		password: 'Contraseña',
		signIn: 'Iniciar sesión',
		legal: 'Legal',
		DPD: 'Declaración de Protección de Datos',
		status: 'Estado del sistema',
		reportAproblem: 'Informar de un problema',
		locationInfo: 'Ubicación',
		loginErrorInvalid: 'Nombre de usuario o contraseña no válidos.',
		loginErrorAllFields: 'Todos los campos son obligatorios.',
		badConnection: 'El servidor no es accesible',
		requestLimiter: 'Hay demasiadas solicitudes. Por favor inténtelo más tarde',

		//? dashboard sidebar
		home: 'Inicio',
		applicants: 'Solicitantes',
		users: 'Usuarios',
		manual: 'Manual',

		//? dashboard toolbar
		languages: 'Idiomas',
		logout: 'Cerrar sesión',

		//? home cards
		hello: 'Hola',
		addnewApplicant: 'Añadir nuevo solicitante',
		start: 'Comienza',
		overview: 'Resumen de solicitantes',
		showMe: 'Mostrarme',
		locationAverage: 'Ubicación promedio',

		//? chart labels
		pvI: 'Vigilancia psicológica I',
		lehc: 'Coordinación de la mano izquierda',
		rehc: 'Coordinación de la mano derecha',
		sa: 'Concentración sostenida',
		cv: 'Visión de color',
		bmI: 'Lógica',
		bmII: 'Matemáticas básicas I',
		bmIII: 'Matemáticas básicas II',
		ul: 'Idioma',
		pvII: 'Vigilancia psicológica II',
		sn: 'Número de serie escrito a mano',

		//? grid
		addNewUser: 'Añadir nuevo usuario',
		idGrid: 'ID',
		hmidGrid: 'HM-ID',
		usernameGrid: 'Nombre de usuario',
		firstnameGrid: 'Nombre',
		lastnameGrid: 'Apellido',
		roleGrid: 'Rol',
		createdOnGrid: 'Creado en',
		testDate: 'Fecha de la prueba',
		lastLoginGrid: 'Último inicio de sesión',
		delete: 'Eliminar',
		addNewApplicant: 'Añadir nuevo solicitante',
		birthdayApplicantGrid: 'Fecha de nacimiento',
		phoneNumberGrid: 'Número de teléfono',
		emailGrid: 'Correo electrónico',
		createdByGrid: 'Creado por',
		statusGrid: 'Estado',
		qrCodeGrid: 'Código-QR',
		openGrid: 'Abrir',
		filterNone: 'Ninguna',
		filterFinished: 'Finalizado',
		export: 'Exportar',
		exportCSV: 'Datos del solicitante',
		exportCSVFull: 'Entrada de base de datos completa',
		filterContains: 'contiene',
		filterEquals: 'es igual',
		filterStartsWith: 'starts with',
		filterAfter: 'comienza con',
		filterOnOrAfter: 'está en o después',
		filterBefore: 'es antes',
		filterOnOrBefore: 'está en o antes',
		filterAdd: 'Añadir filtro',
		filterOperators: 'Operadoras',
		filterAnd: 'Y',
		filterOr: 'O',
		filterColumns: 'columnas',
		filterValue: 'Valor',
		filterValuePlaceholder: 'Valor del filtro',
		filterIsEmpty: 'está vacío',
		filterIsNotEmpty: 'no esta vacío',
		columnSortLabel: 'Clasificar',
		filterFilters: 'Filtros',
		extendedView: 'Vista ampliada',
		exportQRCodes: 'Entradas con código QR',
		exportInfoHead: 'Seleccionaron {length, number} solicitantes!',
		exportInfoBody: '¿Está seguro de que desea exportar tantos boletos?',
		exportInfoBodyError:
			'No puede exportar más de 250 solicitantes a la vez. Use "filtros" o "seleccionar" para limitar su exportación.',
		QRfileName: 'Boletos con código QR del solicitante',
		selectedRows: 'Filas seleccionadas',
		importCSV: 'Importar CSV',

		//? user delete
		deleteUserSuccess: 'El usuario se ha eliminado correctamente!',
		deleteUser: 'Eliminar usuario:',
		deleteUserInfo: 'Para eliminar el usuario seleccionado, por favor, introduzca el nombre de usuario a continuación',
		deleteUserNameInfo: 'Nombre de usuario',
		deleteButton: 'Eliminar',
		deleteErrorNotAllowed: 'No tiene permiso para eliminar este usuario.',
		deleteErrorWrongUsername: 'Nombre de usuario incorrecto.',

		//? user create
		createUserSuccess: 'El usuario se ha creado correctamente!',
		addUser: 'Agregar una nueva usuaria',
		addUserFirstname: 'Nombre',
		addUserLastname: 'Apellido',
		addUserUsername: 'Nombre de usuario',
		addUserPassword: 'Contraseña',
		addUserRole: 'Rol',
		addUserAllFields: 'Todos los campos con * son obligatorios',
		addUserButton: 'Añadir',
		selectUserAdmin: 'Administrador',
		selectUserHR: 'Recursos humanos',
		selectUserTestingStaff: 'Personal de pruebas',
		createUserError4CharUsername: 'El nombre de usuario debe tener al menos 4 caracteres',
		createUserError4CharPassword: 'La contraseña debe tener al menos 4 caracteres',
		createUserErrorAllFields: 'Todos los campos son obligatorios',
		createUserErrorUsernameExists: 'El nombre de usuario ya existe',
		createUserErrorFirstname: 'Nombre: Solo se permiten letras',
		createUserErrorLastname: 'Apellido: Solo se permiten letras',
		createUserErrorUsername: 'Nombre de usuario: Solo se permiten letras, números y guiones bajos "_"',
		createUserErrorPassword: 'Contraseña: Solo se permiten letras, números, + * # _ - $ !',

		//? applicant create
		addApplicant: 'Agregar una nueva solicitante',
		createApplicatnErrorExists: 'La solicitante ya existe',
		createApplicantErrorBirthday: 'Cumpleaños: Fecha inválida',
		createApplicantSuccess: 'Solicitante creada con éxito!',
		createApplicatnErrorEMail: 'Dirección de correo electrónico no válida',

		//? qrCard
		applicant: 'Solicitante',

		//? selected applicant
		eMail: 'Email',
		phoneNumber: 'Número de teléfono',
		score: 'Puntaje',
		test: 'Prueba',
		totalScore: 'Puntaje total',
		applicantScore: 'Puntaje de la solicitante',
		selectedLanguage: 'Idioma seleccionado',

		//? manual
		download: 'Descargar',
		report: 'Informe',

		//? other
		yes: 'Si',
		no: 'No',
		snInfoText: 'Por favor confirme la letra del solicitante con SÍ o NO.',
		check: 'Controlar',
		done: 'Hecho',
		saveRating: 'Guardar calificación',
		cancel: 'Cancelar',
		cancelExport: 'Cancelar exportación',
		ok: 'OK',
		pleaseWait: 'Espere por favor...',
		units: 'unidades',
		passed: 'Aprobado',
		failed: 'Fallido',
		is: 'es',
		testCompletedOn: 'Prueba completada el',
		reset: 'Reiniciar',
		submit: 'Entregar',
		addApplicants: 'Agregue {count, number} solicitantes',
		info: 'Información',

		//? snack
		exportProgress: 'Exportación de entradas en curso',
		exportSuccess: 'Exportación de boletos exitosa',

		//? CSV import
		importCSVSuccess: 'Importación CSV exitosa',
		importCSVNotAll: ' de {maxNumber, number} registros no creados',
		importCSVError: 'Error en la importación de CSV',
		importCSVinPreogress: 'Importación de CSV en curso',
		infoAtLeast2: 'Cargue un archivo CSV con al menos 2 columnas y valores separados por comas.',
		infoTooMany: 'Demasiadas filas en el archivo CSV. Limite a 200 filas.',
		infoOnlyCSV: 'Solo se permite un archivo CSV',
		dnd: 'Arrastre y suelte un archivo aquí, o haga clic para seleccionar uno',
		csvImport: 'importación CSV',
		csvImportInfoHeader: 'Arrastre las etiquetas en los campos correspondientes',
		applicantCount: 'Recuento de solicitantes',
	},
	//!-------------------------------------------------------------------------------------------------------------------------------
	[LOCALES.CZECH.CODE]: {
		//? login
		headline: 'Vítejte! Prosím přihlašte se do svého účtu.',
		username: 'Uživatelské jméno',
		password: 'Heslo',
		signIn: 'Přihlásit se',
		legal: 'Právní',
		DPD: 'Prohlášení o ochraně osobních údajů',
		status: 'Stav systému',
		reportAproblem: 'Nahlásit problém',
		locationInfo: 'Umístění',
		loginErrorInvalid: 'Neplatné uživatelské jméno nebo heslo.',
		loginErrorAllFields: 'Všechna pole jsou povinná.',
		badConnection: 'Server není přístupný',
		requestLimiter: 'Příliš mnoho požadavků. Prosím zkuste to znovu později',

		//? dashboard sidebar
		home: 'Domů',
		applicants: 'Žadatelé',
		users: 'Uživatelé',
		manual: 'Manuál',

		//? dashboard toolbar
		languages: 'Jazyky',
		logout: 'Odhlásit se',

		//? home cards
		hello: 'Ahoj',
		addnewApplicant: 'Přidat nového žadatele',
		start: 'Začít',
		overview: 'Přehled žadatelů',
		showMe: 'Zobrazit mě',
		locationAverage: 'Průměrné umístění',

		//? chart labels
		pvI: 'Psychologické výzvy I',
		lehc: 'Koordinace očí a levé ruky',
		rehc: 'Koordinace očí a pravé ruky',
		sa: 'Soustředěná pohybem',
		cv: 'Zdravé zrakové vidění',
		bmI: 'Logika',
		bmII: 'Základní matematika I',
		bmIII: 'Základní matematika II',
		ul: 'Jazyk',
		pvII: 'Psychologické výzvy II',
		sn: 'Ruční psaní sériového čísla',

		//? grid
		addNewUser: 'Přidat nového uživatele',
		idGrid: 'ID',
		hmidGrid: 'HM-ID',
		usernameGrid: 'Uživatelské jméno',
		firstnameGrid: 'Jméno',
		lastnameGrid: 'Příjmení',
		roleGrid: 'Role',
		createdOnGrid: 'Vytvořeno dne',
		testDate: 'Datum testu',
		lastLoginGrid: 'Poslední přihlášení',
		delete: 'Odstranit',
		addNewApplicant: 'Přidat nového žadatele',
		birthdayApplicantGrid: 'Datum narození',
		phoneNumberGrid: 'Telefonní číslo',
		emailGrid: 'E-mail',
		createdByGrid: 'Vytvořil',
		statusGrid: 'Stav',
		qrCodeGrid: 'QR-kód',
		openGrid: 'Otevřít',
		filterNone: 'Žádný',
		filterFinished: 'Dokončené',
		export: 'Vývozní',
		exportCSV: 'Údaje žadatele',
		exportCSVFull: 'Úplná databáze',
		filterContains: 'obsahuje',
		filterEquals: 'se rovná',
		filterStartsWith: 'začíná s',
		filterAfter: 'je po',
		filterOnOrAfter: 'je zapnuto nebo po něm',
		filterBefore: 'je dříve',
		filterOnOrBefore: 'je zapnuto nebo dříve',
		filterAdd: 'Přidat filtr',
		filterOperators: 'Operátoři',
		filterAnd: 'A',
		filterOr: 'Nebo',
		filterColumns: 'Sloupce',
		filterValue: 'Hodnota',
		filterValuePlaceholder: 'Hodnota filtru',
		filterIsEmpty: 'je prázdný',
		filterIsNotEmpty: 'není prázdný',
		columnSortLabel: 'Seřadit',
		filterFilters: 'Filtry',
		extendedView: 'Rozšířený pohled',
		exportQRCodes: 'Vstupenky s QR kódem',
		exportInfoHead: 'Vybraných {length, number} uchazečů!',
		exportInfoBody: 'Opravdu chcete exportovat tolik lístků?',
		exportInfoBodyError:
			'Nemůžete exportovat více než 250 žadatelů najednou. Chcete-li export zúžit, použijte „filtry“ nebo „vybrat“.',
		QRfileName: 'vstupenek s QR kódem žadatele',
		selectedRows: 'Vybrané řádky',
		importCSV: 'Importovat CSV',

		//? user delete
		deleteUserSuccess: 'Uživatel byl úspěšně odstraněn!',
		deleteUser: 'Odstranit uživatele:',
		deleteUserInfo: 'Pro odstranění vybraného uživatele, zadejte prosím jeho uživatelské jméno',
		deleteUserNameInfo: 'Uživatelské jméno',
		deleteButton: 'Odstranit',
		deleteErrorNotAllowed: 'Nemáte oprávnění k odstranění tohoto uživatele.',
		deleteErrorWrongUsername: 'Uživatelské jméno je nesprávné.',

		//? user create
		createUserSuccess: 'Uživatel byl úspěšně vytvořen!',
		addUser: 'Přidat nového uživatele',
		addUserFirstname: 'Jméno',
		addUserLastname: 'Příjmení',
		addUserUsername: 'Uživatelské jméno',
		addUserPassword: 'Heslo',
		addUserRole: 'Role',
		addUserAllFields: 'Všechna pole s * jsou povinná',
		addUserButton: 'Přidat',
		selectUserAdmin: 'Administrátor',
		selectUserHR: 'Zaměstnanci',
		selectUserTestingStaff: 'Testeri',
		createUserError4CharUsername: 'Uživatelské jméno musí mít alespoň 4 znaky',
		createUserError4CharPassword: 'Heslo musí mít alespoň 4 znaky',
		createUserErrorAllFields: 'Všechna pole jsou povinná',
		createUserErrorUsernameExists: 'Uživatelské jméno již existuje',
		createUserErrorFirstname: 'Jméno: Povoleny jsou pouze písmena',
		createUserErrorLastname: 'Příjmení: Povoleny jsou pouze písmena',
		createUserErrorUsername: 'Uživatelské jméno: Povolena jsou pouze písmena, čísla a podtržítka „_“.',
		createUserErrorPassword: 'Heslo: Povoleny jsou pouze písmena, čísla, + * # _ - $ !',

		//? applicant create
		addApplicant: 'Přidejte nového žadatele',
		createApplicatnErrorExists: 'Žadatel již existuje',
		createApplicantErrorBirthday: 'Narozeniny: Neplatné datum',
		createApplicantSuccess: 'Žadatel byl úspěšně vytvořen!',
		createApplicatnErrorEMail: 'Neplatná e-mailová adresa',

		//? qrCard
		applicant: 'Žadatel',

		//? selected applicant
		eMail: 'E-mailem',
		phoneNumber: 'Telefonní číslo',
		score: 'Skóre',
		test: 'Test',
		totalScore: 'Celkové skóre',
		applicantScore: 'Skóre žadatele',
		selectedLanguage: 'Vybraný jazyk',

		//? manual
		download: 'Stažení',
		report: 'Zpráva',

		//? other
		yes: 'Ano',
		no: 'Ne',
		snInfoText: 'Potvrďte prosím rukopis žadatele ANO nebo NE.',
		check: 'Šek',
		done: 'Hotovo',
		saveRating: 'Uložit hodnocení',
		cancel: 'Zrušení',
		cancelExport: 'Zrušit export',
		ok: 'OK',
		pleaseWait: 'Prosím, čekejte...',
		units: 'jednotky',
		passed: 'Prošel',
		failed: 'Nepodařilo se',
		is: 'je',
		testCompletedOn: 'Test dokončen dne',
		reset: 'Resetovat',
		submit: 'Předložit',
		addApplicants: 'Přidejte {count, number} žadatelů',
		info: 'Informace',

		//? snack
		exportProgress: 'Probíhá export lístku',
		exportSuccess: 'Export lístku byl úspěšný',

		//? CSV import
		importCSVSuccess: 'Import CSV byl úspěšný',
		importCSVNotAll: ' z {maxNumber, number} záznamů není vytvořeno',
		importCSVError: 'Import CSV se nezdařil',
		importCSVinPreogress: 'Probíhá import CSV',
		infoAtLeast2: 'Nahrajte prosím soubor CSV s alespoň 2 sloupci a hodnotami oddělenými čárkami.',
		infoTooMany: 'Příliš mnoho řádků v souboru CSV. Omezte prosím na 200 řádků.',
		infoOnlyCSV: 'Je povolen pouze jeden soubor CSV',
		dnd: 'Sem přetáhněte soubor nebo kliknutím vyberte soubor',
		csvImport: 'Import CSV',
		csvImportInfoHeader: 'Přetáhněte štítky do odpovídajících polí',
		applicantCount: 'Počet žadatelů',
	},
};

// learn_to: "Hello, let's learn how to use React-Intl",
// price_display:
// 	"How {n, number, ::currency/USD} is displayed in your selected language",
// number_display:
// 	"This is how {n, number} is formatted in the selected locale",
// start_today: "Start Today: {d, date}",
// // menu
// about_project: "About the project",
// contact_us: "Contact us",

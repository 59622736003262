import * as React from "react";
// import { generate, Template, BLANK_PDF } from "@pdfme/generator";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { decodeJWT } from "../../../globalStores/jwt";
import { usePDFexport } from "../../../globalStores/pdfExport";
import { rot18 } from "../../../scripts/rot";
import QRCode from "qrcode";
import { sjs, attr } from "slow-json-stringify";

const demo_app = window.location.hostname.includes("app.demo");

const stringify = sjs({
	forename: attr("string"),
	surname: attr("string"),
	uuid: attr("string"),
	id: attr("number"),
	transmissionCode: attr("string"),
	env: attr("string"),
	location: attr("string"),
});

//* workers
var pdfGenerator: any = null;
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 420,
	height: 200,
	bgcolor: "#FFFFFF",
	pl: 3,
	pr: 3,
	pt: 1,
	pb: 1,
	boxShadow:
		"0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
	borderRadius: "20px",
};

interface PDFdata {
	pdfExportInfoModalState: boolean;
	open: () => void;
	close: () => void;
	datagridValues: any;
	pdfExportInfo: {
		forenameInfo: string;
		surnameInfo: string;
		hmidInfo: string;
	};
}

export default function PDFexport(props: PDFdata) {
	const intl = useIntl();

	const { setPdfSnackState, setPdfExportDone, PDFworkerKill, setPDFworkerKill, setSelectedDatagridValues } =
		usePDFexport();

	const [warningModal, setWarningModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [LOCATION, setLOCATION] = React.useState<boolean | string>(false);
	React.useEffect(() => {
		console.log({ LOCATION });
		if (LOCATION === false) {
			setLOCATION(decodeJWT().location);
		}
	}, [LOCATION]);

	const generatePDFqrData = async (data: any) => {
		let tempData: any = [];
		console.log("generatePDFqrData");
		data.forEach(async (applicant: any) => {
			let tempApplicant = applicant;
			const qrCodeData = stringify({
				forename: applicant.forename,
				surname: applicant.surname,
				uuid: applicant.uuid,
				id: applicant.id,
				transmissionCode: applicant.transmission_code,
				env: RELEASE_NAME,
				location: LOCATION,
			});
			// console.log("\n\n--------------------------");
			// console.log("Raw:     " + qrCodeData);
			// console.log("Encoded: " + rot18(qrCodeData));
			// console.log("Decoded: " + rot18(rot18(qrCodeData)));
			// console.log("--------------------------");
			// const QRCODE = await QRCode.toDataURL(qrCodeData.toString(), {
			const QRCODE = await QRCode.toDataURL(rot18(qrCodeData), {
				// width: 300,
				margin: 0,
				color: {
					dark: "#000000",
					light: "#ffffff",
				},
			});
			tempApplicant.QRCODE = QRCODE;
			tempData.push(tempApplicant);
		});
		// console.log(tempData);
		return tempData;
	};

	const handleClose = () => {
		props.close();
		setWarningModal(false);
		setLoading(false);
	};

	const generatePDF = async (pdfData: any, pdfExportInfo: any) => {
		// handleClose();
		setPDFworkerKill(false);
		setPdfExportDone(false);
		setTimeout(() => {
			setPdfSnackState(true);
		}, 500);

		pdfGenerator = new Worker(new URL("../../../workers/pdfGenerator.ts", import.meta.url), { type: "module" });
		pdfGenerator.postMessage({
			pdfExportInfo: pdfExportInfo,
			pdfData: await generatePDFqrData(pdfData),
			location: LOCATION,
			hide_logo: demo_app,
		});
		pdfGenerator.onerror = (error: any) => {
			console.log(error);
		};
		pdfGenerator.onmessage = (e: any) => {
			const url = window.URL.createObjectURL(e.data[0]);
			const link = document.createElement("a");
			link.href = url;
			// link.setAttribute("download", e.data[1]);
			link.setAttribute(
				"download",
				e.data[1] +
					" " +
					intl.formatMessage({
						id: "units",
					}) +
					" - " +
					intl.formatMessage({
						id: "QRfileName",
					}) +
					".pdf"
			);
			document.body.appendChild(link);
			link.click();

			pdfGenerator.terminate();

			// setLoading(false);
			handleClose();
			// setPdfSnackState(false);
			setPdfExportDone(true);
			//* reset selected Datagrid Values
			// setSelectedDatagridValues([]);
		};
	};

	// React.useEffect(() => {
	// 	console.log(inputs);
	// }, [inputs]);
	React.useEffect(() => {
		PDFworkerKill && pdfGenerator?.terminate();
	}, [PDFworkerKill]);

	// React.useEffect(() => {
	// 	console.log(props.datagridValues);
	// }, [props.datagridValues]);

	React.useEffect(() => {
		if (props.pdfExportInfoModalState && props.datagridValues?.length <= 30) {
			generatePDF(props.datagridValues, props.pdfExportInfo);
		} else if (props.pdfExportInfoModalState) {
			setWarningModal(true);
		}
	}, [props.pdfExportInfoModalState]);

	return (
		<Modal
			open={warningModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 850,
				sx: {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					backdropFilter: "blur(8px)",
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}>
			<Box sx={style} className="noselect" id="pdfViewer">
				<Grid container sx={{ height: "100%" }} direction="column" justifyContent="center" alignItems="center">
					<Grid container direction="row" justifyContent="center" alignItems="center" mb={3}>
						<Grid item xs={12} textAlign="center">
							<Typography
								sx={{
									fontFamily: "Rubik",
									fontSize: "1.2rem",
									fontWeight: "bold",
								}}>
								<FormattedMessage id="exportInfoHead" values={{ length: props.datagridValues?.length }} />
							</Typography>
							<Typography
								sx={{
									fontFamily: "Rubik",
									fontSize: "1.2rem",
								}}>
								{props.datagridValues?.length < 250 ? (
									<FormattedMessage id="exportInfoBody" />
								) : (
									<FormattedMessage id="exportInfoBodyError" />
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={4} textAlign="center">
							<Button
								variant="contained"
								color="error"
								disabled={loading}
								style={{
									fontSize: 12,
									width: 125,
									minHeight: 30,
									fontFamily: "Rubik",
									color: "white",
								}}
								onClick={async () => {
									pdfGenerator?.terminate();
									handleClose();
								}}>
								<FormattedMessage id="cancel" />
							</Button>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={4} textAlign="center">
							<Button
								variant="contained"
								color="secondary"
								// disabled={loading || props.datagridValues?.length > 250}
								disabled={props.datagridValues?.length > 250 || loading}
								style={{
									fontSize: 12,
									width: 125,
									minHeight: 30,
									fontFamily: "Rubik",
								}}
								onClick={() => {
									// setLoading(true);
									// setTimeout(() => {
									generatePDF(props.datagridValues, props.pdfExportInfo);
									// }, 400);
									handleClose();
								}}>
								{loading ? <FormattedMessage id="pleaseWait" /> : <FormattedMessage id="yes" />}
							</Button>
							{/* {loading && (
								<CircularProgress
									size={24}
									sx={{
										color: "primary",
										position: "absolute",
										marginTop: "3px",
										marginLeft: "-72px",
									}}
								/>
							)} */}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}

import { create } from 'zustand';

type createApplicantToggleStore = {
	stateApplicantCreate: boolean;
	openApplicantCreate: () => void;
	closeApplicantCreate: () => void;
};

export const useApplicantCreateToggle = create<createApplicantToggleStore>((set) => ({
	stateApplicantCreate: false,
	openApplicantCreate: () => set({ stateApplicantCreate: true }),
	closeApplicantCreate: () => set({ stateApplicantCreate: false }),
}));

import React from "react";
import MDlogo from "../assets/logo with slogan.png";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Tooltip from "@mui/material/Tooltip";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { motion, AnimatePresence } from "framer-motion";
import userAuth from "../api/userAuth";
import { useNavigate } from "react-router-dom";
import { decodeJWT } from "../globalStores/jwt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LOCALES } from "../i18n/locales";
import { useLanguageSwitch } from "../globalStores/languageSwitch";
import { FormattedMessage, useIntl } from "react-intl";
import PreReleaseOverlay from "../components/preReleaseOverlay";
import AppManual from "../components/manual/appManual";
import BrandingModal from "../components/brandingModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import PDFexport from "../components/applicant/pdfExport/pdfExport";
import { usePDFexport } from "../globalStores/pdfExport";
import { useCSVimport } from "../globalStores/csvImport";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
const pdfSnackStyle = makeStyles({
	root: {
		top: 15,
		height: "50px",
	},
});
import Slide, { SlideProps } from "@mui/material/Slide";
type TransitionProps = Omit<SlideProps, "direction">;
function TransitionDown(props: TransitionProps) {
	return <Slide {...props} direction="down" />;
}
import HomeCards from "../components/home/homeCards";
import UserCards from "../components/user/userCards";
import ApplicantCards from "../components/applicant/applicantCards";

import dev from "../assets/flags/dev-logo.png";
import bulgaria from "../assets/flags/bulgaria.png";
import mexico from "../assets/flags/mexico.png";
import czech from "../assets/flags/czech-republic.png";
import Grid from "@mui/material/Grid";

const drawerWidth = 200;

export default function Dashboard() {
	const intl = useIntl();

	const { data, mutate, tokenValide } = userAuth();
	const navigate = useNavigate();
	const [urlPath, setUrlPath] = React.useState(dev);

	const [manualModal, setManualModal] = React.useState(false);

	const [brandingModalOpen, setBrandingModalOpen] = React.useState<any>(false);

	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const handleListItemClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
		setSelectedIndex(index);
		// mutate();
	};

	//* language selector
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openLanguageSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const closeLanguageSelector = () => {
		setAnchorEl(null);
	};
	const { LANGUAGE, setLanguage } = useLanguageSwitch();

	//* PDFexport
	const {
		pdfExportDone,
		pdfSnackState,
		setPdfSnackState,
		setPDFworkerKill,
		pdfExportInfoModalState,
		setPDFexportInfoModalState,
		selectedDatagridValues,
	} = usePDFexport();

	//* CSVimport
	const { csvSnackState, setCSVsnackState, csvImportResponse } = useCSVimport();

	//* handle logout
	const handlelogout = () => {
		// console.log(decoded);
		// console.log(jwt.decode(localStorage.getItem("userToken")));
		localStorage.removeItem("userToken");
		mutate({ token: "" });
		// console.log("jooo");
	};

	const handlePDFsnackClose = () => {
		setPDFworkerKill(true);
		setPdfSnackState(false);
	};

	React.useEffect(() => {
		if (pdfExportDone) {
			setTimeout(() => {
				setPdfSnackState(false);
			}, 2000);
		}
	}, [pdfExportDone]);

	React.useEffect(() => {
		if (csvImportResponse.state) {
			setTimeout(() => {
				setCSVsnackState(false);
			}, 7000);
		}
	}, [csvImportResponse]);

	//* redirect if token expired
	// React.useEffect(() => {
	// 	// if (!data) {
	// 	// 	history.push("/");
	// 	// }
	// 	console.log(data);
	// }, [data]);
	React.useEffect(() => {
		if (tokenValide === false) {
			// history.push("/");
			navigate("/");
			// console.log(tokenValide);
		}
		// console.log({ tokenValide });
	}, [tokenValide]);
	// React.useEffect(() => {
	// 	console.log({ data });
	// }, [data]);

	React.useEffect(() => {
		const location = window.location.hostname.substring(0, 2);
		switch (location) {
			case "bg":
				setUrlPath(bulgaria);
				break;

			case "mx":
				setUrlPath(mexico);
				break;

			case "cz":
				setUrlPath(czech);
				break;

			default:
				setUrlPath(dev);
				break;
		}
	}, []);

	const pdfExportInfo = {
		forenameInfo: (
			intl.formatMessage({
				id: "firstnameGrid",
			}) + ":"
		).toUpperCase(),
		surnameInfo: (
			intl.formatMessage({
				id: "lastnameGrid",
			}) + ":"
		).toUpperCase(),
		hmidInfo: (
			intl.formatMessage({
				id: "hmidGrid",
			}) + ":"
		).toUpperCase(),
	};

	const mainSection = () => {
		if (selectedIndex === 0) {
			return (
				<HomeCards
					forename={decodeJWT().forename}
					openAddNewApplicant={() => {
						setSelectedIndex(1);
						mutate();
					}}
					openTestResults={() => {
						setSelectedIndex(1);
						mutate();
					}}
				/>
			);
		} else if (selectedIndex === 1) {
			// return <ApplicantCards forename={decodeJWT().forename} />;
			return <ApplicantCards />;
		} else if (selectedIndex === 2) {
			return <UserCards />;
		} else return <></>;
	};

	return (
		// <AnimatePresence>
		// 	{useAnimatePresence && (
		<motion.div
			className="App"
			// initial={{ opacity: 0 }}
			initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5 }}
			exit={{ opacity: 0, transition: { duration: 0.5 } }}>
			{/* //* Overlay */}
			<PreReleaseOverlay />
			{/* //* PDFexport */}
			<PDFexport
				pdfExportInfoModalState={pdfExportInfoModalState}
				open={() => setPDFexportInfoModalState(true)}
				close={() => setPDFexportInfoModalState(false)}
				datagridValues={selectedDatagridValues}
				pdfExportInfo={pdfExportInfo}
			/>
			{/* //* Brand */}
			<BrandingModal
				toggle={brandingModalOpen}
				closeWindow={() => {
					setBrandingModalOpen(false);
				}}
			/>
			{/* //* SNACKS */}
			{/* //! PDF SNACK */}
			<Snackbar
				open={pdfSnackState}
				// open={true}
				TransitionComponent={TransitionDown}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				// onClose={handleClose}
				classes={{
					// root: classes.root,
					root: pdfSnackStyle().root,
				}}>
				<Grid
					container
					direction="row"
					pl={2}
					pr={2}
					pt={1}
					pb={1}
					sx={{
						borderRadius: "4px",
						width: "320px",
						backgroundColor: "header.main",
					}}>
					<Grid container item xs={2} justifyContent="flex-start" alignItems="center">
						<Box sx={{ display: "flex" }}>
							{pdfExportDone ? (
								<CheckIcon color="info" fontSize="large" />
							) : (
								<CircularProgress color="info" size={25} />
							)}
						</Box>
					</Grid>
					<Grid container item xs={8} justifyContent="flex-start" alignItems="center">
						<Typography
							sx={{
								fontFamily: "Rubik",
								fontWeight: 500,
								fontSize: "14px",
							}}>
							{pdfExportDone ? <FormattedMessage id="exportSuccess" /> : <FormattedMessage id="exportProgress" />}
						</Typography>
					</Grid>
					<Grid container item xs={2} justifyContent="flex-end" alignItems="center">
						<Tooltip title={<FormattedMessage id="cancelExport" />}>
							<span>
								<IconButton
									disabled={pdfExportDone}
									size="small"
									aria-label="close"
									color="inherit"
									onClick={handlePDFsnackClose}>
									<CloseIcon fontSize="medium" />
								</IconButton>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
			</Snackbar>
			{/* //! CSV SNACK */}
			<Snackbar
				open={csvSnackState}
				// open={true}
				TransitionComponent={TransitionDown}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				classes={{
					// root: classes.root,
					root: pdfSnackStyle().root,
				}}>
				<Grid
					container
					direction="row"
					pl={2}
					pr={2}
					pt={1}
					pb={1}
					sx={{
						borderRadius: "4px",
						width: "320px",
						// width: "auto",
						backgroundColor: "header.main",
					}}>
					<Grid container item xs={2} justifyContent="flex-start" alignItems="center">
						<Box sx={{ display: "flex" }}>
							{csvImportResponse.state === "success" ? (
								<CheckIcon color="info" fontSize="large" />
							) : csvImportResponse.state === "notAllCreated" ? (
								<WarningAmberIcon color="warning" fontSize="large" />
							) : csvImportResponse.state === "error" ? (
								<InfoIcon color="warning" fontSize="large" />
							) : (
								<CircularProgress color="info" size={25} />
							)}
						</Box>
					</Grid>
					<Grid container item pl={2} pr={2} xs justifyContent="center" alignItems="center">
						<Typography
							sx={{
								fontFamily: "Rubik",
								fontWeight: 500,
								fontSize: "14px",
							}}>
							{csvImportResponse.state === "success" ? (
								<FormattedMessage id="importCSVSuccess" />
							) : csvImportResponse.state === "notAllCreated" ? (
								csvImportResponse.message +
								intl.formatMessage(
									{
										id: "importCSVNotAll",
									},
									{
										maxNumber: csvImportResponse.maxNumber,
									}
								)
							) : csvImportResponse.state === "error" ? (
								<FormattedMessage id="importCSVError" />
							) : (
								<FormattedMessage id="importCSVinPreogress" />
							)}
						</Typography>
					</Grid>
				</Grid>
			</Snackbar>

			<Box sx={{ display: "flex" }}>
				<AppBar
					position="fixed"
					sx={{
						width: `calc(100% - ${drawerWidth}px)`,
						ml: `${drawerWidth}px`,
						backgroundColor: "transparent",
						boxShadow: "none",
					}}>
					<Toolbar>
						{/* <Typography variant="h6" noWrap component="div">
							Permanent drawer
						</Typography> */}
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: { xs: "flex", md: "flex" } }}>
							<Tooltip title={<FormattedMessage id="languages" />} placement="left">
								<IconButton
									size="large"
									edge="end"
									aria-label="language"
									color="primary"
									id="language-button"
									sx={{ mr: 5 }}
									// onClick={(event) => {
									// 	// console.log("change language");
									// 	openLanguageSelector;
									// }}
									onClick={openLanguageSelector}>
									<LanguageIcon />
								</IconButton>
							</Tooltip>
							{/* //* language menu */}
							<Menu
								id="language-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={closeLanguageSelector}
								// MenuListProps={{
								// 	"aria-labelledby": "basic-button",
								// }}
							>
								{Object.entries(LOCALES).map(([local, item]: any) => (
									<MenuItem
										key={local}
										onClick={() => {
											setAnchorEl(null);
											setLanguage(LOCALES[local]);
										}}>
										{item.NAME}
									</MenuItem>
								))}
							</Menu>
							<Tooltip title={<FormattedMessage id="logout" />} placement="bottom">
								<IconButton
									size="large"
									edge="end"
									aria-label="logout"
									color="primary"
									onClick={() => {
										handlelogout();
									}}>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						height: "100vh",
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
							boxSizing: "border-box",
						},
						boxShadow: "0 0 10px 2px rgba(0, 0, 0, 0.1)",
						clipPath: "inset(0px -40px 0px 0px)",
					}}
					variant="permanent"
					anchor="left">
					<Box>
						<img
							className={window.location.hostname.includes("app.demo") ? "demo-pic" : ""}
							src={MDlogo}
							style={{
								// paddingLeft: "auto",
								// paddingRight: "auto",
								width: 130,
								padding: "30px 0 30px 0",
							}}
							alt="MD-Logo"
						/>
					</Box>
					<Divider />
					{/* <List>
						{["Home", "Test Results", "User"].map((text, index) => (
							<ListItem button key={text}>
								<ListItemIcon>
									{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
								</ListItemIcon>
								<ListItemText primary={text} />
							</ListItem>
						))}
					</List> */}

					{/* <List component="nav" aria-label="main mailbox folders">
						<ListItemButton
							selected={selectedIndex === 0}
							// onClick={(event) => handleListItemClick(event, 0)}
						>
							<ListItemIcon>
								<HomeOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Home" />
						</ListItemButton>
						<ListItemButton
							selected={selectedIndex === 1}
							// onClick={(event) => handleListItemClick(event, 1)}
						>
							<ListItemIcon>
								<FormatListBulletedOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Test Results" />
						</ListItemButton>
						<ListItemButton
							selected={selectedIndex === 2}
							// onClick={(event) => handleListItemClick(event, 2)}
						>
							<ListItemIcon>
								<PersonOutlineOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="User" />
						</ListItemButton>
					</List> */}

					<Button
						variant={selectedIndex === 0 ? "contained" : "text"}
						onClick={(event) => handleListItemClick(event, 0)}
						color="primary"
						startIcon={<HomeOutlinedIcon style={{ fontSize: 23 }} />}
						style={{
							padding: 10,
							paddingLeft: 20,
							borderRadius: 7,
							margin: 20,
							marginTop: 40,
							fontFamily: "Open Sans",
							fontSize: 14,
							textTransform: "none",
							justifyContent: "flex-start",
						}}>
						<FormattedMessage id="home" />
					</Button>
					<Button
						variant={selectedIndex === 1 ? "contained" : "text"}
						onClick={(event) => handleListItemClick(event, 1)}
						color="primary"
						startIcon={<FormatListBulletedOutlinedIcon style={{ fontSize: 23 }} />}
						style={{
							padding: 10,
							paddingLeft: 20,
							borderRadius: 7,
							margin: 20,
							marginTop: 0,
							fontFamily: "Open Sans",
							fontSize: 14,
							textTransform: "none",
							justifyContent: "flex-start",
						}}>
						<FormattedMessage id="applicants" />
					</Button>
					{decodeJWT().permission == 1 ? (
						<Button
							// className={window.location.hostname.includes("app.demo") ? "demo" : ""}
							variant={selectedIndex === 2 ? "contained" : "text"}
							onClick={(event) => handleListItemClick(event, 2)}
							color="primary"
							startIcon={<PersonOutlineOutlinedIcon style={{ fontSize: 23 }} />}
							style={{
								padding: 10,
								paddingLeft: 20,
								borderRadius: 7,
								margin: 20,
								marginTop: 0,
								fontFamily: "Open Sans",
								fontSize: 14,
								textTransform: "none",
								justifyContent: "flex-start",
							}}>
							<FormattedMessage id="users" />
						</Button>
					) : (
						<></>
					)}

					<Box sx={{ flexGrow: 1 }} />
					<Button
						className={window.location.hostname.includes("app.demo") ? "demo" : ""}
						// disabled
						variant={selectedIndex === 3 ? "contained" : "text"}
						// onClick={(event) => handleListItemClick(event, 3)}
						onClick={(event) => {
							setManualModal(true);
						}}
						color="primary"
						startIcon={<HelpOutlineIcon style={{ fontSize: 23 }} />}
						style={{
							padding: 10,
							paddingLeft: 20,
							borderRadius: 7,
							margin: 20,
							marginTop: 0,
							marginBottom: 5,
							fontFamily: "Open Sans",
							fontSize: 14,
							textTransform: "none",
							justifyContent: "flex-start",
						}}>
						<FormattedMessage id="manual" />
					</Button>
					<Button
						className={window.location.hostname.includes("app.demo") ? "demo" : ""}
						// disabled
						variant={selectedIndex === 3 ? "contained" : "text"}
						// onClick={(event) => handleListItemClick(event, 3)}
						// onClick={(event) => {
						// 	setManualModal(true);
						// }}
						color="primary"
						startIcon={<ChatBubbleOutlineIcon style={{ fontSize: 23 }} />}
						style={{
							padding: 10,
							paddingLeft: 20,
							borderRadius: 7,
							margin: 20,
							marginTop: 0,
							fontFamily: "Open Sans",
							fontSize: 14,
							textTransform: "none",
							justifyContent: "flex-start",
						}}
						href="https://northrock-software.atlassian.net/servicedesk/customer/portal/2"
						target="_blank">
						<FormattedMessage id="report" />
					</Button>
					{/* <Button
						disabled
						variant={selectedIndex === 4 ? "contained" : "text"}
						onClick={(event) => handleListItemClick(event, 4)}
						color="primary"
						startIcon={<SettingsOutlinedIcon style={{ fontSize: 23 }} />}
						style={{
							padding: 10,
							paddingLeft: 20,
							borderRadius: 7,
							margin: 20,
							marginTop: 0,
							fontFamily: "Open Sans",
							fontSize: 14,
							textTransform: "none",
							justifyContent: "flex-start",
						}}
					>
						Dev Settings
					</Button> */}

					{/* //* Version */}
					<Typography
						style={{
							textAlign: "left",
							position: "relative",
							// color: "#1D1D1F",
							// fontFamily: "Rubik",
							// fontFamily: "Open Sans",
							fontFamily: "Roboto",
							// fontWeight: 700,
							fontSize: "10px",
							paddingBottom: 10,
							paddingLeft: 10,
						}}>
						<Tooltip title={<FormattedMessage id="locationInfo" />} placement="top">
							<img src={urlPath} style={{ height: 25, marginRight: 10 }}></img>
						</Tooltip>
						{/* {import.meta.env.VITE_version} */}
						{"v " + APP_VERSION}
						{/* setBrandingModalOpen */}
						<Tooltip
							className={window.location.hostname.includes("app.demo") ? "demo" : ""}
							title={<FormattedMessage id="info" />}
							placement="top">
							<InfoOutlinedIcon
								sx={{ fontSize: "15px", marginLeft: "10px", marginBottom: "-2px", cursor: "pointer" }}
								onClick={() => setBrandingModalOpen(true)}
							/>
						</Tooltip>
					</Typography>
				</Drawer>

				<Box
					component="main"
					// sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
					sx={{
						flexGrow: 1,
						bgcolor: "#F7F7F7",
						p: 3,
						pt: 10,
						pl: 8,
						overflowX: "hidden",
					}}>
					{/* <Toolbar /> */}
					{/* <HomeCards forename={decodedJWT.forename} />
					<UserCards forename={decodedJWT.forename} /> */}
					{mainSection()}
				</Box>
			</Box>
			<AppManual manualState={manualModal} closeManual={() => setManualModal(false)} />
		</motion.div>
		// 	)}
		// </AnimatePresence>
	);
}

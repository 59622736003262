import React from "react";
import Grid from "@mui/material/Grid";

import { useDroppable } from "@dnd-kit/core";

export const DropBox = (props: any) => {
	const [currentChildID, setCurrentChildID] = React.useState<string | null>(
		null
	);

	const { isOver, setNodeRef } = useDroppable({
		id: props.id,
		data: {
			// accepts: ['type1', 'type2'],
			currentChildID: currentChildID,
		},
	});
	const stylePassiv = {
		border: isOver ? "2px dashed #aeaeae" : "2px dashed #E0E0E0",
		backgroundColor: isOver ? "#fafafa" : "#fff",
		borderRadius: "5px",
		// width: "80%",
		minHeight: "36px",
		// display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
	const styleActive = {
		// border: "2px dashed #aeaeae",
		backgroundColor: "#f7f7f7",
		borderRadius: "5px",
		minHeight: "36px",
		alignItems: "center",
		justifyContent: "center",
	};

	React.useEffect(() => {
		if (props.children.props?.id != undefined) {
			setCurrentChildID(props.children.props.id);
		} else {
			setCurrentChildID(null);
		}
	}, [props.children]);

	return (
		<Grid
			container
			item
			xs={2.3}
			ref={setNodeRef}
			style={currentChildID != null ? styleActive : stylePassiv}
		>
			{props.children}
		</Grid>
	);
};

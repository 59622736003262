import React from 'react';
import ReactDOM from 'react-dom/client';
// import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(
	'a68035d2c1f75233467070056352245cT1JERVI6Mzc1NTQsRVhQSVJZPTE2NzYwNDUzOTgwMDAsS0VZVkVSU0lPTj0x',
);

//? BrowserRouter later?
import { MemoryRouter } from 'react-router-dom';
// import { BrowserRouter } from "react-router-dom";
import App from './App';
Sentry.init({
	dsn: 'https://352954925e453c83d9282840d0a9babd@o4507029099773952.ingest.de.sentry.io/4507407141896272',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	environment: RELEASE_NAME,
	// tracePropagationTargets: ['localhost', 'https://api.md.hr-monitor.software'],
	tracePropagationTargets: ['localhost', import.meta.env.VITE_apiURL],
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	// enabled: false, //! change to true
});
// npx @sentry/wizard@latest -i sourcemaps
// return <button onClick={() => methodDoesNotExist()}>Break the world</button>;

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Router>
// 			<App />
// 		</Router>
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<MemoryRouter>
			<App />
		</MemoryRouter>
	</React.StrictMode>,
);

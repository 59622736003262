import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import axios from 'axios';

// export default function userAuth() {
// 	let axConfig = {
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 	};
// 	// let axData = {
// 	// 	body: JSON.stringify(credentials),
// 	// };
// 	let axData = {
// 		token: sessionStorage.getItem("userToken"),
// 	};
// 	let sessionToken = sessionStorage.getItem("userToken");

// 	const fetcher = (url: string) =>
// 		axios.post(url, axData, axConfig).then((res) => res.data);

// 	// const { data, mutate, error } = useSWR(URL + "/api/user/auth", fetcher);
// 	const { data, mutate, error } = useSWR(
// 		[URL + "/api/user/auth", sessionToken],
// 		fetcher
// 	);

// 	// console.log(data);
// 	// console.log(error);
// 	// const loading = !data && !error;
// 	// const loggedOut = error && error.status === 403;

// 	return {
// 		// loading,
// 		// loggedOut,
// 		data,
// 		mutate,
// 		error,
// 	};
// }

export default function userAuth() {
	let axConfig = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// let getSessionToken = () => {
	// 	let localToken = localStorage.getItem("userToken");
	// 	if (localToken) return localToken;
	// 	else return false;
	// };

	// const fetcher = (url: string, localToken: string) => {
	const fetcher = ([url, localToken]: [string, string]) => {
		return axios.post(url, { token: localToken }, axConfig).then((res) => res.data);
	};
	var token = localStorage.getItem('userToken');
	const { data, mutate, error } = useSWR(
		[import.meta.env.VITE_apiURL + '/user/auth', token],
		// [import.meta.env.VITE_apiURL + "/user/auth", localStorage.getItem("userToken")],
		fetcher,
		{
			revalidateOnFocus: true,
			// revalidateOnMount: true,
			// revalidateIfStale: true,
			// refreshInterval: 3000,
			refreshInterval: (x) => {
				// console.log(x);
				// mutate();
				// console.log(localStorage.getItem("userToken"));
				// if (localStorage.getItem("userToken") === null) {
				// 	console.log("no token");
				// 	// mutate();
				// 	return 3000;
				// }
				// token = localStorage.getItem("userToken");
				return 5000;
			},
			refreshWhenHidden: true,
			// errorRetryInterval: 3000,
			// errorRetryCount: 2,
			shouldRetryOnError: false,
		},
	);
	const loading = !data && !error;
	// const loggedOut = error && error.status === 403;
	const tokenValide = error && error.status === 401;
	// console.log(error);

	return {
		loading,
		// loggedOut,
		tokenValide,
		data,
		mutate,
		error,
	};
}

import { createTheme } from "@mui/material/styles";

import "@fontsource/rubik";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";

import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/roboto";
import "@fontsource/montserrat";
import "@fontsource/montserrat/600.css";

export let MDtheme = createTheme({
	palette: {
		primary: {
			main: "#003D61",
			light: "#3D678F",
			dark: "#001737",
		},
		secondary: {
			// main: "#B0CB24",
			main: "#7D9A00",
			light: "#E5FE5C",
			dark: "#7D9A00",
			contrastText: "#fff",
		},
		third: {
			main: "#0090D7",
			light: "#5FC0FF",
			dark: "#0063A5",
			contrastText: "#fff",
		},
		error: {
			main: "#FF7D45",
			light: "#FFAE73",
			dark: "#C64D18",
		},
		header: {
			main: "#E6ECEF",
		},
		// //? Used by `getContrastText()` to maximize the contrast between
		// //? the background and the text.
		// contrastThreshold: 3,
		// //? Used by the functions below to shift a color's luminance by approximately
		// //? two indexes within its tonal palette.
		// //? E.g., shift from Red 500 to Red 300 or Red 700.
		// tonalOffset: 0.2,
	},
});

// MDtheme = createTheme(MDtheme, {
// 	palette: {
// 		third: {
// 			main: "#0090D7",
// 			light: "#5FC0FF",
// 			dark: "#0063A5",
// 			contrastText: "#fff",
// 		},
// 		header: {
// 			main: "#E6ECEF",
// 		},
// 	},
// });

declare module "@mui/material/styles" {
	interface Palette {
		third: Palette["primary"];
	}
	// allow configuration using `createTheme`
	interface PaletteOptions {
		third?: PaletteOptions["primary"];
	}
	interface PaletteColor {
		darker?: string;
	}
	interface SimplePaletteColorOptions {
		darker?: string;
	}

	//*-----------
	interface Palette {
		header: Palette["primary"];
	}

	// allow configuration using `createTheme`
	interface PaletteOptions {
		header?: PaletteOptions["primary"];
	}
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		third: true;
	}
}

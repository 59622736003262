import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { decodeJWT } from '../../../globalStores/jwt';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { DndContext } from '@dnd-kit/core';
import { DropBox } from './dropBox';
import { DragItem } from './dragItem';
import { snapCenterToCursor, restrictToWindowEdges } from '@dnd-kit/modifiers';
import submitCSV from '../../../api/submitCSV';
import { styled } from '@mui/system';
import { useCSVimport } from '../../../globalStores/csvImport';
import getApplicants from '../../../api/getApplicants';

const TableCellStyled = styled(TableCell)({
	fontFamily: 'Rubik',
	fontSize: 14,
});

interface Props {
	state: boolean;
	closeWindow: () => void;
	snackSuccessCSV: (message: string) => void;
}
const getKeyByValue = (object: any, value: string) => {
	return Object.keys(object).find((key) => object[key] === value);
};

const csvOrder = ['forname', 'surname', 'email', 'phone_number', 'birthday'];

export default function DnD(props: Props) {
	const intl = useIntl();
	const { mutate } = getApplicants();

	//* CSVimport
	const { setCSVsnackState, setCSVimportResponse } = useCSVimport();

	const [infoText, setInfoText] = React.useState('');
	const [borderColor, setBorderColor] = React.useState('#E0E0E0');

	const [applicantRowCount, setApplicantRowCount] = React.useState<number>(0);
	const [activeDragCount, setActiveDragCount] = React.useState<number>(0);
	const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
	//* csv headers
	const [uploadColumnsHeader, setUploadColumnsHeader] = React.useState<any>({});
	//* csv data, selectedable by header name
	const [uploadedCSVdata, setUploadedCSVdata] = React.useState<any>(false);

	const [previewData, setPreviewRows] = React.useState([
		['', '', '', '', ''],
		['', '', '', '', ''],
		['', '', '', '', ''],
	]);
	const [parent, setParent] = React.useState<any>({
		forname: null,
		surname: null,
		email: null,
		phone_number: null,
		birthday: null,
	});

	// React.useEffect(() => {
	// 	console.log({ uploadColumnsHeader });
	// 	console.log({ uploadedCSVdata });
	// }, [uploadColumnsHeader, uploadedCSVdata]);

	const handleReset = () => {
		setInfoText('');
		setBorderColor('#E0E0E0');
		setApplicantRowCount(0);
		setActiveDragCount(0);
		setDisableSubmit(true);
		setUploadColumnsHeader({});
		setUploadedCSVdata(false);
		// prettier-ignore
		setPreviewRows([
			['', '', '', '', ''],
			['', '', '', '', ''],
			['', '', '', '', ''],
		]);
		// prettier-ignore
		setParent({ forname: null, surname: null, email: null, phone_number: null, birthday: null });
	};
	const handleClose = () => {
		handleReset();
		props.closeWindow();
	};

	const handleSubmit = () => {
		// setDisableSubmit(true);
		setCSVimportResponse(false, 0, 0);
		setCSVsnackState(true);

		const activeValues = Object.values(parent);
		const iterateArray = uploadedCSVdata[parent.forname];

		let endDataRows: any = [];
		//* create rows to submit
		iterateArray.forEach((element: any, i: number) => {
			let tempRow: any = [];
			activeValues.forEach((value: any) => {
				if (value !== null) {
					tempRow.push(uploadedCSVdata[value][i]);
				} else {
					tempRow.push('');
				}
			});
			endDataRows.push(tempRow);
		});
		//* submit data
		const submit = async () => {
			const response = await submitCSV(JSON.stringify(endDataRows));
			// console.log(response);

			if (response.status === 200) {
				mutate();
				// console.log(await response.json());
				let data = await response.json();
				if (data.applicantsDismissed > 0) {
					setCSVimportResponse('notAllCreated', data.applicantsDismissed, applicantRowCount);
				} else {
					setCSVimportResponse('success', 0, 0);
				}
			}
			if (response.status === 500 || response.status === 400 || response.status === 404) {
				setCSVimportResponse('error', 0, 0);
			}
		};
		submit();
		handleClose();
	};

	React.useEffect(() => {
		if (uploadColumnsHeader !== null) {
			let count: number = 0;
			Object.values(uploadColumnsHeader).forEach((column: any) => {
				if (column) {
					count++;
				}
			});
			setActiveDragCount(count);
		}
	}, [uploadColumnsHeader]);

	React.useEffect(() => {
		const tableKeys = Object.keys(parent);
		const tableValues = Object.values(parent);
		const nullCount = tableValues.filter((value) => value === null).length;

		//* if values are set
		if (nullCount < tableKeys.length) {
			if (parent.forname && parent.surname) {
				setDisableSubmit(false);
			} else {
				setDisableSubmit(true);
			}

			let tempPreviewData: any = [];
			//* create preview rows
			for (let i = 0; i < previewData.length; i++) {
				let tempRow: any = [];
				tableValues.forEach((value: any) => {
					// console.log(value);
					if (value !== null) {
						tempRow.push(uploadedCSVdata[value][i]);
					} else {
						tempRow.push('');
					}
				});
				tempPreviewData.push(tempRow);
				// console.log(tempRow);
			}
			setPreviewRows(tempPreviewData);
		}
		if (nullCount == tableKeys.length) {
			setDisableSubmit(true);
			setPreviewRows([
				['', '', '', '', ''],
				['', '', '', '', ''],
				['', '', '', '', ''],
			]);
		}
	}, [parent]);

	// const onDrop = React.useCallback((acceptedFiles) => {
	const onDrop = (acceptedFiles: any) => {
		acceptedFiles.forEach((file: any) => {
			setInfoText('');
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');
			reader.onload = () => {
				try {
					const binaryStr = reader.result;
					var enc = new TextDecoder('utf-8');

					if (binaryStr instanceof ArrayBuffer) {
						const textDecode = enc.decode(new Uint8Array(binaryStr));
						var delimiter = ',';

						//* split new line
						const lines = textDecode.split('\n');
						const headerColumns = lines[0];

						//* , or ; and change delimiter
						if (headerColumns.includes(';')) {
							delimiter = ';';
						} else if (headerColumns.includes(',')) {
							delimiter = ',';
						}

						//* split columns and remove "\r"
						const headerColumnsArray = headerColumns.replace(/\r/g, '').split(delimiter);
						// if (headerColumnsArray.length < 2) {
						// 	setInfoText(
						// 		intl.formatMessage({
						// 			id: "infoAtLeast2",
						// 		})
						// 	);
						// 	setBorderColor("#FF7D45");
						// 	return;
						// }

						let tempHeaderColumns: any = {};
						headerColumnsArray.map((item: any) => {
							if (item == '') {
								return;
							}
							tempHeaderColumns[item] = true;
						});
						setUploadColumnsHeader(tempHeaderColumns);
						// console.log(tempHeaderColumns);
						if (Object.keys(tempHeaderColumns).length < 2) {
							setInfoText(
								intl.formatMessage({
									id: 'infoAtLeast2',
								}),
							);
							setBorderColor('#FF7D45');
							return;
						}
						let tempCSVdataOBJ: any = {};
						// headerColumnsArray.map(
						// 	(coloumnName: any, coloumnNameIndex: number) => {
						Object.keys(tempHeaderColumns).map((coloumnName: any, coloumnNameIndex: number) => {
							//* remove first line (header) and add each cloumn to array, selectable by header name
							const columnData = lines.slice(1).map((row, i) => {
								const rowArray = row.replace(/\r/g, '').split(delimiter);
								return rowArray[coloumnNameIndex];
							});
							if (coloumnNameIndex === 0) {
								if (columnData.length > 200) {
									setInfoText(
										intl.formatMessage({
											id: 'infoTooMany',
										}),
									);
									setBorderColor('#FF7D45');
									throw new Error('Too many rows in CSV file');
								}
								setApplicantRowCount(columnData.length);
							}
							tempCSVdataOBJ[coloumnName] = columnData;
						});
						setUploadedCSVdata(tempCSVdataOBJ);
					} else {
						throw new Error('Unexpected result');
					}
				} catch (error) {
					// console.log(error);
				}
			};
			reader.readAsArrayBuffer(file);
		});
		// }, []);
	};

	const {
		// acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps,
		isFocused,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			'text/csv': ['.csv'],
		},
	});

	React.useEffect(() => {
		if (isDragActive) {
			setInfoText('');
			setBorderColor('#aeaeae');
		} else {
			setBorderColor('#E0E0E0');
		}

		if (isDragActive && isDragAccept) {
			setInfoText('');
			setBorderColor('#0090D7');
		}
		if ((isDragActive && isDragReject) || fileRejections.length > 0) {
			setInfoText(
				intl.formatMessage({
					id: 'infoOnlyCSV',
				}),
			);
			setBorderColor('#FF7D45');
		}
	}, [isDragActive, isDragAccept, isDragReject, fileRejections]);

	const container = {
		initial: { height: 0 },
		show: {
			height: 'auto',
			transition: {
				duration: 0.55,
				delayChildren: 0.65,
			},
		},
		exit: {
			height: 0,
			opacity: 0,
			transition: {
				duration: 0.4,
				delay: 0.55,
			},
		},
	};

	const item = {
		initial: { opacity: 0 },
		show: {
			opacity: 1,
			transition: {
				duration: 0.55,
			},
		},
		exit: {
			opacity: 0,
			transition: {
				duration: 0.55,
			},
		},
	};

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={props.state}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 840,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
			}}
		>
			<motion.div
				initial={{
					opacity: 0,
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					translateX: '-50%',
					translateY: '-50%',
					width: 840,
					backgroundColor: '#FFFFFF',
					boxShadow:
						'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
					borderRadius: '20px',
				}}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				className='noselect'
			>
				<Grid container sx={{ pr: '7px', pt: '7px' }}>
					{/* <Grid item xs={10}></Grid> */}
					<Grid
						item
						xs={8}
						alignItems='center'
						justifyContent='flex-start'
						sx={{
							pl: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'start',
							fontFamily: 'Rubik',
							fontSize: 14,
						}}
					>
						{infoText}
					</Grid>
					<Grid
						item
						xs={3}
						alignItems='center'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'end',
						}}
					>
						<Button
							disabled={uploadedCSVdata != false ? false : true}
							onClick={() => {
								handleReset();
							}}
						>
							<FormattedMessage id='reset' />
						</Button>
					</Grid>
					<Grid item xs={1} sx={{ textAlign: 'right' }}>
						<IconButton
							aria-label='close'
							size='large'
							sx={{ ml: 1 }}
							onClick={() => {
								handleClose();
							}}
						>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems='center'
					sx={{
						p: 3,
						paddingTop: 0,
						minHeight: 135,
					}}
				>
					<AnimatePresence mode='wait' initial={false}>
						{!uploadedCSVdata && (
							<Grid
								key='upload-dropzone'
								container
								direction='column'
								alignItems='center'
								justifyContent='center'
								component={motion.div}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.4 }}
							>
								<Grid
									item
									xs={12}
									sx={{
										width: '100%',
										borderRadius: '5px',
										border: '2px dashed #E0E0E0',
										borderColor: borderColor,
										p: 3,
										userSelect: 'none',
										'&:hover': {
											cursor: 'pointer',
											border: '2px dashed #aeaeae',
										},
									}}
									{...getRootProps()}
								>
									<section>
										<input {...getInputProps()} />
										<Typography
											sx={{
												textAlign: 'center',
												fontFamily: 'Rubik',
												fontSize: 18,
												// fontWeight: "bold",
												color: 'primary.dark',
												// textTransform: "uppercase",
												p: 2,
											}}
										>
											<FormattedMessage id='dnd' />
											{/* Drag 'n' drop a file here, or click to select one */}
										</Typography>
									</section>
								</Grid>
							</Grid>
						)}
						{uploadedCSVdata && (
							<motion.div
								style={{
									width: '100%',
								}}
								key='handle-csv'
								variants={container}
								initial='initial'
								animate='show'
								exit='exit'
							>
								<motion.div variants={item}>
									<DndContext
										// collisionDetection={closestCenter}
										onDragEnd={(e: any) => {
											const { over, active } = e;
											// console.log({ active });
											// console.log({ over });
											// console.log(over.data.current);
											// console.log(over.id);
											if (over) {
												setUploadColumnsHeader({
													...uploadColumnsHeader,
													[active.id]: false,
													// [active.id]: !active.id,
												});

												//* check if already in one of the drop zones && check if drope zone is NOT empty
												if (Object.values(parent).includes(active.id) && over.data.current.currentChildID !== null) {
													const parentId = getKeyByValue(parent, active.id);
													setParent({
														...parent,
														//@ts-ignore
														[parentId?.toString()]: over.data.current.currentChildID,
														[over.id]: active.id,
													});
												}
												//* check if already in one of the drop zones
												else if (Object.values(parent).includes(active.id)) {
													const parentId = getKeyByValue(parent, active.id);
													setParent({
														...parent,
														//@ts-ignore
														[parentId?.toString()]: null,
														[over.id]: active.id,
													});
												}
												//* check if drope zone is NOT empty
												else if (over.data.current.currentChildID !== null) {
													setUploadColumnsHeader({
														...uploadColumnsHeader,
														[over.data.current.currentChildID]: true,
														[active.id]: false,
													});
													setParent({
														...parent,
														[over.id]: active.id,
													});
												} else {
													setParent({
														...parent,
														[over.id]: active.id,
													});
												}
											} else if (parent[active.data.current.hoveredOver] === active.id) {
												setParent({
													...parent,
													[active.data.current.hoveredOver]: null,
												});
												setUploadColumnsHeader({
													...uploadColumnsHeader,
													[active.id]: true,
												});
											}
										}}
										modifiers={[snapCenterToCursor, restrictToWindowEdges]}
									>
										<Grid item xs={12}>
											<Typography
												sx={{
													// fontFamily: "Open Sans",
													// fontSize: 20,
													// color: "primary.dark",
													// textAlign: "left",
													// pt: 1,
													// pb: 2.7,
													textAlign: 'left',
													fontFamily: 'Rubik',
													fontSize: 26,
													fontWeight: 'bold',
													color: 'primary.dark',
													textTransform: 'uppercase',
												}}
											>
												<FormattedMessage id='csvImport' />
											</Typography>
											<Typography
												sx={{
													// fontFamily: "Open Sans",
													// fontSize: 20,
													// color: "primary.dark",
													// textAlign: "left",
													// pt: 1,
													pb: 2.7,
													textAlign: 'left',
													fontFamily: 'Rubik',
													fontSize: 14,
													// fontWeight: "bold",
													color: 'primary.dark',
													// textTransform: "uppercase",
												}}
											>
												<FormattedMessage id='csvImportInfoHeader' />
											</Typography>

											<Grid
												container
												direction='row'
												alignItems='center'
												columnSpacing={1.5}
												rowSpacing={1.5}
												component={motion.div}
												initial={false}
												animate={{
													height:
														// prettier-ignore
														'calc(' + Math.ceil(activeDragCount / 5) * 60 + 'px)',
													// maxHeight: "calc(" + 60 * 5 + "px)",
													// overflow: "auto",
													// overflowX: "hidden",
													// overflowY:
													// 	selectColumnsContainerRef?.current?.clientHeight >
													// 	rowHeight * 2
													// 		? "auto"
													// 		: "hidden",
												}}
												transition={{
													duration: 0.7,
												}}
											>
												{/* <AnimatePresence mode='wait'> */}
												{/* //? dragItems */}
												{Object.keys(uploadColumnsHeader).map((uploadColumn: any, i: number) => {
													return Object.values(uploadColumnsHeader)[i] ? (
														<DragItem id={uploadColumn} key={i}>
															{uploadColumn}
														</DragItem>
													) : null;
												})}
												{/* </AnimatePresence> */}
											</Grid>
										</Grid>

										<Grid item xs={12} mt={2.5}>
											<Divider />
											<Grid
												container
												direction='row'
												alignItems='center'
												justifyContent='space-between'
												pt={2.5}
												pb={2.5}
											>
												{/* //? dropBoxes */}
												{csvOrder.map((column: any, i: number) => {
													return (
														<DropBox key={i} id={column}>
															{parent[column] != null ? <DragItem id={parent[column]}>{parent[column]}</DragItem> : ''}
														</DropBox>
													);
												})}
											</Grid>

											<TableContainer
												sx={{
													border: '2px solid #E0E0E0',
													borderRadius: '5px',
												}}
											>
												<Table size='small'>
													<TableHead>
														<TableRow>
															<TableCellStyled width={'20%'}>
																<FormattedMessage id='firstnameGrid' />*
															</TableCellStyled>
															<TableCellStyled width={'20%'}>
																<FormattedMessage id='lastnameGrid' />*
															</TableCellStyled>
															<TableCellStyled width={'20%'}>
																<FormattedMessage id='eMail' />
															</TableCellStyled>
															<TableCellStyled width={'20%'}>
																<FormattedMessage id='phoneNumber' />
															</TableCellStyled>
															<TableCellStyled width={'20%'}>
																<FormattedMessage id='birthdayApplicantGrid' />
															</TableCellStyled>
														</TableRow>
													</TableHead>

													{/* //* example data */}
													<TableBody>
														{previewData.map((row, i) => (
															<TableRow key={i} sx={{ height: '33.02px' }}>
																<TableCellStyled component='th' scope='row'>
																	{row[0]}
																</TableCellStyled>
																<TableCellStyled>{row[1]}</TableCellStyled>
																<TableCellStyled>{row[2]}</TableCellStyled>
																<TableCellStyled>{row[3]}</TableCellStyled>
																<TableCellStyled>{row[4]}</TableCellStyled>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
											<Typography
												sx={{
													fontFamily: 'Rubik',
													fontSize: 10,
													pt: 0.3,
												}}
											>
												<FormattedMessage id='addUserAllFields' />
											</Typography>
										</Grid>
										<Grid container alignItems='center' justifyContent='center' pt={2}>
											<Grid item xs={4}>
												{/* <Typography
													sx={{
														fontFamily: "Rubik",
														fontSize: 14,
														minHeight: "21px",
													}}
												>
													<FormattedMessage id="applicantCount" />
													{": " + applicantRowCount}
												</Typography> */}
											</Grid>
											<Grid item container alignItems='center' justifyContent='center' xs={4}>
												<Button
													variant='contained'
													color='secondary'
													disabled={disableSubmit}
													sx={{
														p: 1,
														pr: 2,
														pl: 2,
														fontFamily: 'Rubik',
													}}
													onClick={() => {
														handleSubmit();
													}}
												>
													<FormattedMessage
														id='addApplicants'
														values={{
															count: applicantRowCount,
														}}
													/>
												</Button>
											</Grid>
											<Grid item xs={4}></Grid>
										</Grid>
									</DndContext>
								</motion.div>
							</motion.div>
						)}
					</AnimatePresence>
				</Grid>
			</motion.div>
		</Modal>
	);
}

import { create } from 'zustand';

interface item {
	name: string;
	testScore?: number | string;
	average?: number | string;
}
type createChartData = {
	chartData: item[];
	averageData: item[];
	setChartData: (results: any) => void;
	resetChartData: () => void;
	setAverageChartData: (results: any) => void;
	resetAverageChartData: () => void;
};

export const useChartData = create<createChartData>((set) => ({
	chartData: [
		{
			// name: "PV",
			name: 'pvI',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Left Eye Hand * Coordination",
			name: 'lehc',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Right Eye Hand * Coordination",
			name: 'rehc',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Sustained * Attention",
			name: 'sa',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Color Vision",
			name: 'cv',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Basic Math",
			name: 'bmI',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Basic Math II",
			name: 'bmII',
			testScore: '-',
			average: 5,
		},
		{
			// name: "Basic Math II",
			name: 'bmIII',
			testScore: '-',
			average: 5,
		},
		// {
		// 	// name: "Language",
		// 	name: "ul",
		// 	testScore: "-",
		// 	average: 5,
		// },
		{
			// name: "PV II",
			name: 'pvII',
			testScore: '-',
			average: 5,
		},
	],

	averageData: [
		{
			// name: "PV",
			name: 'pvI',
			average: 5,
		},
		{
			// name: "Left Eye Hand * Coordination",
			name: 'lehc',
			average: 5,
		},
		{
			// name: "Right Eye Hand * Coordination",
			name: 'rehc',
			average: 5,
		},
		{
			// name: "Sustained * Attention",
			name: 'sa',
			average: 5,
		},
		{
			// name: "Color Vision",
			name: 'cv',
			average: 5,
		},
		{
			// name: "Basic Math",
			name: 'bmI',
			average: 5,
		},
		{
			// name: "Basic Math II",
			name: 'bmII',
			average: 5,
		},
		{
			// name: "Basic Math II",
			name: 'bmIII',
			average: 5,
		},
		// {
		// 	// name: "Language",
		// 	name: "ul",
		// 	average: 5,
		// },
		{
			// name: "PV II",
			name: 'pvII',
			average: 5,
		},
	],

	setChartData: (results) => {
		// set((state) => {
		// 	let temp: any = [];
		// 	state.chartData.map((item, index) => {
		// 		let newItem = item;
		// 		newItem.testScore = results[index][0] !== null ? results[index][0] : null;
		// 		newItem.average = results[index][1];
		// 		temp.push(newItem);
		// 	});
		// 	state.chartData = temp;
		// });
		set((state) => {
			const temp = state.chartData.map((item, index) => ({
				...item,
				testScore: results[index][0] !== null ? results[index][0] : null,
				average: results[index][1],
			}));
			return { chartData: temp };
		});
	},
	resetChartData: () => {
		// set((state) => {
		// 	let temp: any = [];
		// 	state.chartData.map((item, index) => {
		// 		let newItem = item;
		// 		newItem.testScore = '-';
		// 		temp.push(newItem);
		// 	});
		// 	// console.log("chart ressetet");
		// 	state.chartData = temp;
		// });
		set((state) => ({
			chartData: state.chartData.map((item) => ({
				...item,
				testScore: '-',
			})),
		}));
	},

	setAverageChartData: (results) => {
		// console.log(results);
		// set((state) => {
		// 	state.averageData = results;
		// 	let temp: any = [];
		// 	state.averageData.map((item, index) => {
		// 		let newItem = item;
		// 		newItem.average = results[index] !== null ? results[index] : null;
		// 		temp.push(newItem);
		// 		// console.log(temp);
		// 	});
		// 	console.log(temp);
		// 	state.averageData = temp;
		// });

		// set((state) => {
		// 	let temp: any = [];
		// 	state.averageData.map((item, index) => {
		// 		let newItem = item;
		// 		newItem.average = results[index] !== null ? results[index] : null;
		// 		temp.push(newItem);
		// 	});
		// 	// state.averageData = temp;
		// });

		set((state) => {
			// let temp: any = [];
			const temp = state.averageData.map((item, index) => ({
				...item,
				average: results[index] !== null ? results[index] : null,
			}));
			return { averageData: temp };
		});
	},

	resetAverageChartData: () => {
		// set((state) => {
		// 	let temp: any = [];
		// 	state.averageData.map((item, index) => {
		// 		let newItem = item;
		// 		newItem.average = 5;
		// 		temp.push(newItem);
		// 	});
		// 	// console.log("chart ressetet");
		// 	state.averageData = temp;
		// });
		set((state) => ({
			averageData: state.averageData.map((item) => ({
				...item,
				average: 5,
			})),
		}));
	},
}));

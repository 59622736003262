import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NRS from "../assets/brand/nrs.png";
import DiveInn from "../assets/brand/dive-inn.png";
import INPUT1 from "../assets/brand/input1st.jpg";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
	toggle: boolean;
	closeWindow: () => void;
}
export default function BrandingModal(props: Props) {
	const handleClose = () => {
		props.closeWindow();
	};

	React.useEffect(() => {}, []);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={props.toggle}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					backdropFilter: "blur(8px)",
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}>
			<motion.div
				initial={{
					opacity: 0,
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					translateX: "-50%",
					translateY: "-50%",
					minWidth: 350,
					// minHeight: 600,
					// height: 564,
					backgroundColor: "#FFFFFF",
					boxShadow:
						"0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
					borderRadius: "20px",
				}}
				// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className="noselect">
				{/* //! close */}
				<Grid container sx={{ pr: "7px", pt: "7px" }}>
					<Grid container item xs={10} alignItems="center" justifyContent="start">
						{/* <Typography
							sx={{
								pl: 4,
								fontSize: "1rem",
								fontFamily: "Open Sans",
								color: "error.dark",
							}}>
						</Typography> */}
					</Grid>
					<Grid item xs={2} sx={{ textAlign: "right" }}>
						<IconButton aria-label="delete" size="large" sx={{ ml: 1 }} onClick={handleClose}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems="center"
					sx={{
						padding: "32px",
						paddingTop: 0,
						marginTop: "70px",
					}}>
					<Grid container direction="column" alignItems="center" justifyContent="center">
						<Grid container item xs={12}>
							<Typography
								sx={{
									display: "inline",
									fontSize: "4rem",
									fontFamily: "Open Sans",
									fontWeight: "600",
									paddingRight: "10px",
								}}>
								MD
							</Typography>
							<Typography
								sx={{
									display: "inline",
									fontSize: "4rem",
									fontFamily: "Open Sans",
									marginRight: "14px",
								}}>
								HR-Monitor
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{
								marginTop: "-20px",
								marginLeft: "10px",
							}}>
							<Typography
								sx={{
									fontSize: "1.5rem",
									fontFamily: "Open Sans",
								}}>
								{"Version " + APP_VERSION}
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							justifyContent="center"
							alignItems="center"
							sx={{ minHeight: 150, marginBottom: "20" }}>
							<Typography
								sx={{
									fontSize: "1.5rem",
									fontFamily: "Open Sans",
									fontStyle: "italic",
									fontWeight: "600",
								}}>
								{RELEASE_NAME}
							</Typography>
						</Grid>
						<Grid container item xs={12}>
							<Typography
								sx={{
									fontSize: ".7rem",
									fontFamily: "Open Sans",
									fontWeight: "700",
									marginLeft: "10px",
								}}>
								Developed by
							</Typography>
						</Grid>
						<Grid container item xs justifyContent="space-between" sx={{ width: "470px" }}>
							<Grid container item xs={4} justifyContent="flex-start" alignItems="center">
								<a href="https://northrock.software/" target="_blank" style={{ maxWidth: "95%" }}>
									<img src={NRS} style={{ maxWidth: "100%" }} />
								</a>
							</Grid>
							<Grid container item xs={4} justifyContent="center" alignItems="center">
								<a href="https://input1st.com/" target="_blank" style={{ maxWidth: "90%" }}>
									<img src={INPUT1} style={{ maxWidth: "100%" }} />
								</a>
							</Grid>
							<Grid container item xs={4} justifyContent="center" alignItems="center">
								<a href="https://dive-inn.rocks/" target="_blank" style={{ maxWidth: "80%" }}>
									<img src={DiveInn} style={{ maxWidth: "100%" }} />
								</a>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</motion.div>
		</Modal>
	);
}

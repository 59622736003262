import { create } from 'zustand';

type createPDFexportStore = {
	csvSnackState: boolean;
	setCSVsnackState: (csvSnackState: boolean) => void;

	csvImportResponse: {
		state: boolean | string;
		message: number | string;
		maxNumber: number | string;
	};
	setCSVimportResponse: (
		csvImportResponseState: boolean | string,
		csvImportResponseMessage: number | string,
		maxNumber: number | string,
	) => void;
};

export const useCSVimport = create<createPDFexportStore>((set, get) => ({
	csvSnackState: false,
	setCSVsnackState: (csvSnackState: boolean) => {
		set({
			csvSnackState: csvSnackState,
		});
	},

	csvImportResponse: { state: false, message: 0, maxNumber: 0 },
	// csvImportResponse: { state: "notAllCreated", message: "2", maxNumber: "10" },
	setCSVimportResponse: (csvImportResponseState, csvImportResponseMessage, maxNumber) => {
		set({
			csvImportResponse: {
				state: csvImportResponseState,
				message: csvImportResponseMessage,
				maxNumber: maxNumber,
			},
		});
	},
}));

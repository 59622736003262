import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { create } from 'zustand';
import { motion, AnimatePresence } from 'framer-motion';
import getUsers from '../../api/getUsers';
import { FormattedMessage } from 'react-intl';

// import { styled } from '@mui/system';

// const Backdrop = styled('div')`
//   z-index: -1;
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   -webkit-tap-highlight-color: transparent;
// `;

// * Stores
type createUserStore = {
	userData: {
		permission: string;
		username: string;
		password: string;
		forename: string;
		surname: string;
	};
	addPermission: (data: string) => any;
	addUsername: (data: string) => any;
	addPassword: (data: string) => any;
	addForename: (data: string) => any;
	addSurname: (data: string) => any;
	clearUserData: () => any;
};
const useUserCreate = create<createUserStore>((set) => ({
	userData: {
		permission: '',
		username: '',
		password: '',
		forename: '',
		surname: '',
	},
	addPermission: (data: string) => set((state) => ({ userData: { ...state.userData, permission: data } })),
	addUsername: (data: string) => set((state) => ({ userData: { ...state.userData, username: data } })),
	addPassword: (data: string) => set((state) => ({ userData: { ...state.userData, password: data } })),
	addForename: (data: string) => set((state) => ({ userData: { ...state.userData, forename: data } })),
	addSurname: (data: string) => set((state) => ({ userData: { ...state.userData, surname: data } })),
	clearUserData: () =>
		set((state) => ({
			userData: {
				...state.userData,
				permission: '',
				username: '',
				password: '',
				forename: '',
				surname: '',
			},
		})),
}));

interface ErrorInterface {
	message: string | JSX.Element;
	allFields: boolean;
	permission: boolean;
	username: boolean;
	password: boolean;
	forename: boolean;
	surname: boolean;
}

interface Props {
	toggle: boolean;
	closeWindow: () => void;
	snackSuccess: (message: string | JSX.Element) => void;
}

const userRoles = [
	{
		value: '1',
		// label: "Administrator",
		label: <FormattedMessage id='selectUserAdmin' />,
	},
	{
		value: '2',
		// label: "Human Resources",
		label: <FormattedMessage id='selectUserHR' />,
	},
	{
		value: '3',
		// label: "Testing Staff",
		label: <FormattedMessage id='selectUserTestingStaff' />,
	},
];

export default function createUser(props: Props) {
	const { userData, addPermission, addUsername, addPassword, addForename, addSurname, clearUserData } = useUserCreate();
	const [role, setUserRole] = React.useState('');
	const handleUserRole = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorState({
			...errorState,
			message: '',
			permission: false,
		});
		setUserRole(event.target.value);
		addPermission(event.target.value);
	};
	const { mutate } = getUsers();

	//* error state
	const [errorState, setErrorState] = React.useState<ErrorInterface>({
		message: '',
		allFields: false,
		permission: false,
		username: false,
		password: false,
		forename: false,
		surname: false,
	});

	let closeAndReset = () => {
		props.closeWindow();
		clearUserData();
		setUserRole('');
		setErrorState({
			message: '',
			allFields: false,
			permission: false,
			username: false,
			password: false,
			forename: false,
			surname: false,
		});
	};

	//* request add new user
	const addnewUser = async () => {
		const localToken = localStorage.getItem('userToken');
		const request = await fetch(import.meta.env.VITE_apiURL + '/user/register', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token: localToken ? localToken : 'noToken',
			},
			body: JSON.stringify(userData),
		});
		return request;
	};

	const handleSubmit = async () => {
		//* check if any field has errorState
		if (
			errorState.permission ||
			errorState.username ||
			errorState.password ||
			errorState.forename ||
			errorState.surname
		) {
			return;
		}

		//* user input length validation
		if (userData.username.length < 4) {
			console.log('userData.username.length');
			setErrorState({
				...errorState,
				// message: "Username must be at least 4 characters long",
				message: <FormattedMessage id='createUserError4CharUsername' />,
				username: true,
			});
			return;
		} else if (userData.password.length < 4) {
			setErrorState({
				...errorState,
				// message: "Password must be at least 4 characters long",
				message: <FormattedMessage id='createUserError4CharPassword' />,
				password: true,
			});
			return;
		}

		//* api response object
		const request = await addnewUser();
		//* api response message
		const response: string = await request.json();
		// console.log(request);
		// console.log(response);
		console.log(request.status);
		//* ok
		if (request.status == 200) {
			// props.closeWindow();
			// clearUserData();
			// setUserRole("");
			closeAndReset();
			mutate();
			// props.snackSuccess("User created successfully");
			props.snackSuccess(<FormattedMessage id='createUserSuccess' />);
		}
		//* error all fields are required
		if (request.status == 400) {
			setErrorState({
				// ...errorState,
				// message: response,
				// message: "All fields are required",
				message: <FormattedMessage id='createUserErrorAllFields' />,
				allFields: true,
				permission: userData.permission == '' || errorState.permission ? true : false,
				username: userData.username == '' || errorState.username ? true : false,
				password: userData.password == '' || errorState.password ? true : false,
				forename: userData.forename == '' || errorState.forename ? true : false,
				surname: userData.surname == '' || errorState.surname ? true : false,
			});
		}
		//* error user already exists
		if (request.status == 409) {
			setErrorState({
				// ...errorState,
				// message: "Username already exists",
				message: <FormattedMessage id='createUserErrorUsernameExists' />,
				allFields: true,
				permission: userData.permission == '' || errorState.permission ? true : false,
				username: userData.username == '' || errorState.username ? true : false,
				password: userData.password == '' || errorState.password ? true : false,
				forename: userData.forename == '' || errorState.forename ? true : false,
				surname: userData.surname == '' || errorState.surname ? true : false,
			});
		}
	};

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={props.toggle}
			onClose={closeAndReset}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}
		>
			<motion.div
				initial={{
					opacity: 0,
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					// transform: "translate(-50%, -50%)",
					translateX: '-50%',
					translateY: '-50%',
					width: 500,
					// minHeight: 600,
					backgroundColor: '#FFFFFF',
					boxShadow:
						'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
					borderRadius: '20px',
					// padding: 32,
				}}
				// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className='noselect'
			>
				{/* //! close */}
				<Grid container sx={{ pr: '7px', pt: '7px' }}>
					<Grid item xs={10}></Grid>
					<Grid item xs={2} sx={{ textAlign: 'right' }}>
						<IconButton aria-label='delete' size='large' sx={{ ml: 1 }} onClick={closeAndReset}>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems='center'
					sx={{
						padding: '32px',
						paddingTop: 0,
					}}
				>
					<Grid item xs={12}>
						<Typography
							sx={{
								textAlign: 'left',
								fontFamily: 'Rubik',
								fontSize: 26,
								fontWeight: 'bold',
								color: 'primary.dark',
								textTransform: 'uppercase',
							}}
						>
							<FormattedMessage id='addUser' />
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography
							sx={{
								textAlign: 'left',
								fontFamily: 'Rubik',
								fontSize: 14,
								minHeight: '21px',
								color: 'error.main',
							}}
						>
							{/* {errorFirstname.message} */}
							{errorState.message}
						</Typography>
					</Grid>
					{/* <Grid item xs={1}>
							<IconButton
								aria-label="delete"
								size="large"
								sx={{ p: 1, ml: 2 }}
								onClick={closeAndReset}
								// onClick={() => {
								// 	props.closeWindow();
								// 	clearUserData();
								// 	setUserRole("");
								// }}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						</Grid> */}
					<Grid container alignItems='center' spacing={3} sx={{ pt: 4.5 }}>
						<Grid item xs={6} style={{ paddingTop: '0' }}>
							<TextField
								error={errorState.forename ? errorState.forename : false}
								required
								fullWidth
								id='firstname'
								label={<FormattedMessage id='addUserFirstname' />}
								type='text'
								variant='outlined'
								inputProps={{ maxLength: 18 }}
								value={userData.forename}
								onChange={(e) => {
									addForename(e.target.value);
									if (/[^a-zA-Z]/gi.test(e.target.value)) {
										setErrorState({
											...errorState,
											// message: "Firstname: Only letters are allowed",
											message: <FormattedMessage id='createUserErrorFirstname' />,
											forename: true,
										});
									} else {
										setErrorState({
											...errorState,
											message: '',
											forename: false,
										});
									}
								}}
							/>
						</Grid>
						<Grid item xs={6} style={{ paddingTop: '0' }}>
							<TextField
								error={errorState.surname ? errorState.surname : false}
								required
								fullWidth
								id='lastname'
								label={<FormattedMessage id='addUserLastname' />}
								type='text'
								variant='outlined'
								inputProps={{ maxLength: 18 }}
								onChange={(e) => {
									addSurname(e.target.value);
									// if (/[^a-zA-Z]/gi.test(e.target.value)) {
									if (/[€@§#+^?!"§%&{}.()\[\]=\\/\d]/gi.test(e.target.value) || /[\s]{2,}/gi.test(e.target.value)) {
										setErrorState({
											...errorState,
											// message: "Lastname: Only letters are allowed",
											message: <FormattedMessage id='createUserErrorLastname' />,
											surname: true,
										});
									} else {
										setErrorState({
											...errorState,
											message: '',
											surname: false,
										});
									}
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								error={errorState.username ? errorState.username : false}
								required
								fullWidth
								id='username'
								label={<FormattedMessage id='addUserUsername' />}
								type='text'
								variant='outlined'
								inputProps={{
									maxLength: 14,
									// inputMode: "text",
									// pattern: "[A-Za-z]*",
								}}
								onChange={(e) => {
									addUsername(e.target.value);
									if (/[^a-zA-Z0-9_]/gi.test(e.target.value)) {
										setErrorState({
											...errorState,
											// message:
											// 	'Username: Only letters, numbers and underscores "_" are allowed',
											message: <FormattedMessage id='createUserErrorUsername' />,
											username: true,
										});
									} else {
										setErrorState({
											...errorState,
											message: '',
											username: false,
										});
									}
									// if (e.target.value.length < 3) {
									// 	setErrorState({
									// 		...errorState,
									// 		message: "Username must be at least 3 characters long",
									// 		username: true,
									// 	});
									// }
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								error={errorState.password ? errorState.password : false}
								required
								fullWidth
								id='password'
								label={<FormattedMessage id='addUserPassword' />}
								type='password'
								// type="text"
								variant='outlined'
								inputProps={{ maxLength: 24 }}
								onChange={(e) => {
									addPassword(e.target.value);
									if (/[^a-zA-Z0-9+*#$!_-]/gi.test(e.target.value)) {
										setErrorState({
											...errorState,
											// message:
											// 	"Password: Only letters, numbers and  + * # _ - $ !  are allowed",
											message: <FormattedMessage id='createUserErrorPassword' />,
											password: true,
										});
									} else {
										setErrorState({
											...errorState,
											message: '',
											password: false,
										});
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								error={errorState.permission ? errorState.permission : false}
								required
								fullWidth
								select
								id='userRole'
								label={<FormattedMessage id='addUserRole' />}
								variant='outlined'
								value={role}
								onChange={handleUserRole}
								// helperText="All fields with * are required"
								// helperText={errorMessage.message}
							>
								{userRoles.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: '10px' }}>
							<Typography
								sx={{
									fontSize: '.7rem',
									color: errorState.allFields === false ? '#919191' : 'error.main',
								}}
							>
								<FormattedMessage id='addUserAllFields' />
							</Typography>
						</Grid>
						<Grid
							container
							spacing={0}
							direction='column'
							alignItems='center'
							justifyContent='center'
							sx={{ pt: 4, paddingLeft: '24px' }}
						>
							<Grid item xs={1}>
								<Button
									// type="submit"
									variant='contained'
									onClick={() => {
										// props.closeWindow();
										// addnewUser();
										handleSubmit();
									}}
									color='secondary'
									style={{
										// color: "#fff",
										padding: 10,
										paddingLeft: 40,
										paddingRight: 40,
										fontFamily: 'Rubik',
									}}
								>
									<FormattedMessage id='addUserButton' />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</motion.div>
		</Modal>
	);
}

import React, { SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import moment from 'moment-timezone';
import AddchartIcon from '@mui/icons-material/Addchart';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined';
import Tooltip from '@mui/material/Tooltip';

import {
	DataGridPro,
	useGridApiRef,
	gridFilteredSortedRowEntriesSelector, //? get all raw data of the grid after filtering
	GridSortModel,
	GridToolbar,
	GridColDef,
	GridValueGetterParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarExportContainer,
	GridToolbarDensitySelector,
	GridValueFormatterParams,
	GridActionsCellItem,
	GridToolbarContainerProps,
	GridExportMenuItemProps,
	GridSelectionModel,
	useGridApiContext,
	GridOverlay,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	getGridNumericOperators,
	getGridBooleanOperators,
	getGridDateOperators,
	getGridStringOperators,
	getGridSingleSelectOperators,
	GridColumnVisibilityModel,
	GridCellParams,
	GridFilterPanel,
} from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import { FormattedMessage, useIntl } from 'react-intl';

import { makeStyles } from '@mui/styles';
import { format, getTime } from 'date-fns';
// import PDFexport from "../pdfExport";
import { usePDFexport } from '../../globalStores/pdfExport';

import LaunchIcon from '@mui/icons-material/Launch';
import { create } from 'zustand';
import { useApplicantCreateToggle } from '../../globalStores/applicantToggleStore';

import getApplicants from '../../api/getApplicants';
import CreateApplicant from './createApplicant';
import SelectedApplicantCard from './selectedApplicantCard';
import QRcodeGenerator from './qr-code/qrGenerator';
import DnD from './csvImport/csvDnD';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

import Slide, { SlideProps } from '@mui/material/Slide';
type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionUp(props: TransitionProps) {
	return <Slide {...props} direction='up' />;
}
// import {
// 	useNavigate,
// 	// Link as RouterLink,
// 	// LinkProps as RouterLinkProps,
// } from "react-router-dom";

import { styled } from '@mui/material/styles';
// import InputLabel from '@mui/material/InputLabel';
// import { Item } from 'framer-motion/types/components/Reorder/Item';

// * Stores
// type createApplicantToggleStore = {
// 	stateApplicantCreate: boolean;
// 	openApplicantCreate: () => void;
// 	closeApplicantCreate: () => void;
// };
// const useUserCreateToggle = create<createApplicantToggleStore>((set) => ({
// 	stateApplicantCreate: false,
// 	openApplicantCreate: () => set({ stateApplicantCreate: true }),
// 	closeApplicantCreate: () => set({ stateApplicantCreate: false }),
// }));
// * Stores
type selectedApplicantToggleStore = {
	selectedApplicant: object;
	setSelectedApplicant: (data: object) => any;

	stateSelectedApplicantCard: boolean;
	openSelectedApplicantCard: () => void;
	closeSelectedApplicantCard: () => void;
};
const useSelectedApplicant = create<selectedApplicantToggleStore>((set) => ({
	selectedApplicant: {},
	setSelectedApplicant: (data) => set({ selectedApplicant: data }),
	// set((state) => ({
	// 	selectedApplicant: { ...state.selectedApplicant, data },
	// })),
	stateSelectedApplicantCard: false,
	openSelectedApplicantCard: () => set({ stateSelectedApplicantCard: true }),
	closeSelectedApplicantCard: () => set({ stateSelectedApplicantCard: false }),
}));

type QRgeneratorStore = {
	// selectedApplicant: object;
	// setSelectedApplicant: (data: object) => any;

	stateQRgeneratorStore: boolean;
	openQRgenerator: () => void;
	closeQRgenerator: () => void;
};
const useQRgenerator = create<QRgeneratorStore>((set) => ({
	// selectedApplicant: {},
	// setSelectedApplicant: (data) => set({ selectedApplicant: data }),
	// set((state) => ({
	// 	selectedApplicant: { ...state.selectedApplicant, data },
	// })),
	stateQRgeneratorStore: false,
	openQRgenerator: () => set({ stateQRgeneratorStore: true }),
	closeQRgenerator: () => set({ stateQRgeneratorStore: false }),
}));

const CardBox = styled(Box)`
	border-radius: 20px;
	background-color: #ffffff;
	// z-index: 5;
	box-shadow: 0px 2px 6px #4d4f5c29;
	overflow: hidden;
	// width: 100%;
`;

// const topBoxHeight = 170;

const cellStyle = makeStyles({
	root: {
		'& .newStyle': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600',
		},
	},
});
const gridStyle = makeStyles({
	root: {
		'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
		'& .MuiDataGrid-iconSeparator': {
			display: 'none',
		},
		'&.MuiDataGrid-root .MuiDataGrid-selectedRowCount': {
			visibility: 'hidden',
		},
		'&.MuiDataGrid-root': {
			border: 'none',
		},
		'&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
			fontFamily: 'Montserrat',
			fontWeight: 600,
		},
		'&.MuiDataGrid-root .MuiDataGrid-dataContainer': {
			fontFamily: 'Open Sans',
			// fontWeight: 600,
		},
		'&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
			backgroundColor: '#E6EAEE',
		},
		'& .MuiPaginationItem-root': {
			borderRadius: 4,
		},
		'& .borderLeft': {
			borderLeft: '2px solid #e0e0e0',
			// backgroundColor: "#ff943975",
			// color: "#1a3e72",
		},
		//? hide density and export function
		// "& .css-14imlab-MuiButtonBase-root-MuiButton-root:nth-child(n+3)": {
		// 	visibility: "hidden",
		// },
	},
});

// function CustomPagination() {
// 	const apiRef = useGridApiContext();

// 	return (
// 		<Pagination
// 			color="primary"
// 			variant="outlined"
// 			shape="rounded"
// 			defaultPage={1}
// 			showFirstButton
// 			showLastButton
// 			siblingCount={1}
// 			boundaryCount={2}
// 			page={apiRef.current.state.pagination.page + 1}
// 			count={apiRef.current.state.pagination.pageCount}
// 			// page={page}
// 			// count={pageCount}
// 			// @ts-expect-error
// 			renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
// 			onChange={(event, value) => apiRef.current.setPage(value - 1)}
// 		/>
// 	);
// }

export default function applicantCards() {
	const gridApiRef = useGridApiRef();
	const not_demo_app = !window.location.hostname.includes('app.demo');

	const intl = useIntl();
	const { stateApplicantCreate, openApplicantCreate, closeApplicantCreate } = useApplicantCreateToggle();

	const {
		selectedApplicant,
		setSelectedApplicant,
		stateSelectedApplicantCard,
		openSelectedApplicantCard,
		closeSelectedApplicantCard,
	} = useSelectedApplicant();

	const { stateQRgeneratorStore, openQRgenerator, closeQRgenerator } = useQRgenerator();
	const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
		id: false,
		// total_score: false,
		pv1_score: false,
		ehc_l_score: false,
		ehc_r_score: false,
		sa_score: false,
		cv_score: false,
		l_score: false,
		m1_score: false,
		m2_score: false,
		ul_score: false,
		pv2_score: false,
		sn_score: false,
		//! add or remove?
		birthday: false,
		email: false,
		phone_number: false,
	});
	const [alwaysHiddenColumns, setAlwaysHiddenColumns] = React.useState<GridColumnVisibilityModel>({
		pv1_extended: false,
		pv2_extended: false,
		// cv_extended: false,
		ehc_l_extended: false,
		ehc_r_extended: false,
		sa_extended: false,
		// m1_extended: false,
		// m2_extended: false,
		// m3_extended: false,
		// sn_extended: false,
		// ul_extended: false,
	});
	const { data, mutate } = getApplicants();
	const [userRows, setUserRows] = React.useState([]);
	const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
	const [loading, setLoading] = React.useState(true);
	const [extendedActive, setExtendedActive] = React.useState(false);
	const [dnd, setDND] = React.useState(false);
	const [mounted, setMounted] = React.useState(false);

	// const memoizedValue = React.useMemo(() => computeExpensiveValue(a, b), []);
	//* snack
	const [snackInfo, setSnackInfo] = React.useState({
		open: false,
		message: '',
	});
	//* PDFexport
	const { setPDFexportInfoModalState, selectedDatagridValues, setSelectedDatagridValues } = usePDFexport();
	// React.useEffect(() => {
	// 	console.log(pdfExportState);
	// }, [pdfExportState]);
	const [sortModel, setSortModel] = React.useState<GridSortModel>([
		// { field: "id", sort: "desc" },
		{ field: 'created_on', sort: 'desc' },
	]);

	const [timeZone, setTimeZone] = React.useState<string>('Europe/Berlin');
	React.useEffect(() => {
		if (!mounted) setMounted(true);

		if (window.location.hostname === 'localhost') {
			setTimeZone('Europe/Berlin');
		} else if (window.location.hostname.startsWith('bg')) {
			setTimeZone('Europe/Sofia');
		} else if (window.location.hostname.startsWith('mx')) {
			setTimeZone('America/Mexico_City');
		} else if (window.location.hostname.startsWith('cz')) {
			setTimeZone('Europe/Prague');
		}
	}, [mounted]);

	function CustomFooter() {
		const apiRef = useGridApiContext();
		return (
			<Grid
				container
				mt={1}
				direction='row'
				// justifyContent="center"
				alignItems='center'
			>
				<Grid container item justifyContent='flex-start' xs={3}>
					<Typography
						align='center'
						pl={1}
						style={{
							fontFamily: 'Open Sans',
							fontWeight: 400,
							fontSize: 14,
						}}
					>
						<FormattedMessage id='selectedRows' />
						{': ' + selectionModel.length}
					</Typography>
				</Grid>
				<Grid container item justifyContent='flex-end' xs>
					<Pagination
						color='primary'
						variant='outlined'
						shape='rounded'
						defaultPage={1}
						showFirstButton
						showLastButton
						siblingCount={1}
						boundaryCount={2}
						page={apiRef.current.state.pagination.page + 1}
						count={apiRef.current.state.pagination.pageCount}
						renderItem={(props2) => (
							// @ts-ignore
							<PaginationItem {...props2} disableRipple />
						)}
						onChange={(event, value) => apiRef.current.setPage(value - 1)}
					/>
				</Grid>
			</Grid>
		);
	}

	function CustomLoadingOverlay() {
		return (
			<GridOverlay>
				<div style={{ position: 'absolute', top: 0, width: '100%' }}>
					<LinearProgress />
				</div>
			</GridOverlay>
		);
	}

	//? old
	// function CustomToolbar() {
	// 	return (
	// 		<>
	// 			<GridToolbarContainer>
	// 				{/* <GridToolbarColumnsButton sx={{ mb: 2 }} /> */}
	// 				<GridToolbarExport
	// 					csvOptions={{
	// 						fileName: "MD HR-Monitor Applicants",
	// 						// getRowsToExport: (rows) =>
	// 						// allColumns: true,
	// 						// fields: ["id", "forename", "surname", "birthday"],
	// 					}}
	// 					sx={{ mb: 2 }}
	// 					printOptions={{ disableToolbarButton: true }}
	// 				/>
	// 				<GridToolbarFilterButton sx={{ mb: 2 }} />
	// 				{/* <GridToolbarDensitySelector /> */}
	// 				<Button
	// 					sx={{
	// 						mb: 2,
	// 						backgroundColor: extendedActive ? "#cbcbcb" : "transparent",
	// 					}}
	// 					onClick={() => {
	// 						var temp: any = {};
	// 						Object.keys(columnVisibilityModel).forEach((key) => {
	// 							temp[key] = !columnVisibilityModel[key];
	// 						});
	// 						setColumnVisibilityModel(temp);
	// 						setExtendedActive(!extendedActive);
	// 					}}
	// 				>
	// 					<AddchartIcon sx={{ mr: 1 }} />
	// 					Extended view
	// 				</Button>
	// 			</GridToolbarContainer>
	// 		</>
	// 	);
	// }
	//? old

	// {
	// 	<Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start" }}>
	// 		<Button
	// 			variant="contained"
	// 			// onClick={() => {
	// 			// 	// toggle column
	// 			// 	setColumnVisibilityModel(
	// 			// 		columnVisibilityModel.id === false
	// 			// 			? { id: true }
	// 			// 			: { id: false }
	// 			// 	);
	// 			// }}
	// 			onClick={() => {
	// 				// gridColumnLookupSelector(state, gridApiRef.current.instanceId)
	// 				console.log(gridFilteredSortedRowEntriesSelector(gridApiRef));
	// 			}}
	// 			color="secondary"
	// 			style={{
	// 				fontSize: 12,
	// 				width: 100,
	// 				minHeight: 30,
	// 				fontFamily: "Rubik",
	// 			}}
	// 		>
	// 			Test
	// 		</Button>
	// 	</Grid>;
	// }
	const FullDBExportMenuItem = (props: GridExportMenuItemProps<{}>) => {
		// const apiRef = useGridApiContext();
		// console.log(props);
		const { hideMenu } = props;

		return (
			<MenuItem
				onClick={() => {
					//TODO applicant is selected and pdf exported, do test-run. if slection has not changed, old data (QR-code) will be exported
					// console.log(selectedDatagridValues);
					// //* if data is already set, get newest data from grid
					// if (selectedDatagridValues.length > 0) {
					// }

					// setSelectedDatagridValues(
					// 	selectionModel.length > 0
					// 		? selectionModel
					// 		: gridFilteredSortedRowEntriesSelector(gridApiRef).map((row) => row.model),
					// );
					// setPDFexportInfoModalState(true);
					// hideMenu?.();
					// console.log(gridFilteredSortedRowEntriesSelector(gridApiRef).map((row) => row.model));
					// console.log(selectionModel);

					gridApiRef.current.exportDataAsCsv({
						allColumns: true,
						// fields: ["id", "forename", "surname", "birthday"],
						fileName: 'MD HR-Monitor Applicants - Full DB Export',
					});
					// console.log(
					// 	gridApiRef.current.getDataAsCsv({
					// 		allColumns: true,
					// 		// fields: ["id", "forename", "surname", "birthday"],
					// 	}),
					// );
					// console.log(gridFilteredSortedRowEntriesSelector(gridApiRef).map((row) => row.model));
					// console.log(selectionModel);
					// console.log(
					// 	gridApiRef.current.getDataAsCsv({
					// 		allColumns: true,
					// 	}),
					// );
					// setSelectionModel([]);
					hideMenu?.();
				}}
			>
				<FormattedMessage id='exportCSVFull' />
			</MenuItem>
		);
	};
	const QRexportMenuItem = (props: GridExportMenuItemProps<{}>) => {
		// const apiRef = useGridApiContext();
		// console.log(props);
		const { hideMenu } = props;

		return (
			<MenuItem
				onClick={() => {
					//TODO applicant is selected and pdf exported, do test-run. if slection has not changed, old data (QR-code) will be exported
					// console.log(selectedDatagridValues);
					// //* if data is already set, get newest data from grid
					// if (selectedDatagridValues.length > 0) {
					// }

					setSelectedDatagridValues(
						selectionModel.length > 0
							? selectionModel
							: gridFilteredSortedRowEntriesSelector(gridApiRef).map((row) => row.model),
					);
					setPDFexportInfoModalState(true);
					hideMenu?.();
					// setSelectionModel([]);
				}}
			>
				<FormattedMessage id='exportQRCodes' />
			</MenuItem>
		);
	};
	const SpacerMenuItem = (props: GridExportMenuItemProps<{}>) => {
		return (
			<MenuItem
				className='nonSelectable'
				divider
				sx={{
					padding: 0,
					mt: 1,
					mb: 1,
				}}
			/>
		);
	};

	const csvOptions: GridCsvExportOptions = {
		delimiter: ',',
		fileName: 'MD HR-Monitor Applicants',
	};
	const CustomExportButton = (props: ButtonProps) => (
		<GridToolbarExportContainer
			{...props}
			sx={{
				mb: 2,
				mr: 1,
				'& svg': {
					width: '21px',
					height: '21px',
				},
			}}
		>
			<GridCsvExportMenuItem options={csvOptions} />
			<FullDBExportMenuItem />
			<SpacerMenuItem />
			<QRexportMenuItem />
		</GridToolbarExportContainer>
	);

	const CustomToolbar = (props: GridToolbarContainerProps) => (
		<GridToolbarContainer {...props}>
			<CustomExportButton size='medium' sx={{ mb: 2, mr: 1, fontSize: 14 }} />

			<Button
				sx={{ mb: 2, mr: 1 }}
				// disabled
				onClick={() => {
					setDND(true);
				}}
			>
				<FileUploadOutlinedIcon sx={{ mr: 1, fontSize: 22 }} />
				<FormattedMessage id='importCSV' />
			</Button>
			<GridToolbarFilterButton sx={{ mb: 2, mr: 1, p: 0.7, fontSize: 14 }} />
			<Button
				sx={{
					mb: 2,
					backgroundColor: extendedActive ? '#e0e7ec' : 'transparent',
					fontSize: 14,
				}}
				onClick={() => {
					var temp: any = {};
					Object.keys(columnVisibilityModel).forEach((key) => {
						temp[key] = !columnVisibilityModel[key];
					});
					setTimeout(() => {
						setColumnVisibilityModel(temp);
						setExtendedActive(!extendedActive);
					}, 60);
				}}
			>
				<AddchartIcon sx={{ mr: 1, fontSize: 21 }} />
				<Typography style={{ fontSize: 14 }}>
					<FormattedMessage id='extendedView' />
				</Typography>
			</Button>
		</GridToolbarContainer>
	);

	//* DataGrid
	const columns = [
		// {
		// 	field: "id",
		// 	// headerName: "ID",
		// 	renderHeader: () => (
		// 		<Typography
		// 			sx={{
		// 				fontFamily: "Montserrat",
		// 				fontWeight: 600,
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			<FormattedMessage id="idGrid" />
		// 		</Typography>
		// 	),
		// 	width: 80,
		// 	//hide this column
		// 	// hide: true,
		// 	filterable: columnVisibilityModel.id,
		// },

		{
			// headerName: "QR-Code",
			field: 'qr-code',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='qrCodeGrid' />
				</Typography>
			),
			type: 'actions',
			width: 100,
			getActions: (params: any) => [
				<GridActionsCellItem
					icon={<QrCodeIcon />}
					label='Open'
					onClick={() => {
						// console.log(params.row);
						openQRgenerator();
						setSelectedApplicant(params.row);
						// useApplicantToggle((state) => state.openApplicant);
					}}
				/>,
			],
		},
		{
			field: 'hmid',
			// headerName: "ID",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='hmidGrid' />
				</Typography>
			),
			width: 100,
			filterable: true,
		},
		// {
		// 	field: "username",
		// 	headerName: "Username",
		// 	width: 150,
		// 	// disableColumnMenu: true,
		// 	// cellClassName: "newStyle",
		// 	// editable: true,
		// },
		{
			field: 'forename',
			// headerName: "First name",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='firstnameGrid' />
				</Typography>
			),
			width: 150,
			// editable: true,
			filterable: true,
		},
		{
			field: 'surname',
			// headerName: "Last name",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='lastnameGrid' />
				</Typography>
			),
			width: 150,
			// editable: true,
			filterable: true,
		},

		//TODO info birthday
		{
			field: 'birthday',
			// headerName: "Birthday",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='birthdayApplicantGrid' />
				</Typography>
			),
			// sortable: false,
			// filterable: false,
			type: 'date',
			width: 210,
			// sortComparator: (v1:any, v2:any) => (v1!.toString().localeCompare(v2!.toString())),
			valueFormatter: (params: GridValueFormatterParams) => {
				// console.log(params.value);
				if (params.value == null) {
					return '';
				}
				let convertedDateBirthday = format(new Date(params.value as Date), 'dd.MM.yyyy');
				//   (params.value as number) * 100,
				// ).toLocaleString();
				// return `${valueFormatted} %`;
				return convertedDateBirthday;
			},
		},
		{
			// headerName: "Status",
			field: 'valid',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='statusGrid' />
				</Typography>
			),
			width: 100,
			// type: "boolean",
			//@ts-ignore
			// valueFormatter: ({ value }) => `${value == null ? "Invalid" : "Valid"}`,
			valueFormatter: (params: any) => {
				if (params.value == null) {
					// return "None";
					return intl.formatMessage({
						id: 'filterNone',
					});
				} else {
					// return "Done";
					return intl.formatMessage({
						id: 'filterFinished',
					});
				}
			},
			// `${params.value == null ? "Invalid" : "Valid"}`,
			renderCell: (params: any) => {
				// console.log(params.value);
				return params.value == 0 || params.value == null ? (
					<Box display='flex' justifyContent='center' alignItems='center' sx={{ width: 1 }}>
						<Box
							sx={{
								backgroundColor: '#A4AFB7',
								color: '#43425d',
								textTransform: 'uppercase',
								fontSize: '11px',
								fontWeight: 'bold',
								paddingTop: '5px',
								paddingBottom: '5px',
								width: '100%',
								borderRadius: '14px',
							}}
						>
							<FormattedMessage id='filterNone' />
						</Box>
					</Box>
				) : (
					<Box display='flex' justifyContent='center' alignItems='center' sx={{ width: 1 }}>
						<Box
							sx={{
								backgroundColor: '#69E4A6',
								color: '#0b733e',
								textTransform: 'uppercase',
								fontSize: '11px',
								fontWeight: 'bold',
								paddingTop: '5px',
								paddingBottom: '5px',
								width: '100%',
								borderRadius: '14px',
							}}
						>
							<FormattedMessage id='filterFinished' />
						</Box>
					</Box>
				);
			},
			// getActions: (params: any) => [
			// 	<GridActionsCellItem
			// 		icon={<QrCodeIcon />}
			// 		label="Open"
			// 		onClick={() => {
			// 			// console.log(params.row);
			// 			openQRgenerator();
			// 			setSelectedApplicant(params.row);
			// 			// useApplicantToggle((state) => state.openApplicant);
			// 		}}
			// 	/>,
			// ],
		},
		{
			field: 'total_score',
			// headerName: "ID",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='totalScore' />
				</Typography>
			),
			renderCell: (params: any) => {
				return (
					// <Box textAlign="center" width="100%">
					<Box>{params.value == null ? '' : params.value + ' / 100'}</Box>
				);
			},
			width: 130,
			// filterable: columnVisibilityModel.total_score,
		},
		{
			// headerName: "Open",
			field: 'openApplicant',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='openGrid' />
				</Typography>
			),
			type: 'actions',
			width: 100,
			getActions: (params: any) => [
				<GridActionsCellItem
					icon={<LaunchIcon />}
					label='Open'
					onClick={() => {
						// console.log(params.row);
						openSelectedApplicantCard();
						setSelectedApplicant(params.row);
						// useApplicantToggle((state) => state.openApplicant);
					}}
				/>,

				// <GridActionsCellItem
				// 	// icon={<SecurityIcon />}
				// 	label="Open Applicant"
				// 	// onClick={toggleAdmin(params.id)}
				// 	onClick={() => {
				// 		console.log(params.row);
				// 	}}
				// 	showInMenu
				// />,

				// <GridActionsCellItem
				// 	// icon={<FileCopyIcon />}
				// 	label="Duplicate User"
				// 	// onClick={duplicateUser(params.id)}
				// 	showInMenu
				// />,
			],
		},
		{
			field: 'email',
			width: 200,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='emailGrid' />
				</Typography>
			),
		},
		{
			field: 'phone_number',
			width: 170,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='phoneNumberGrid' />
				</Typography>
			),
			// headerName: 'Phone Number',
			// sortable: false,
			// filterable: false,
			// editable: true,
		},

		{
			field: 'created_on_test_summary',
			// headerName: "Created on",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='testDate' />
				</Typography>
			),
			// sortable: false,
			// filterable: false,
			type: 'date',
			width: 195,
			valueFormatter: (params: GridValueFormatterParams) => {
				let convertedCreatedOn = moment(params.value as Date).tz(timeZone);
				// return convertedCreatedOn.format("DD.MM.YYYY");
				return params.value == null ? '---' : convertedCreatedOn.format('DD.MM.YYYY');
			},
		},
		// {
		// 	field: "total_score",
		// 	renderHeader: () => (
		// 		// <Box
		// 		// 	sx={{
		// 		// 		// display: "flex",
		// 		// 		// justifyContent: "center",
		// 		// 		// alignItems: "center",
		// 		// 		width: "100%",
		// 		// 		textAlign: "center",
		// 		// 	}}
		// 		// >
		// 		<Typography
		// 			sx={{
		// 				fontFamily: "Montserrat",
		// 				fontWeight: 600,
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			<FormattedMessage id="totalScore" />
		// 		</Typography>
		// 		// </Box>
		// 	),
		// 	width: 110,
		// 	valueFormatter: (params: any) => {
		// 		// console.log(params.value);
		// 		return params.value == null ? "---" : params.value;
		// 	},
		// 	// type: "number",
		// 	// editable: true,
		// },
		{
			field: 'created_from',
			// headerName: "Created by",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='createdByGrid' />
				</Typography>
			),
			width: 140,
			// filterable: false,
			// editable: true,
		},
		{
			field: 'created_on',
			// headerName: "Created on",
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					<FormattedMessage id='createdOnGrid' />
				</Typography>
			),
			// sortable: false,
			// filterable: false,
			type: 'date',
			width: 170,
			valueFormatter: (params: GridValueFormatterParams) => {
				// let convertedDateBirthday = format(
				// 	new Date(params.value as Date),
				// 	"dd.MM.yyyy"
				// );
				// //   (params.value as number) * 100,
				// // ).toLocaleString();
				// // return `${valueFormatted} %`;
				// return convertedDateBirthday;
				let convertedCreatedOn = moment(params.value as Date).tz(timeZone);
				return convertedCreatedOn.format('DD.MM.YYYY');
			},
		},
		{
			field: 'pv1_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
						// width: "80px",
						// textAlign: "center",
					}}
				>
					PV I
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.pv1_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'ehc_l_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					EHC-L
				</Typography>
			),
			width: 110,
			filterable: columnVisibilityModel.ehc_l_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'ehc_r_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					EHC-R
				</Typography>
			),
			width: 110,
			filterable: columnVisibilityModel.ehc_r_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'sa_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					SA
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.sa_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 20'}
					</Box>
				);
			},
		},
		{
			field: 'cv_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					CV
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.cv_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 5'}
					</Box>
				);
			},
		},
		{
			field: 'l_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					L
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.l_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'm1_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					M I
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.m1_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'm2_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					M II
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.m2_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 10'}
					</Box>
				);
			},
		},
		{
			field: 'ul_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					UL
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.ul_score,
			//* format for filtering
			valueGetter: (params: any) => {
				if (params.value == null) return null;
				if (params.value <= 4) {
					return 'failed';
				} else {
					return 'passed';
				}
			},
			//* format for export, other languages
			valueFormatter: (params: any) => {
				if (params.value == null) return null;
				if (params.value == 'failed') {
					return intl.formatMessage({
						id: 'failed',
					});
				} else {
					return intl.formatMessage({
						id: 'passed',
					});
				}
			},
			renderCell: (params: any) => {
				// console.log(params);
				if (params.value == null) return '';
				return params.value == 'failed' ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Tooltip title={<FormattedMessage id='failed' />}>
							<HideSourceOutlinedIcon
								sx={{
									color: 'primary.dark',
									fontSize: 26,
								}}
							/>
						</Tooltip>
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Tooltip title={<FormattedMessage id='passed' />}>
							<TaskAltOutlinedIcon
								sx={{
									color: 'primary.dark',
									fontSize: 26,
								}}
							/>
						</Tooltip>
					</Box>
				);
			},
		},
		{
			field: 'pv2_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					PV II
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.pv2_score,
			renderCell: (params: any) => {
				return (
					<Box textAlign='center' width='100%'>
						{params.value == null ? '' : params.value + ' / 15'}
					</Box>
				);
			},
		},
		{
			field: 'sn_score',
			headerAlign: 'center',
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: 'Montserrat',
						fontWeight: 600,
						fontSize: '14px',
					}}
				>
					SN
				</Typography>
			),
			width: 100,
			filterable: columnVisibilityModel.sn_score,
			//* format for filtering
			valueGetter: (params: any) => {
				if (params.value == null) return null;
				if (params.value <= 3) {
					return 'failed';
				} else {
					return 'passed';
				}
			},
			//* format for export, other languages
			valueFormatter: (params: any) => {
				if (params.value == null) return null;
				if (params.value == 'failed') {
					return intl.formatMessage({
						id: 'failed',
					});
				} else {
					return intl.formatMessage({
						id: 'passed',
					});
				}
			},
			renderCell: (params: any) => {
				if (params.value == null) return '';
				return params.value == 'failed' ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Tooltip title={<FormattedMessage id='failed' />}>
							<HideSourceOutlinedIcon
								sx={{
									color: 'primary.dark',
									fontSize: 26,
								}}
							/>
						</Tooltip>
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Tooltip title={<FormattedMessage id='passed' />}>
							<TaskAltOutlinedIcon
								sx={{
									color: 'primary.dark',
									fontSize: 26,
								}}
							/>
						</Tooltip>
					</Box>
				);
			},
		},
		{
			field: 'pv1_extended',
			headerAlign: 'center',
			// renderHeader: () => (
			// 	<Typography
			// 		sx={{
			// 			fontFamily: 'Montserrat',
			// 			fontWeight: 600,
			// 			fontSize: '14px',
			// 		}}
			// 	>
			// 		pv1_extended
			// 	</Typography>
			// ),
			width: 0,
			filterable: false,
			//* format for export, other languages
			valueFormatter: (params: any) => {
				// console.log(params.value);
				// console.log(JSON.stringify(params.value));
				// console.log('typeof: ', typeof params.value);
				if (params.value == null) return null;
				// console.log('obviously not null');
				// console.log(JSON.stringify(params.value?.average_times));
				// console.log(params.value.average_times);
				// let temp = JSON.stringify(params.value.average_times);
				// return temp;

				if (typeof params.value === 'string') {
					let temp = JSON.parse(params.value);
					temp = JSON.stringify(temp.average_times);
					return temp;
				} else if (typeof params.value === 'object') {
					return JSON.stringify(params.value?.average_times);
				} else {
					return 'Could not parse';
				}
				// return params.value.average_times;
				// if (params.value == null) return null;
				// if (params.value == 'failed') {
				// 	return intl.formatMessage({
				// 		id: 'failed',
				// 	});
				// } else {
				// 	return intl.formatMessage({
				// 		id: 'passed',
				// 	});
				// }
			},
			// renderCell: (params: any) => {
			// 	return (
			// 		<Box textAlign='center' width='100%'>
			// 			{JSON.stringify(params.value).slice(0, 8)}
			// 		</Box>
			// 	);
			// },
		},
		{
			field: 'pv2_extended',
			headerAlign: 'center',
			// renderHeader: () => (
			// 	<Typography
			// 		sx={{
			// 			fontFamily: 'Montserrat',
			// 			fontWeight: 600,
			// 			fontSize: '14px',
			// 		}}
			// 	>
			// 		pv1_extended
			// 	</Typography>
			// ),
			width: 0,
			filterable: false,
			valueFormatter: (params: any) => {
				// console.log(params.value);
				// return JSON.stringify(params.value);
				if (params.value == null) return null;
				// return JSON.stringify(params.value?.average_times);
				if (typeof params.value === 'string') {
					let temp = JSON.parse(params.value);
					temp = JSON.stringify(temp.average_times);
					return temp;
				} else if (typeof params.value === 'object') {
					return JSON.stringify(params.value?.average_times);
				} else {
					return 'Could not parse';
				}
			},
		},
		// {
		// 	field: 'cv_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		cv_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },
		{
			field: 'ehc_l_extended',
			headerAlign: 'center',
			// renderHeader: () => (
			// 	<Typography
			// 		sx={{
			// 			fontFamily: 'Montserrat',
			// 			fontWeight: 600,
			// 			fontSize: '14px',
			// 		}}
			// 	>
			// 		ehc_l_extended
			// 	</Typography>
			// ),
			width: 0,
			filterable: false,
			valueFormatter: (params: any) => {
				// return JSON.stringify(params.value);
				if (params.value == null) return null;
				// return JSON.stringify(params.value?.average_times);
				if (typeof params.value === 'string') {
					let temp = JSON.parse(params.value);
					temp = JSON.stringify(temp.average_times);
					return temp;
				} else if (typeof params.value === 'object') {
					return JSON.stringify(params.value?.average_times);
				} else {
					return 'Could not parse';
				}
			},
		},
		{
			field: 'ehc_r_extended',
			headerAlign: 'center',
			// renderHeader: () => (
			// 	<Typography
			// 		sx={{
			// 			fontFamily: 'Montserrat',
			// 			fontWeight: 600,
			// 			fontSize: '14px',
			// 		}}
			// 	>
			// 		ehc_r_extended
			// 	</Typography>
			// ),
			width: 0,
			filterable: false,
			valueFormatter: (params: any) => {
				// return JSON.stringify(params.value);
				if (params.value == null) return null;
				// return JSON.stringify(params.value?.average_times);
				if (typeof params.value === 'string') {
					let temp = JSON.parse(params.value);
					temp = JSON.stringify(temp.average_times);
					return temp;
				} else if (typeof params.value === 'object') {
					return JSON.stringify(params.value?.average_times);
				} else {
					return 'Could not parse';
				}
			},
		},
		// {
		// 	field: 'm1_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		m1_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },
		// {
		// 	field: 'm2_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		m2_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },
		// {
		// 	field: 'm3_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		m3_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },
		{
			field: 'sa_extended',
			headerAlign: 'center',
			// renderHeader: () => (
			// 	<Typography
			// 		sx={{
			// 			fontFamily: 'Montserrat',
			// 			fontWeight: 600,
			// 			fontSize: '14px',
			// 		}}
			// 	>
			// 		sa_extended
			// 	</Typography>
			// ),
			width: 0,
			filterable: false,
			valueFormatter: (params: any) => {
				// return JSON.stringify(params.value);
				if (params.value == null) return null;
				// return JSON.stringify(params.value?.average_times);
				if (typeof params.value === 'string') {
					let temp = JSON.parse(params.value);
					temp = JSON.stringify(temp.average_times);
					return temp;
				} else if (typeof params.value === 'object') {
					return JSON.stringify(params.value?.average_times);
				} else {
					return 'Could not parse';
				}
			},
		},
		// {
		// 	field: 'sn_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		sn_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },
		// {
		// 	field: 'ul_extended',
		// 	headerAlign: 'center',
		// 	// renderHeader: () => (
		// 	// 	<Typography
		// 	// 		sx={{
		// 	// 			fontFamily: 'Montserrat',
		// 	// 			fontWeight: 600,
		// 	// 			fontSize: '14px',
		// 	// 		}}
		// 	// 	>
		// 	// 		ul_extended
		// 	// 	</Typography>
		// 	// ),
		// 	width: 0,
		// 	filterable: false,
		// 	valueFormatter: (params: any) => {
		// 		return JSON.stringify(params.value);
		// 	},
		// },

		// {
		// 	field: "fullName",
		// 	headerName: "Full name",
		// 	description: "This column has a value getter and is not sortable.",
		// 	sortable: false,
		// 	width: 160,
		// 	valueGetter: (params: GridValueGetterParams) =>
		// 		`${params.getValue(params.id, "firstName") || ""} ${
		// 			params.getValue(params.id, "lastName") || ""
		// 		}`,
		// },

		//?  GridValueFormatterParams
		// valueFormatter: (params: GridValueFormatterParams) => {
		// 	const valueFormatted = Number(
		// 	  (params.value as number) * 100,
		// 	).toLocaleString();
		// 	return `${valueFormatted} %`;
	];

	const columnsFilter = React.useMemo(
		() =>
			columns.map((col, i) => {
				// console.log(col);
				// if (col.field !== "valid") {
				// 	return col;
				// }

				// return {
				// 	...col,
				// 	// filterOperators: getGridNumericOperators().filter((operator) => {
				// 	// 	// console.log(operator);
				// 	// 	return (
				// 	// 		operator.value === "isEmpty" || operator.value === "isNotEmpty"
				// 	// 		// operator.label === "None"
				// 	// 	);
				// 	// }),
				// 	headerName: intl.formatMessage({
				// 		id: "statusGrid",
				// 	}),
				// 	filterOperators: getGridNumericOperators()
				// 		.filter(
				// 			(operator) =>
				// 				operator.value === "isEmpty" || operator.value === "isNotEmpty"
				// 		)
				// 		.map((operator) => ({
				// 			...operator,
				// 			label: operator.value === "isEmpty" ? "None" : "Finished",
				// 		})),
				// };
				if (col.field == 'valid') {
					return {
						...col,
						// filterOperators: getGridNumericOperators().filter((operator) => {
						// 	// console.log(operator);
						// 	return (
						// 		operator.value === "isEmpty" || operator.value === "isNotEmpty"
						// 		// operator.label === "None"
						// 	);
						// }),
						headerName: intl.formatMessage({
							id: 'statusGrid',
						}),
						filterOperators: getGridNumericOperators()
							.filter((operator) => operator.value === 'isEmpty' || operator.value === 'isNotEmpty')
							.map((operator) => ({
								...operator,
								label:
									operator.value === 'isEmpty'
										? intl.formatMessage({
												id: 'filterNone',
											})
										: intl.formatMessage({
												id: 'filterFinished',
											}),
							})),
					};
				}
				if (col.field == 'created_on') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'createdOnGrid',
						}),
						filterOperators: getGridDateOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'after' ||
								operator.value === 'onOrAfter' ||
								operator.value === 'before' ||
								operator.value === 'onOrBefore',
						),
					};
				}
				if (col.field == 'created_on_test_summary') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'testDate',
						}),
						filterOperators: getGridDateOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'after' ||
								operator.value === 'onOrAfter' ||
								operator.value === 'before' ||
								operator.value === 'onOrBefore',
						),
					};
				}
				if (col.field == 'created_from') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'createdByGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				if (col.field == 'forename') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'firstnameGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				if (col.field == 'surname') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'lastnameGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				if (col.field == 'hmid') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'hmidGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				// if (col.field == "id") {
				// 	return {
				// 		...col,
				// 		headerName: "ID",
				// 		filterOperators: getGridStringOperators().filter(
				// 			(operator) =>
				// 				operator.value === "contains" ||
				// 				operator.value === "equals" ||
				// 				operator.value === "startsWith"
				// 		),
				// 	};
				// }
				if (col.field == 'birthday') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'birthdayApplicantGrid',
						}),
						filterOperators: getGridDateOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'after' ||
								operator.value === 'onOrAfter' ||
								operator.value === 'before' ||
								operator.value === 'onOrBefore',
						),
					};
				}
				if (col.field == 'email') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'emailGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				if (col.field == 'phone_number') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'phoneNumberGrid',
						}),
						filterOperators: getGridStringOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'contains' || operator.value === 'equals' || operator.value === 'startsWith',
						),
					};
				}
				//* scores
				if (col.field == 'total_score') {
					return {
						...col,
						headerName: intl.formatMessage({
							id: 'totalScore',
						}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								// console.log(operator)
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'pv1_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'pvI',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'ehc_l_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'lehc',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'ehc_r_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'rehc',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'sa_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'sa',
							}),
						// renderHeader: (params: any) => <div style={{ backgroundColor: "red" }}>{params.colDef.headerName}</div>,
						// renderHeader: () => {
						// 	return (
						// 		<div
						// 			className="d-flex justify-content-between"
						// 			style={{
						// 				color: "green",
						// 				fontWeight: "bold",
						// 				fontSize: "14px",
						// 			}}>
						// 			<div>
						// 				{intl.formatMessage({
						// 					id: "pvII",
						// 				})}
						// 			</div>
						// 		</div>
						// 	);
						// },
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'cv_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'cv',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'l_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'bmI',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'm1_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'bmII',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'm2_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'bmIII',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				if (col.field == 'ul_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'ul',
							}),
						filterOperators: getGridSingleSelectOperators().filter(
							(operator) => operator.value === 'is',
							// operator.value === "not"
							// operator.value === "isAnyOf"
						),
						valueOptions: [
							{ value: 'failed', label: intl.formatMessage({ id: 'failed' }) },
							{ value: 'passed', label: intl.formatMessage({ id: 'passed' }) },
						],
					};
				}
				if (col.field == 'pv2_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'pvII',
							}),
						filterOperators: getGridNumericOperators().filter(
							(operator) =>
								operator.value === 'isEmpty' ||
								operator.value === 'isNotEmpty' ||
								operator.value === '=' ||
								operator.value === '>' ||
								operator.value === '<' ||
								operator.value === '>=' ||
								operator.value === '<=',
						),
					};
				}
				// if (col.field == 'm1_extended') {
				// 	return {
				// 		...col,
				// 		headerName: 'logic_extended',
				// 	};
				// }
				if (col.field == 'pv1_extended') {
					return {
						...col,
						headerName: 'pv1_average_reaction_times',
					};
				}
				if (col.field == 'pv2_extended') {
					return {
						...col,
						headerName: 'pv2_average_reaction_times',
					};
				}
				if (col.field == 'ehc_l_extended') {
					return {
						...col,
						headerName: 'ehc_l_average_reaction_times',
					};
				}
				if (col.field == 'ehc_r_extended') {
					return {
						...col,
						headerName: 'ehc_r_average_reaction_times',
					};
				}
				if (col.field == 'sa_extended') {
					return {
						...col,
						headerName: 'sa_average_reaction_times',
					};
				}
				if (col.field == 'sn_score') {
					return {
						...col,
						headerName:
							not_demo_app &&
							intl.formatMessage({
								id: 'sn',
							}),
						filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
						valueOptions: [
							{ value: 'failed', label: intl.formatMessage({ id: 'failed' }) },
							{ value: 'passed', label: intl.formatMessage({ id: 'passed' }) },
						],
					};
				} else {
					return col;
				}
			}),
		[columns],
	);

	// React.useEffect(() => {
	// 	console.log(selectedApplicant);
	// }, [selectedApplicant]);

	React.useEffect(() => {
		if (!mounted) return;
		if (!data) {
			// console.log("no data");
		} else {
			// console.log(data);
			// let tempData: SetStateAction<never[]> = [];
			// setTimeout(() => {
			// let tempData = [] as any;
			// data.applicants.forEach((element: any, index: number) => {
			// 	//? modify date
			// 	let convertedDateCreatedOn = format(
			// 		new Date(element.created_on),
			// 		"dd/MM/yyyy"
			// 	);
			// 	// let convertedDateCreatedOn = new Date(element.created_on);
			// 	let convertedDateBirthday = format(
			// 		new Date(element.birthday),
			// 		"dd/MM/yyyy"
			// 	);
			// 	// let convertedDateBirthday = new Date(element.birthday);
			// 	// prettier-ignore
			// 	// let convertedDate = `${tempCreated_on.getDate()}.${tempCreated_on.getMonth() + 1}.${tempCreated_on.getFullYear()}`;
			// 	//? modify role
			// 	// let convertRole = (role: string) => {
			// 	// 	if (role == "1") return "Administrator";
			// 	// 	if (role == "2") return "Human Resources";
			// 	// 	if (role == "3") return "Testing Staff";
			// 	// };
			// 	tempData[index] = {
			// 		...data.applicants[index],
			// 		// created_on: convertedDateCreatedOn,
			// 		// birthday: convertedDateBirthday,
			// 		// permission: convertRole(element.permission),
			// 	};
			// });
			// setUserRows(tempData);
			setLoading(false);

			// console.log(data.applicants);
			setUserRows(data.applicants);
			// }, 3000);
		}
	}, [data, mounted]);

	return (
		// {/* <Box
		// 	sx={{
		// 		width: 300,
		// 		height: 300,
		// 		bgcolor: "header.main",
		// 		"&:hover": {
		// 			backgroundColor: "primary.main",
		// 			opacity: [0.9, 0.8, 0.7],
		// 		},
		// 	}}
		// /> */}

		<Box
			sx={{
				minWidth: 950,
				maxWidth: 1920,
				flexGrow: 1,
				height: '100%',
			}}
		>
			{/* //? top empty name box */}
			{/* <Box sx={{ height: 30, mb: 3 }} /> */}
			<CreateApplicant
				toggle={stateApplicantCreate}
				snackSuccess={(message) => {
					setSnackInfo({ open: true, message: message });
				}}
				closeWindow={() => {
					closeApplicantCreate();
				}}
			/>
			{stateSelectedApplicantCard && (
				<SelectedApplicantCard
					toggle={stateSelectedApplicantCard}
					closeWindow={() => {
						closeSelectedApplicantCard();
					}}
					// @ts-expect-error
					applicantData={selectedApplicant}
				/>
			)}
			<QRcodeGenerator
				toggle={stateQRgeneratorStore}
				closeWindow={() => {
					closeQRgenerator();
				}}
				// @ts-expect-error
				applicantData={selectedApplicant}
			/>
			<DnD
				// state={true}
				state={dnd}
				snackSuccessCSV={(message) => {
					setSnackInfo({ open: true, message: message });
				}}
				closeWindow={() => {
					setDND(false);
				}}
			/>
			<CardBox sx={{ height: '100%' }}>
				<Grid container spacing={2} sx={{ p: 3, height: '100%' }}>
					<Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
						<Button
							variant='contained'
							onClick={() => {
								openApplicantCreate();
								// history.push("/applicantStart");
							}}
							// component={LinkBehavior}
							color='secondary'
							style={{
								fontSize: 12,
								width: 180,
								minHeight: 30,
								// marginTop: 50,
								// padding: 15,
								// paddingLeft: 50,
								// paddingRight: 50,
								fontFamily: 'Rubik',
							}}
						>
							<FormattedMessage id='addNewApplicant' />
						</Button>
					</Grid>
					<Grid item style={{ height: '94%', width: '100%' }}>
						{/* <div style={{ display: "flex", height: "100%" }}> */}
						{/* <Grid item xs={12} sx={{ flexGrow: 1 }}> */}
						<Grid item xs={12} style={{ height: '100%', width: '100%' }}>
							<DataGridPro
								// gridApiRef
								apiRef={gridApiRef}
								// localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
								sortModel={sortModel}
								onSortModelChange={(model: any) => {
									setSortModel(model);
								}}
								// onSelectionModelChange={(model: any) => {
								// 	// setSelectionModel(model);
								// 	// console.log(model);
								// }}
								// selectionModel={selectionModel}
								checkboxSelection
								disableSelectionOnClick
								// autoHeight={true}
								onSelectionModelChange={(ids: any) => {
									const selectedIDs = new Set(ids);
									const selectedRowData = userRows.filter((row: any) => selectedIDs.has(row.id));
									setSelectionModel(selectedRowData);
								}}
								// selectionModel={selectionModel}
								rows={userRows}
								// columns={columns}
								//@ts-ignore
								columns={columnsFilter}
								// columnVisibilityModel={columnVisibilityModel}
								columnVisibilityModel={{ ...columnVisibilityModel, ...alwaysHiddenColumns }}
								pageSize={100}
								pagination={true}
								// rowsPerPageOptions={[10]}
								disableColumnMenu
								hideFooter={false}
								hideFooterRowCount={false}
								hideFooterSelectedRowCount={false}
								loading={loading}
								//? cant have mui translation and react-intl (custom)
								//? https://github.com/mui/mui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
								//? https://github.com/mui/mui-x/blob/d9e5e63c0d58d714ae3e3e23cfdf099b4263b280/packages/grid/x-data-grid/src/internals/locales/deDE.ts
								localeText={{
									//* toolbar
									toolbarExport: intl.formatMessage({
										id: 'export',
									}),
									// toolbarExportLabel: intl.formatMessage({
									// 	id: "export",
									// }),
									toolbarExportCSV: intl.formatMessage({
										id: 'exportCSV',
									}),
									toolbarFilters: intl.formatMessage({
										id: 'filterFilters',
									}),
									toolbarFiltersTooltipHide: '',
									toolbarFiltersTooltipShow: '',
									//* filters
									filterOperatorContains: intl.formatMessage({
										id: 'filterContains',
									}),
									filterOperatorEquals: intl.formatMessage({
										id: 'filterEquals',
									}),
									filterOperatorStartsWith: intl.formatMessage({
										id: 'filterStartsWith',
									}),
									filterOperatorAfter: intl.formatMessage({
										id: 'filterAfter',
									}),
									filterOperatorOnOrAfter: intl.formatMessage({
										id: 'filterOnOrAfter',
									}),
									filterOperatorBefore: intl.formatMessage({
										id: 'filterBefore',
									}),
									filterOperatorOnOrBefore: intl.formatMessage({
										id: 'filterOnOrBefore',
									}),
									filterPanelAddFilter: intl.formatMessage({
										id: 'filterAdd',
									}),
									filterPanelDeleteIconLabel: intl.formatMessage({
										id: 'deleteButton',
									}),
									filterPanelOperators: intl.formatMessage({
										id: 'filterOperators',
									}),
									filterPanelOperatorAnd: intl.formatMessage({
										id: 'filterAnd',
									}),
									filterPanelOperatorOr: intl.formatMessage({
										id: 'filterOr',
									}),
									filterPanelColumns: intl.formatMessage({
										id: 'filterColumns',
									}),
									filterPanelInputLabel: intl.formatMessage({
										id: 'filterValue',
									}),
									filterPanelInputPlaceholder: intl.formatMessage({
										id: 'filterValuePlaceholder',
									}),
									filterOperatorIsEmpty: intl.formatMessage({
										id: 'filterIsEmpty',
									}),
									filterOperatorIsNotEmpty: intl.formatMessage({
										id: 'filterIsNotEmpty',
									}),
									filterOperatorIs: intl.formatMessage({
										id: 'is',
									}),
									//* column
									columnHeaderSortIconLabel: intl.formatMessage({
										id: 'columnSortLabel',
									}),
								}}
								// use GridFilterPanel
								// components={{
								// 	// Toolbar: GridToolbar,
								// }}
								// componentsProps={{
								// 	filterPanel: (props: any) => {
								// 		console.log(props);
								// 		// return (
								// 		// 	<GridFilterPanel {...props} sx={{ backgroundColor: "red" }}>
								// 		// 		{/* <GridFilterItem {...props} column={{ field: "name" }} /> */}
								// 		// 	</GridFilterPanel>
								// 		// );
								// 	},
								// 	cell: (props: any) => {
								// 		<div>awdwad</div>;
								// 	},
								// 	panel: (props: any) => {
								// 		console.log(props);
								// 		// return (
								// 		// 	<GridFilterPanel {...props} sx={{ backgroundColor: "red" }}>
								// 		// 		{/* <GridFilterItem {...props} column={{ field: "name" }} /> */}
								// 		// 	</GridFilterPanel>
								// 		// );
								// 	},
								// }}
								components={{
									FilterPanel: (props: any) => {
										return <GridFilterPanel className={window.location.hostname.includes('app.demo') ? 'demo' : ''} />;
									},
									// <GridToolbarContainer {...props}>
									// 	<CustomExportButton size="medium" sx={{ mb: 2, mr: 1, fontSize: 14 }} />

									// 	<Button
									// 		sx={{ mb: 2, mr: 1 }}
									// 		// disabled
									// 		onClick={() => {
									// 			setDND(true);
									// 		}}>
									// 		<FileUploadOutlinedIcon sx={{ mr: 1, fontSize: 22 }} />
									// 		<FormattedMessage id="importCSV" />
									// 	</Button>
									// 	<GridToolbarFilterButton sx={{ mb: 2, mr: 1, p: 0.7, fontSize: 14 }} />
									// 	<Button
									// 		sx={{
									// 			mb: 2,
									// 			backgroundColor: extendedActive ? "#e0e7ec" : "transparent",
									// 			fontSize: 14,
									// 		}}
									// 		onClick={() => {
									// 			var temp: any = {};
									// 			Object.keys(columnVisibilityModel).forEach((key) => {
									// 				temp[key] = !columnVisibilityModel[key];
									// 			});
									// 			setTimeout(() => {
									// 				setColumnVisibilityModel(temp);
									// 				setExtendedActive(!extendedActive);
									// 			}, 60);
									// 		}}>
									// 		<AddchartIcon sx={{ mr: 1, fontSize: 21 }} />
									// 		<Typography style={{ fontSize: 14 }}>
									// 			<FormattedMessage id="extendedView" />
									// 		</Typography>
									// 	</Button>
									// </GridToolbarContainer>

									// Pagination: CustomPagination,
									Footer: CustomFooter,
									Toolbar: CustomToolbar,
									LoadingOverlay: CustomLoadingOverlay,
								}}
								// componentsProps={{
								// 	footer: {},
								// }}
								className={gridStyle().root}
								getCellClassName={(params: GridCellParams<any>) => {
									// 	if (params.field === 'pv1_score' || params.value == null) {
									// 	  return '';
									// 	}
									// 	return params.value >= 15 ? 'hot' : 'cold';
									//   }}

									// if (params.field in columnVisibilityModel) {
									// 	// console.log(
									// 	// 	"params.field",
									// 	// 	params.field,
									// 	// 	columnVisibilityModel[params.field]
									// 	// );
									// 	// console.log("key in obj");
									// 	return "borderLeft";
									// } else return "";

									if (params.field === 'pv1_score') {
										return 'borderLeft';
									} else return '';
								}}
							/>
						</Grid>
						{/* </div> */}
					</Grid>
				</Grid>
			</CardBox>
			{/* //* PDFexport */}
			{/* <PDFexport
				pdfExportState={pdfExportState}
				toggle={() => togglePDFexportState()}
				datagridValues={selectedDatagridValues}
			/> */}
			{/* //* snacks */}
			<Snackbar
				open={snackInfo.open}
				TransitionComponent={TransitionUp}
				autoHideDuration={4000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				onClose={() => {
					setSnackInfo({ ...snackInfo, open: false });
				}}
			>
				<Alert severity='success' sx={{ backgroundColor: 'secondary.main' }}>
					{snackInfo.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CircularProgress from '@mui/material/CircularProgress';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined';
import { useChartData } from '../../globalStores/chartData';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import CustomChart from './customChartApplicant';
import getApplicantTestData from '../../api/getApplicantTestData';
import getAverageResults from '../../api/getAverageResults';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SNcheck from './snCheck';
import { parseISO, format } from 'date-fns';

interface Props {
	applicantData: {
		birthday: Date | null | string;
		email: string;
		phone_number: string;
		forename: string;
		surname: string;
		uuid: string;
		created_on: Date | null | string;
		created_from: string;
		id: number;
		language: string | null;
		created_on_test_summary: null | string;
	};
	toggle: boolean;
	closeWindow: () => void;
}

// var applicantHeight = 754.5;
const applicantHeight = 851;

// const applicantWidth = 700;
var applicantWidth = 740;
const paddingLR = 4;

const initialResponseDataState = {
	first_pv_test_score: '-',
	second_pv_test_score: '-',
	left_ehc_test_score: '-',
	right_ehc_test_score: '-',
	sa_test_score: '-',
	cv_test_score: '-',
	ul_test_score: '-',
	logic_test_score: '-',
	m1_test_score: '-',
	m2_test_score: '-',
	v_test_score: '-',
	sn_test_score: '-',
	total_score: '-',
};

export default function selectedApplicantCard(props: Props) {
	const intl = useIntl();
	const [opacity, setOpacity] = React.useState(1);
	const [awaitResponse, setAwaitResponse] = React.useState(false);
	const [responseData, setResponseData] = React.useState(initialResponseDataState);
	const { chartData, setChartData, resetChartData } = useChartData();
	const [applicantBoxSize, setApplicantBoxSize] = React.useState({
		applicantBoxWidth: applicantWidth,
		applicantBoxHeight: applicantHeight,
	});

	//* applicant data
	const { loading, data, mutate, isValidating, error } = getApplicantTestData(props.applicantData);
	//* average results
	const { isValidatingAVG, loadingAVG, dataAVG, mutateAVG, errorAVG } = getAverageResults();

	const [isOn, setIsOn] = React.useState(false);
	const toggleSwitch = () => {
		setIsOn(!isOn);
		setApplicantBoxSize(
			isOn
				? {
						applicantBoxWidth: applicantWidth,
						applicantBoxHeight: applicantHeight,
					}
				: { applicantBoxWidth: 1200, applicantBoxHeight: 660 },
		);
	};

	const [snCheckState, setSNcheckState] = React.useState(false);
	const [enableSNcheckButton, setEnableSNcheckButton] = React.useState(false);
	const [location, setLocation] = React.useState('nr');

	//* toggle button SN visibility CZ
	React.useEffect(() => {
		const locationURL = window.location.hostname.substring(0, 2);
		switch (locationURL) {
			case 'bg':
				setLocation('bg');
				break;

			case 'mx':
				setLocation('mx');
				break;

			case 'cz':
				setLocation('cz');
				break;

			//? DEV
			default:
				setLocation('nr');
				//? set to "cz" if you want to test the SN check
				// setLocation("cz");
				break;
		}
	}, []);

	React.useEffect(() => {
		// console.log("mutating");
		mutate();
		mutateAVG();
		return () => {
			// console.log("cleanup");
		};
	}, []);

	React.useEffect(() => {
		// console.log("%csetting data", "color: yellow");
		if (isValidating) {
			// console.log("isValidating");
			//* toggle loading animation on
			setAwaitResponse(false);
			//* rest applicant data
			setResponseData(initialResponseDataState);
		} else if (error) {
			// console.log("%cno getApplicantTestData", "color: orange");
			//* toggle loading animation off
			setAwaitResponse(true);
			//* rest applicant data
			setResponseData(initialResponseDataState);
			setEnableSNcheckButton(false);
		} else if (data) {
			// console.log("%cgot applicant data", "color: green");
			let tests = data.testData;
			let testSummary = data.testSummary;
			if (tests.sn_test.manual_check_results === null) {
				setEnableSNcheckButton(true);
			}
			// prettier-ignore
			setResponseData({
				first_pv_test_score: tests.first_pv_test.valid !== 0 ? tests.first_pv_test.score_value : '-',
				second_pv_test_score: tests.second_pv_test.valid !== 0 ? tests.second_pv_test.score_value : '-',
				left_ehc_test_score: tests.left_ehc_test.valid !== 0 ? tests.left_ehc_test.score_value : '-',
				right_ehc_test_score: tests.right_ehc_test.valid !== 0 ? tests.right_ehc_test.score_value : '-',
				sa_test_score: tests.sa_test.valid !== 0 ? tests.sa_test.score_value : '-',
				cv_test_score: tests.cv_test.valid !== 0 ? tests.cv_test.score_value : '-',
				ul_test_score: tests.ul_test.valid !== 0 ? tests.ul_test.score_value : '-',
				logic_test_score: tests.logic_test.valid !== 0 ? tests.logic_test.score_value : '-',
				m1_test_score: tests.m1_test.valid !== 0 ? tests.m1_test.score_value : '-',
				m2_test_score: tests.m2_test.valid !== 0 ? tests.m2_test.score_value : '-',
				v_test_score: tests.v_test.valid !== 0 ? tests.v_test.score_value : '-',
				sn_test_score: tests.sn_test.valid !== 0 ? tests.sn_test.score_value : '-',
				total_score: testSummary.valid !== 0 ? testSummary.total_score : '-',
			});
			setAwaitResponse(true);
		}
		if (data && dataAVG && !error) {
			console.log('set new chart data');
			let tests = data.testData;
			let averages = dataAVG.averages;
			// prettier-ignore
			//! order of this array is important
			setChartData([
				//* show applicant score and location average
				[
					tests.first_pv_test.valid !== 0 ? tests.first_pv_test.score_value : null,
					Math.round(averages.avg_first_pv_test),
				],
				[
					tests.left_ehc_test.valid !== 0 ? tests.left_ehc_test.score_value : null,
					Math.round(averages.avg_left_ehc__test),
				],
				[
					tests.right_ehc_test.valid !== 0 ? tests.right_ehc_test.score_value : null,
					Math.round(averages.avg_right_ehc_test),
				],
				[tests.sa_test.valid !== 0 ? tests.sa_test.score_value : null, Math.round(averages.avg_sa_test)],
				[tests.cv_test.valid !== 0 ? tests.cv_test.score_value : null, Math.round(averages.avg_cv_test)],
				[tests.logic_test.valid !== 0 ? tests.logic_test.score_value : null, Math.round(averages.avg_logic_test)],
				[tests.m1_test.valid !== 0 ? tests.m1_test.score_value : null, Math.round(averages.avg_m1_test)],
				[tests.m2_test.valid !== 0 ? tests.m2_test.score_value : null, Math.round(averages.avg_m2_test)],
				// [tests.ul_test.valid !==0? tests.ul_test.score_value:  null, Math.round(averages.avg_ul_test)],
				[
					tests.second_pv_test.valid !== 0 ? tests.second_pv_test.score_value : null,
					Math.round(averages.avg_second_pv_test),
				],
				[tests.v_test.valid !== 0 ? tests.v_test.score_value : null, Math.round(averages.avg_v_test)],
			]);
		} else {
			// console.log("reset chart data");
			resetChartData();
		}
		// console.log(props.applicantData);
	}, [data, isValidating, dataAVG]);

	const closeAndReset = () => {
		//* reset response & chart data
		resetChartData();
		// setEnableSNcheckButton(false);
		// mutateAVG(null, false);
		// setResponseData(initialResponseDataState);
		setAwaitResponse(false);
		setIsOn(false);
		setApplicantBoxSize({
			applicantBoxWidth: applicantWidth,
			applicantBoxHeight: applicantHeight,
		});
		props.closeWindow();
	};

	function createApplicant(description: string, value: string) {
		return { description, value };
	}
	const rowsApplicant = [
		createApplicant(
			// "First Name",
			intl.formatMessage({
				id: 'firstnameGrid',
			}),
			props.applicantData.forename ? props.applicantData.forename : '-',
		),
		createApplicant(
			// "Last Name",
			intl.formatMessage({
				id: 'lastnameGrid',
			}),
			props.applicantData.surname ? props.applicantData.surname : '-',
		),
		createApplicant(
			intl.formatMessage({
				id: 'birthdayApplicantGrid',
			}),
			//prettier-ignore
			props.applicantData.birthday ? format(parseISO(props.applicantData.birthday as string), 'dd.MM.yyyy') : '-',
		),
		createApplicant(
			// "E-Mail",
			intl.formatMessage({
				id: 'eMail',
			}),
			props.applicantData.email ? props.applicantData.email : '-',
		),
		createApplicant(
			// "Phone Number",
			intl.formatMessage({
				id: 'phoneNumber',
			}),
			props.applicantData.phone_number ? props.applicantData.phone_number : '-',
		),
		createApplicant(
			// "selectedLanguage",
			intl.formatMessage({
				id: 'selectedLanguage',
			}),
			props.applicantData.language ? props.applicantData.language : '-',
		),
	];

	const rowsTest = [
		{
			testName: intl.formatMessage({
				id: 'pvI',
			}),
			testScore: responseData.first_pv_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'lehc',
			}),
			testScore: responseData.left_ehc_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'rehc',
			}),
			testScore: responseData.right_ehc_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'sa',
			}),
			testScore: responseData.sa_test_score + '/20',
		},
		{
			testName: intl.formatMessage({
				id: 'cv',
			}),
			testScore: responseData.cv_test_score + '/5',
		},
		{
			testName: intl.formatMessage({
				id: 'bmI',
			}),
			testScore: responseData.logic_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'bmII',
			}),
			testScore: responseData.m1_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'bmIII',
			}),
			testScore: responseData.m2_test_score + '/10',
		},
		{
			testName: intl.formatMessage({
				id: 'ul',
			}),
			// testScore: responseData.ul_test_score + "/6",
			testScore:
				responseData.ul_test_score === '-' ? (
					<RadioButtonUncheckedIcon
						sx={{
							color: 'primary.dark',
							fontSize: 26,
						}}
					/>
				) : parseInt(responseData.ul_test_score) <= 4 ? (
					<HideSourceOutlinedIcon
						sx={{
							color: 'primary.dark',
							fontSize: 26,
						}}
					/>
				) : (
					<TaskAltOutlinedIcon
						sx={{
							color: 'primary.dark',
							fontSize: 26,
						}}
					/>
				),
		},
		{
			testName: intl.formatMessage({
				id: 'pvII',
			}),
			testScore: responseData.second_pv_test_score + '/15',
		},
		{
			testName: intl.formatMessage({
				id: 'sn',
			}),
			testScore:
				responseData.sn_test_score === '-' || responseData.sn_test_score === undefined ? (
					<RadioButtonUncheckedIcon
						sx={{
							color: location !== 'cz' && location !== 'nr' ? 'rgb(112, 112, 112, 0.5)' : 'primary.dark',
							fontSize: 26,
						}}
					/>
				) : parseInt(responseData.sn_test_score) <= 3 ? (
					<HideSourceOutlinedIcon
						sx={{
							color: location !== 'cz' && location !== 'nr' ? 'rgb(112, 112, 112, 0.5)' : 'primary.dark',
							fontSize: 26,
						}}
					/>
				) : (
					<TaskAltOutlinedIcon
						sx={{
							color: location !== 'cz' && location !== 'nr' ? 'rgb(112, 112, 112, 0.5)' : 'primary.dark',
							fontSize: 26,
						}}
					/>
				),
		},
	];

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={props.toggle}
			onClose={closeAndReset}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 800,
				sx: {
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					backdropFilter: 'blur(8px)',
				},
				// transitionDuration: { appear: 5000, enter: 2000, exit: 2000 },
			}}
		>
			<motion.div
				initial={{
					opacity: 0,
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					translateX: '-50%',
					translateY: '-50%',
					// width: 680,
					// minHeight: 600,
					// height: 564,
					backgroundColor: '#FFFFFF',
					boxShadow:
						'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
					borderRadius: '20px',
					// paddingTop: 7,
				}}
				// initial={{ opacity: 0, position: "fixed", width: "100%", top: 0 }}

				animate={{
					opacity: opacity,
					width: applicantBoxSize.applicantBoxWidth,
					height: applicantBoxSize.applicantBoxHeight,
				}}
				transition={{ duration: 0.4 }}
				// whileHover={{ width: 620 }}
				// exit={{ opacity: 0, transition: { duration: 0.4 } }}
				className='noselect'
			>
				<Grid container alignItems='stretch' sx={{ height: '100%' }}>
					{/* //! left */}
					{/* <AnimateSharedLayout type="crossfade"> */}
					<Grid container item xs={5}>
						<AnimatePresence>
							{isOn && (
								<motion.div
									initial={{
										opacity: 0,
										width: '100%',
									}}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.4, delay: 0.4 }}
									// exit={{ opacity: 0, transition: { duration: 0.2 } }}
								>
									<Grid
										item
										xs={12}
										sx={{
											pl: paddingLR,
											pr: paddingLR,
											mt: '29px',
											height: 55,
										}}
									>
										<motion.div>
											<Typography
												sx={{
													textAlign: 'left',
													fontFamily: 'Rubik',
													fontSize: 26,
													fontWeight: 'bold',
													color: 'primary.dark',
													textTransform: 'capitalize',
												}}
											>
												{props.applicantData.forename
													? props.applicantData.forename + ' ' + props.applicantData.surname
													: 'Applicant'}
											</Typography>
										</motion.div>
									</Grid>
								</motion.div>
							)}
						</AnimatePresence>

						<AnimatePresence>
							{!isOn && (
								<motion.div
									initial={{
										opacity: 0,
										width: '100%',
										height: '100%',
										boxShadow: ' 0px 5px 7px #A4AFB7',
										borderRadius: '20px',
									}}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.4, delay: 0.4 }}
								>
									<Grid
										container
										alignItems='center'
										sx={{
											pl: paddingLR,
											pr: paddingLR,
											mt: '92px',
											mb: '35px',
											height: 55,
										}}
									>
										<Grid item xs={12}>
											{/* <ApplicantName isActive={!isOn}></ApplicantName> */}
											<Typography
												sx={{
													textAlign: 'left',
													fontFamily: 'Rubik',
													fontSize: 26,
													fontWeight: 'bold',
													color: 'primary.dark',
													textTransform: 'capitalize',
												}}
											>
												{props.applicantData.forename
													? props.applicantData.forename + ' ' + props.applicantData.surname
													: 'Applicant'}
											</Typography>
										</Grid>
									</Grid>
									{rowsApplicant.map((row, i) => (
										<Grid
											container
											key={i}
											spacing={2}
											sx={{
												pt: 2,
												pl: paddingLR,
												pr: paddingLR,
												userSelect: 'text',
											}}
											alignItems='center'
										>
											<Grid item xs={12} sx={{ pb: 0 }}>
												<Typography
													sx={{
														// position: "relative",
														// bottom: -11,
														textAlign: 'left',
														fontFamily: 'Rubik',
														fontSize: 17,
														color: 'primary.dark',
													}}
												>
													{row.value}
												</Typography>
											</Grid>
											<Divider
												style={{
													width: '100%',
													position: 'relative',
													left: 8,
												}}
											/>
											<Grid item xs={12}>
												<Typography
													sx={{
														position: 'relative',
														top: -13,
														textAlign: 'left',
														fontFamily: 'Open Sans',
														fontWeight: 900,
														fontSize: 10,
														color: 'primary.dark',
													}}
												>
													{row.description}
												</Typography>
											</Grid>
										</Grid>
									))}
								</motion.div>
							)}
						</AnimatePresence>
					</Grid>
					{/* </AnimateSharedLayout> */}

					{/* //! right */}
					<Grid container item xs={7}>
						{/* //! close */}
						<Grid container direction='row' justifyContent='flex-end' sx={{ pr: paddingLR - 3, paddingTop: '7px' }}>
							{/* <Grid item xs={10}></Grid> */}
							<Grid item xs={3} container direction='row' justifyContent='flex-end'>
								<Box
									onClick={toggleSwitch}
									sx={{
										marginTop: '12px',
										width: '68px',
										height: '26px',
										backgroundColor: '#003d61',
										display: 'flex',
										// justifyContent: isOn"flex-start",
										justifyContent: isOn ? 'flex-end' : 'flex-start',
										borderRadius: '10px',
										padding: '4px',
										cursor: 'pointer',
									}}
								>
									<motion.div
										layout
										whileHover={{ scale: 1.05 }}
										transition={{
											type: 'spring',
											stiffness: 700,
											damping: 40,
										}}
										style={{
											textAlign: 'center',
											width: '34px',
											height: '26px',
											backgroundColor: 'white',
											borderRadius: '7px',
										}}
									>
										<AnimatePresence>
											{isOn ? (
												<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
													<ShowChartIcon sx={{ color: 'primary.main' }} />
												</motion.div>
											) : (
												<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
													<ManageSearchIcon sx={{ color: 'primary.main' }} />
												</motion.div>
											)}
										</AnimatePresence>
									</motion.div>
								</Box>
							</Grid>

							<Grid item xs={2} sx={{ textAlign: 'right' }}>
								<IconButton aria-label='delete' size='large' sx={{ ml: 1 }} onClick={closeAndReset}>
									<CloseIcon fontSize='inherit' />
								</IconButton>
							</Grid>
						</Grid>

						<AnimatePresence>
							{!isOn && (
								<motion.div
									initial={{ opacity: 0, width: '100%' }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.4, delay: 0.4 }}
									// exit={{ opacity: 0, transition: { duration: 0.4 } }}
								>
									<Grid container sx={{ pl: 2, pr: 2, mt: 2 }}>
										<Grid
											container
											alignItems='center'
											sx={{
												backgroundColor: '#E6EAEE',
												pl: paddingLR - 2,
												pr: paddingLR - 2,
												height: 55,
											}}
										>
											<Grid item xs={6}>
												<Typography
													sx={{
														textAlign: 'left',
														fontFamily: 'Rubik',
														fontSize: 14,
														// fontWeight: "bold",
														color: 'primary.dark',
														// textTransform: "uppercase",
													}}
												>
													<FormattedMessage id='test' />
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													sx={{
														textAlign: 'right',
														fontFamily: 'Rubik',
														fontSize: 14,
														color: 'primary.dark',
													}}
												>
													<FormattedMessage id='score' />
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{/* // location !== "cz" && i === 10 ? (
										{
											i === 9 ? (
												(console.log("hmmm"), (<></>))
											) : ( */}
									{rowsTest.map((row, i) => (
										<Grid
											container
											key={i}
											spacing={2}
											sx={{
												pt: 2,
												pl: paddingLR,
												pr: paddingLR,
												userSelect: 'text',
											}}
											alignItems='center'
										>
											<Grid item xs={10} sx={{ pb: 2 }}>
												<Typography
													className={window.location.hostname.includes('app.demo') ? 'demo' : ''}
													sx={{
														textAlign: 'left',
														fontFamily: 'Rubik',
														fontSize: 17,
														color:
															//! change "10" to all tests - 1 if new test is added
															location !== 'cz' && i == 10 && location !== 'nr' && i == 10
																? 'rgb(112, 112, 112, 0.5)'
																: 'primary.dark',
													}}
												>
													{row.testName}
												</Typography>
											</Grid>
											<Grid item xs={2} sx={{ pb: 2 }}>
												{typeof row.testScore !== 'object' ? (
													<Typography
														sx={{
															textAlign: 'right',
															fontFamily: 'Rubik',
															fontSize: 17,
															color: 'primary.dark',
														}}
													>
														{awaitResponse ? row.testScore : <CircularProgress size={16} />}
													</Typography>
												) : (
													<Grid container direction='row' justifyContent='flex-end' alignItems='center'>
														{awaitResponse ? row.testScore : <CircularProgress size={16} />}
													</Grid>
												)}
											</Grid>
											{/* //! change "10" to all tests - 1 if new test is added */}
											{(location === 'cz' && i == 10 && enableSNcheckButton) ||
											(location === 'nr' && i == 10 && enableSNcheckButton) ? (
												// {location === "cz" && i == 9 && ( //? DEV
												<Box
													sx={{
														position: 'absolute',
														bgcolor: responseData.sn_test_score === '-' ? '#818181' : 'secondary.main',
														right: -58,
														borderRadius: '0px 8px 8px 0px',
														width: '58px',
														height: '50px',
														alignItems: 'center',
														justifyContent: 'center',
														// display: enableSNcheckButton ? "flex" : "none",
														display: 'flex',
														// cursor: "pointer",
														cursor: responseData.sn_test_score === '-' ? '' : 'pointer',
													}}
													onClick={() => {
														if (responseData.sn_test_score !== '-') {
															setTimeout(() => {
																setSNcheckState(true);
															}, 200);
															setOpacity(0);
														}
													}}
												>
													<FactCheckIcon
														sx={{
															fontSize: 34,
															color: 'white',
															transition: '0.2s',
															transitionTimingFunction: 'ease-in-out',
															'&:hover': {
																transform: 'translate(1.5px, -1.5px)',
															},
														}}
													/>
												</Box>
											) : (
												''
											)}
											<Divider
												style={{
													width: '100%',
													position: 'relative',
													left: 8,
												}}
											/>
										</Grid>
									))}

									<Grid container alignItems='center' spacing={2} sx={{ pt: 2, pl: paddingLR, pr: paddingLR }}>
										<Divider
											style={{
												width: '100%',
												position: 'relative',
												left: 8,
												borderWidth: 1,
												borderColor: '#003D61',
											}}
										/>
										<Grid item xs={8} sx={{ pb: '5px' }}>
											<Typography
												sx={{
													textAlign: 'left',
													fontFamily: 'Rubik',
													fontSize: 20,
													color: 'primary.dark',
													fontWeight: 'bold',
													textTransform: 'uppercase',
												}}
											>
												<FormattedMessage id='totalScore' />
												{':'}
											</Typography>
										</Grid>
										<Grid item xs={4} sx={{ pb: '5px' }}>
											<Typography
												sx={{
													textAlign: 'right',
													fontFamily: 'Rubik',
													fontSize: 20,
													color: 'primary.dark',
													fontWeight: 'bold',
												}}
											>
												{awaitResponse ? responseData.total_score : <CircularProgress size={20} />}
												{'/100'}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										container
										sx={{
											pl: paddingLR,
											pr: paddingLR,
											userSelect: 'text',
										}}
									>
										<Grid item xs={12}>
											<Typography
												sx={{
													textAlign: 'left',
													fontFamily: 'Open Sans',
													fontWeight: 900,
													fontSize: 11,
													color: 'primary.dark',
												}}
											>
												{props.applicantData.created_on_test_summary
													? format(parseISO(props.applicantData.created_on_test_summary), 'dd.MM.yyyy')
													: ''}
											</Typography>
										</Grid>
									</Grid>
								</motion.div>
							)}
						</AnimatePresence>
					</Grid>

					{/* //! body graph */}
					<AnimatePresence>
						{isOn && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.4, delay: 0.45 }}
								// exit={{ opacity: 0, transition: { duration: 0.4 } }}
							>
								<CustomChart
									responsiveWidth={applicantBoxSize.applicantBoxWidth}
									responsiveHeight={applicantBoxSize.applicantBoxHeight - 111}
									gotTestData={awaitResponse}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</Grid>
				{snCheckState && (
					<SNcheck
						applicantData={props.applicantData}
						snCheckState={snCheckState}
						closeSNcheck={() => setSNcheckState(false)}
						opacityParent={(v: number) => setOpacity(v)}
						mutateApplicantResults={() => {
							mutate();
							// setEnableSNcheckButton(false);
							if (data) {
								let tests = data.testData;
								if (tests.sn_test.manual_check_results === null) {
									setEnableSNcheckButton(false);
								}
							}
						}}
					/>
				)}
			</motion.div>
		</Modal>
	);
}

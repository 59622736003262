//* request latest location average test data
// export default async function getAverageTestData(
// 	// id: number,
// 	// uuid: string,
// 	signal: any
// ) {
// 	let localToken = localStorage.getItem("userToken");
// 	// let applicantData = { id: id, uuid: uuid };

// 	let response = await fetch(
// 		import.meta.env.VITE_apiURL + "/applicant/average",
// 		{
// 			method: "GET",
// 			headers: {
// 				"Content-Type": "application/json",
// 				token: localToken ? localToken : "noToken",
// 				// applcantdata: JSON.stringify(applicantData),
// 				// applicantdata: JSON.stringify(applicantData),
// 			},
// 			signal: signal,
// 		}
// 	);
// 	// console.log(await response.json());
// 	return { status: response.status, data: await response.json() };
// }

//* request latest location average test data
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export default function getAverageResults() {
	let localToken = localStorage.getItem("userToken");

	const fetcher = (url: string) =>
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				token: localToken ? localToken : "noToken",
			},
		}).then(async (res) => {
			if (!res.ok) {
				const error = new Error("An error occurred in getAverageTestData!");
				throw error;
			}
			return res.json();
		});

	const { data, mutate, isValidating, error } = useSWRImmutable(
		import.meta.env.VITE_apiURL + "/applicant/average",
		fetcher,
		{
			// revalidateIfStale: false,
			// revalidateOnFocus: false,
			// revalidateOnReconnect: false,
			// errorRetryInterval: 5000,
			errorRetryCount: 0,
		}
	);
	const loadingAVG = !data && !error;
	const isValidatingAVG = isValidating;
	const dataAVG = data;
	const mutateAVG = mutate;
	const errorAVG = error;
	//prettier-ignore
	return {isValidatingAVG, loadingAVG, dataAVG, mutateAVG, errorAVG};
}

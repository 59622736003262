import React from 'react';
import loginIllu from '../assets/Illustration-login.svg';
import MDlogo from '../assets/logo with slogan.png';

import dev from '../assets/flags/dev-logo.png';
import bulgaria from '../assets/flags/bulgaria.png';
import mexico from '../assets/flags/mexico.png';
import czech from '../assets/flags/czech-republic.png';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import PreReleaseOverlay from '../components/preReleaseOverlay';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import userAuth from '../api/userAuth';
import { motion, AnimatePresence } from 'framer-motion';
import { useIntl, FormattedMessage } from 'react-intl';
import { LOCALES } from '../i18n/locales';
import { useLanguageSwitch } from '../globalStores/languageSwitch';

async function loginUser(credentials: any) {
	return await fetch(import.meta.env.VITE_apiURL + '/user/login', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	}).catch(function (error) {
		return error;
	});
}

interface ErrorInterface {
	message: JSX.Element | boolean;
	type: boolean;
}
export default function Welcome() {
	const { data, mutate, tokenValide } = userAuth();
	const navigate = useNavigate();
	const [urlPath, setUrlPath] = React.useState(dev);
	const [statusPath, setStatusPath] = React.useState('');
	const { setLanguage } = useLanguageSwitch();

	const [username, setUserName] = React.useState(window.location.hostname.includes('app.demo') ? 'demo' : '');
	const [password, setPassword] = React.useState(window.location.hostname.includes('app.demo') ? 'demo' : '');

	const [errorMessage, setErrorMessage] = React.useState<ErrorInterface>({
		message: false,
		type: false,
	});

	//* get a user token
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		// try {
		if (username == '' || password == '') {
			console.log('no username or password');
			setErrorMessage({
				message: <FormattedMessage id='loginErrorAllFields' />,
				type: true,
			});
			return;
		}
		const request = await loginUser({ username, password });
		if (!request.ok && request.status === undefined) {
			setErrorMessage({
				message: <FormattedMessage id='badConnection' />,
				type: true,
			});
			return;
		}
		let response = await request.json();
		if (typeof response == 'object' && response.token !== 'undefined') {
			mutate();
			// sessionStorage.setItem("userToken", request.token);
			localStorage.setItem('userToken', response.token);
		}
		// console.log(request);
		// console.log(response);
		if (request.status == 404) {
			setErrorMessage({
				message: <FormattedMessage id='loginErrorInvalid' />,
				type: true,
			});
			// setErrorMessage({ message: response, type: true });
		}
		// if (request.status == 400) {
		// 	setErrorMessage({
		// 		message: <FormattedMessage id="loginErrorAllFields" />,
		// 		type: true,
		// 	});
		// 	// setErrorMessage({ message: response, type: true });
		// }
		if (request.status == 401) {
			setErrorMessage({
				// message: response,
				message: <FormattedMessage id='requestLimiter' />,
				type: true,
			});
		}

		// catch ERR_CONNECTION_REFUSED
		// if (request.status == 0) {
		// 	setErrorMessage({
		// 		message: <FormattedMessage id="loginErrorServer" />,
		// 		type: true,
		// 	});
		// 	// setErrorMessage({ message: response, type: true });
		// }
		// } catch (error) {
		// 	// console.log(error);
		// }
	};

	React.useEffect(() => {
		const location = window.location.hostname.substring(0, 2);
		switch (location) {
			case 'bg':
				setUrlPath(bulgaria);
				setStatusPath('https://status.bg.md.hr-monitor.software/');
				setLanguage(LOCALES.BULGARIAN);
				break;

			case 'mx':
				setUrlPath(mexico);
				setStatusPath('https://status.mx.md.hr-monitor.software/');
				setLanguage(LOCALES.SPANISH);
				break;

			case 'cz':
				setUrlPath(czech);
				setStatusPath('https://status.cz.md.hr-monitor.software/');
				setLanguage(LOCALES.CZECH);
				break;

			// case "pr":
			// 	setUrlPath(dev);
			// 	setStatusPath("https://status.pre-release.md.hr-monitor.software/");
			// 	setLanguage(LOCALES.ENGLISH);
			// 	break;

			default:
				setUrlPath(dev);
				setStatusPath('https://status.nrs.md.hr-monitor.software/');
				setLanguage(LOCALES.ENGLISH);
				break;
		}
	}, []);

	//* redirect if already logged in and token is valid
	React.useEffect(() => {
		if (data && tokenValide !== false) {
			// history.push("/dashboard");
			navigate('/dashboard');
		}
		// console.log(data);
	}, [data]);
	// React.useEffect(() => {
	// 	console.log(data);
	// 	if (data) {
	// 		history.push("/dashboard");
	// 	}
	// 	console.log(data);
	// }, [data]);

	return (
		// <AnimatePresence>
		// 	{useAnimatePresence && (
		<motion.div
			className='App'
			// initial={{ opacity: 0 }}
			initial={{ opacity: 0, position: 'fixed', width: '100%', top: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5 }}
			exit={{ opacity: 0, transition: { duration: 0.5 } }}
		>
			<PreReleaseOverlay />
			<Box
				sx={{
					zIndex: 1,
					width: '45vw',
					height: '100vh',
					// bgcolor: "primary.main",
					bgcolor: '#F8F9FA',
					// "&:hover": {
					// 	backgroundColor: "primary.main",
					// 	opacity: [0.9, 0.8, 0.7],
					// },
					// boxShadow: "0 0 10px 3px rgba(0, 0, 0, 0.3)",
					// clipPath: "inset(0px -40px 0px 0px)",
					boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.15)',
					clipPath: 'inset(0px -40px 0px 0px)',

					//? center content
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						width: '300px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Grid container rowSpacing={3} sx={{ paddingBottom: '25vh' }}>
							{/* <Grid container > */}
							<Grid item xs={12}>
								<img
									className={window.location.hostname.includes('app.demo') ? 'demo-pic' : ''}
									src={MDlogo}
									style={{
										height: '130px',
									}}
									alt='MD-Logo'
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									style={{
										// color: "#1D1D1F",
										// fontFamily: "Rubik",
										fontFamily: 'Open Sans',
										// fontFamily: "Roboto",
										// fontWeight: 700,
										fontSize: '14px',
										opacity: '0.5',
										paddingBottom: '20px',
									}}
								>
									{window.location.hostname.includes('app.demo') ? (
										'Username: demo Password: demo'
									) : (
										<FormattedMessage id='headline' />
									)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									error={errorMessage.type}
									helperText={errorMessage.message ? errorMessage.message : ' '}
									// error={username == "" ? errorMessage.type : false}
									// helperText={username == "" ? errorMessage.message : ""}
									fullWidth
									id='username'
									label={<FormattedMessage id='username' />}
									type='text'
									variant='standard'
									value={username}
									// value={window.location.hostname.includes("app.demo") ? "demo" : ""}
									sx={{
										marginBottom: '19,91px',
									}}
									onChange={(e) => {
										setUserName(e.target.value);
										setErrorMessage({ message: false, type: false });
									}}
									// onChange={(e) => console.log("Username")}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									error={errorMessage.type}
									helperText={errorMessage.message ? errorMessage.message : ' '}
									// error={password == "" ? errorMessage.type : false}
									// helperText={password == "" ? errorMessage.message : ""}
									fullWidth
									id='password'
									label={<FormattedMessage id='password' />}
									type='password'
									variant='standard'
									value={password}
									// value={window.location.hostname.includes("app.demo") ? "demo" : ""}
									onChange={(e) => {
										setPassword(e.target.value);
										setErrorMessage({ message: false, type: false });
									}}
									// onChange={(e) => console.log("Password")}
								/>
							</Grid>

							{/*//? Remember me / Forgot Password */}
							{/* <Grid container sx={{ paddingTop: "5px" }}>
                                <Grid item xs={6} sx={{ alignItems: "left" }}>
                                    <FormControlLabel
                                        disabled
                                        sx={{ float: "left" }}
                                        control={
                                            <Checkbox
                                                // defaultChecked
                                                sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{ fontFamily: "Open Sans", fontSize: "11px" }}
                                            >
                                                Remember me
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        sx={{ float: "right" }}
                                        // variant="contained"
                                        // onClick={() => {
                                        // 	history.push("/applicantStart");
                                        // }}
                                        disabled
                                        color="secondary"
                                        style={{
                                            // color: "#fff",
                                            fontSize: "11px",
                                            fontFamily: "Open Sans",
                                        }}
                                    >
                                        Forgot Password
                                    </Button>
                                </Grid>
						    </Grid> */}
							<Grid item xs={12}>
								<Button
									type='submit'
									variant='contained'
									// onClick={() => {
									// 	// history.push("/applicantStart");
									// 	mutate();
									// }}
									color='primary'
									style={{
										// color: "#fff",
										padding: 10,
										paddingLeft: 40,
										paddingRight: 40,
										fontFamily: 'Open Sans',
									}}
								>
									<FormattedMessage id='signIn' />
								</Button>
							</Grid>
						</Grid>
					</form>

					{/* //* Version */}
					<Typography
						style={{
							position: 'absolute',
							bottom: 0,
							left: 0,
							// color: "#1D1D1F",
							// fontFamily: "Rubik",
							// fontFamily: "Open Sans",
							fontFamily: 'Roboto',
							// fontWeight: 700,
							fontSize: '10px',
							paddingBottom: 10,
							paddingLeft: 10,
						}}
					>
						<Tooltip title={<FormattedMessage id='locationInfo' />} placement='top'>
							<img src={urlPath} style={{ height: 25, marginRight: 10 }}></img>
						</Tooltip>
						{'v ' + APP_VERSION}
					</Typography>

					<Grid
						className={window.location.hostname.includes('app.demo') ? 'demo' : ''}
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'
						sx={{ position: 'absolute', bottom: 0, left: 100 }}
					>
						{/* //* Legal */}
						<Typography
							style={{
								fontFamily: 'Roboto',
								fontSize: '10px',
								paddingBottom: 10,
							}}
						>
							<Link
								color='inherit'
								underline='hover'
								href='https://www.md-elektronik.com/en/legal-notice/'
								target='_blank'
								sx={{ marginRight: 1 }}
							>
								<FormattedMessage id='legal' />
							</Link>
							<Link
								color='inherit'
								underline='hover'
								href='https://www.md-elektronik.com/en/data-protection-declaration/'
								target='_blank'
								sx={{ marginRight: 1 }}
							>
								<FormattedMessage id='DPD' />
							</Link>
							{new Date().getFullYear()}

							{/* //* Status */}
							<Typography component='span' sx={{ marginLeft: 10, fontSize: '10px' }}>
								<Link color='inherit' underline='hover' href={statusPath} target='_blank'>
									<FormattedMessage id='status' />
								</Link>
							</Typography>
							<Link
								color='inherit'
								underline='hover'
								href='https://northrock-software.atlassian.net/servicedesk/customer/portal/2'
								target='_blank'
								sx={{ marginLeft: 1 }}
							>
								<FormattedMessage id='reportAproblem' />
							</Link>
						</Typography>
					</Grid>
				</Box>
				{/* //* Status */}
			</Box>
			<img
				src={loginIllu}
				style={{
					position: 'fixed',
					right: '-11rem',
					zIndex: -1,
					top: '130px',
					minHeight: 600,
					height: '70vh',
					maxHeight: 770,
				}}
				alt='MD-Logo'
			/>
		</motion.div>
		// 	)}
		// </AnimatePresence>
	);
}

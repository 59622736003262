import React from 'react';
import {
	// MemoryRouter as Router, //* needs to be one component above
	Route,
	Routes,
	useLocation,
	MemoryRouter,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import './css/App.css';

import {
	styled,
	createTheme,
	ThemeProvider,
	StyledEngineProvider,
	// } from "@material-ui/core/styles";
} from '@mui/material/styles';
import { MDtheme } from './colorTheme';

// import { enUS, bgBG, esES, csCZ } from "@mui/x-data-grid-pro";
// import { bgBG as coreBgBG } from "@mui/material/locale";

import { useLanguageSwitch } from './globalStores/languageSwitch';
import { IntlProvider } from 'react-intl';
import { messages } from './i18n/translations';

// * Views
import Login from './views/login';
import Dashboard from './views/dashboard';

function App() {
	//* router location
	const location = useLocation();

	//* set language
	const { LANGUAGE } = useLanguageSwitch();
	// const [themeWithLocale, setThemeWithLocale] = React.useState<any>(MDtheme);

	// React.useEffect(() => {
	// 	const selectedLagnuage = LANGUAGE.CODE;
	// 	// console.log("set to: ", selectedLagnuage);
	// 	switch (selectedLagnuage) {
	// 		case "en-US":
	// 			setThemeWithLocale(createTheme(MDtheme, enUS));
	// 			break;
	// 		case "bg-BG":
	// 			setThemeWithLocale(createTheme(MDtheme, bgBG));
	// 			break;
	// 		case "es-ES":
	// 			setThemeWithLocale(createTheme(MDtheme, esES));
	// 			break;
	// 		case "cs-CZ":
	// 			setThemeWithLocale(createTheme(MDtheme, csCZ));
	// 			break;
	// 		default:
	// 			setThemeWithLocale(createTheme(MDtheme, enUS));
	// 			break;
	// 	}
	// }, [LANGUAGE]);

	return (
		<IntlProvider messages={messages[LANGUAGE.CODE]} locale={LANGUAGE.CODE} defaultLocale={LANGUAGE.CODE}>
			<StyledEngineProvider injectFirst>
				{/* <ThemeProvider theme={themeWithLocale}> */}
				<ThemeProvider theme={MDtheme}>
					{/* <Router> */}
					<AnimatePresence>
						{/* <Switch location={location} key={location.pathname}> */}
						{/* <MemoryRouter> */}
						<Routes location={location} key={location.pathname}>
							{/* // * Views */}
							{/* <Route path={["/", "/login"]} exact component={Login} /> */}
							<Route path={'/'} element={<Login />} />
							<Route path={'/login'} element={<Login />} />
							<Route path={'/dashboard'} element={<Dashboard />} />
							{/* // * DEV */}
							{/* <Route path="/dashboard" exact component={Dashboard} /> */}
						</Routes>
						{/* </MemoryRouter> */}
					</AnimatePresence>
					{/* </Router> */}
				</ThemeProvider>
			</StyledEngineProvider>
		</IntlProvider>
	);
}

export default App;
